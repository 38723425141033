import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { AdminUsuariosModel, UsuarioLoginModel, InvitadoModel, UsuarioGridModel } from './../models/adminusuarios/adminusuarios.model';
import { AppSettings } from '../models/appsettings';
import md5 from 'crypto-js/md5';
import sha1 from 'crypto-js/sha1';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class UsuarioService {

  private header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  .append('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  private headerJSON = new HttpHeaders().set('Content-Type', 'application/json');


  private headerQP = new HttpHeaders().set('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  constructor(private http: HttpClient) { }

  getQueryParam( query: string) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.get(url, { headers: this.headerQP });
  }

  getFormParam( query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.header });
  }

  getFormParamJSON(query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.headerJSON });
  }

  login(data: UsuarioLoginModel) {
    const params = new HttpParams({
      fromObject: {
        usuario: data.usuario === undefined ? '' : data.usuario,
        password: data.password === undefined ? '' : sha1(data.password)
      }
    });
    return this.getFormParam('login', params);
  }

  abcRegistroUsuario(data: AdminUsuariosModel) {
    const params = new HttpParams({
      fromObject: {
        idusuario: String(data.idusuario === undefined ? 0 : data.idusuario),
        idrol: String(data.idrol === undefined ? 0 : data.idrol),
        idsucursal: String( data.idsucursal === undefined ? 0 : data.idsucursal),
        username: data.username === undefined ? '' : data.username,
        password: data.password === undefined ? '' : sha1(data.password),
        nombre: data.nombre === undefined ? '' : data.nombre,
        appaterno: data.appaterno === undefined ? '' : data.appaterno,
        apmaterno: data.apmaterno === undefined ? '' : data.apmaterno,
        email: data.email === undefined ? '' : data.email,
        fotobase64: data.fotobase64 === undefined ? '' : data.fotobase64,
        estatus: String( data.estatus === undefined ? 0 : data.estatus),
        urlfotoperfil: data.urlfotoperfil === undefined ? '' : data.urlfotoperfil,
        telefono: data.telefono === undefined ? '' : data.telefono,
        operacion: String( data.operacion === undefined ? 0 : data.operacion)
      }
    });
    return this.getFormParam('login/registrousuario', params);
  }

  getPerfilUsuario(idusuario: number) {
    return this.getQueryParam('login/perfilusuario?idusuario=' + idusuario);
  }

  abcRegistroInvitado(data: InvitadoModel) {
    const params = new HttpParams({
      fromObject: {
        idinvitado: String(data.idinvitado === undefined ? 0 : data.idinvitado),
        email: data.email === undefined ? '' : data.email,
        telefono: data.telefono === undefined ? '' : data.telefono,
        operacion: String( data.operacion === undefined ? 0 : data.operacion)
      }
    });
    return this.getFormParam('invitado/registroinvitado', params);
  }

  getInvitado(email: string, telefono: string) {
    return this.getQueryParam('invitado/leerinvitado?email=' + email + '&telefono=' + telefono);
  }

  // panel de usuarios

  getGridUsuariosPanel(idsucursal: number, idrol: number) {
    return this.getQueryParam('login/leerusuariospanel?idsucursal=' + idsucursal + '&idrol=' + idrol);
  }

  abcPanelUsuario(data: UsuarioGridModel) {

    const params = new HttpParams({
      fromObject: {
        idusuario: String(data.idusuario === undefined ? 0 : data.idusuario),
        idrol: String(data.idrol === undefined ? 0 : data.idrol),
        idsucursal: String( data.idsucursal === undefined ? 0 : data.idsucursal),
        username: data.username === undefined ? '' : data.username,
        password: data.password === undefined ? '' : String(sha1(data.password)),
        nombre: data.nombre === undefined ? '' : data.nombre,
        ap_paterno: data.ap_paterno === undefined ? '' : data.ap_paterno,
        ap_materno: data.ap_materno === undefined ? '' : data.ap_materno,
        email: data.email === undefined ? '' : data.email,
        fotobase64: data.fotobase64 === undefined ? '' : data.fotobase64,
        url_foto_perfil: data.url_foto_perfil === undefined ? '' : data.url_foto_perfil,
        telefono: data.telefono === undefined ? '' : data.telefono,
        movil: data.movil === undefined ? '' : data.movil,
        estatus: String( data.estatus === undefined ? 0 : data.estatus),
        operacion: String( data.operacion === undefined ? 0 : data.operacion)
      }
    });

    const paramsObject = params.keys().reduce((object, key) => {
      object[key] = params.get(key);
      return object;
     }, {});

    const paramsJSON = JSON.parse(JSON.stringify(paramsObject));

    // JSON.parse(JSON.stringify(params))

    return this.getFormParamJSON('login/registrousuario', JSON.parse(JSON.stringify(paramsObject)));
  }

   // Solicitar cambiar contraseña por email (paso 1)
   // relacionado con Cambiar el password (paso 2)

   solicitarPassword(email: string) {
    return this.getQueryParam('login/solicitudrecuperacion?email=' + email);
  }

  // Cambiar el password (paso2)

  cambiarPassword(idusuario: number, password: string) {
    const params = new HttpParams({
      fromObject: {
        idusuario: String(idusuario === undefined ? '' : idusuario),
        password: password === undefined ? '' : sha1(password)
      }
    });
    return this.getFormParam('login/recuperacionpwd', params);
  }

  // confirmacion del usuario registrado

  // getConfimarRegistroUsuario(idusuario: number) {
  //   return this.getQueryParam('login/confirmacion?idusuario=' + idusuario);
  // }

  ConfimarRegistroUsuario(idusuario: number) {
    const params = new HttpParams({
      fromObject: {
        idusuario: String(idusuario === undefined ? '' : idusuario)
      }
    });
    return this.getFormParam('login/confirmacion', params);
  }

  validaPasswordIguales( passName: string, rpassName: string ) {
    return ( formGroup: FormGroup ) => {
      const passControl = formGroup.controls[passName];
      const rpassControl = formGroup.controls[rpassName];

      if (passControl.value === rpassControl.value) {
        rpassControl.setErrors(null);
      } else {
        rpassControl.setErrors( { NoEsIgual : true });
      }

    };
  }

}
