import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import Swal from 'sweetalert2';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-navbarpanel',
  templateUrl: './navbarpanel.component.html',
  styleUrls: ['./navbarpanel.component.css']
})
export class NavbarPanelComponent implements OnInit {

  nombreUsuario: string;
  imagenPerfil: string;
  rol: number;

  constructor(private router: Router,
              private auth: AuthService) { }


  ngOnInit(): void {
    this.rol = Number(sessionStorage.getItem('idr'));
    this.nombreUsuario = String(sessionStorage.username);
    if (String(sessionStorage.image) === '' || String(sessionStorage.image) === 'null') {
      this.imagenPerfil = './assets/iconouser/user2.png';
    } else {
      this.imagenPerfil = String(sessionStorage.image);
    }
  }

  cerrarsesion() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cerrando sesión ...'
    });
    Swal.showLoading();
   // console.log('cerrar sesión');
    this.auth.longout();
   // console.log(sessionStorage);
    this.router.navigateByUrl('');
    Swal.close();
  }

 

  rutamodulos(component: string) {
    const rol = Number(sessionStorage.idr);
    switch (component) {
      case 'cattienda':
        switch (rol) {
          case 1:
            this.acceso(true, component); /* SuperAdministrador */
            break;
          default:
            this.acceso(false, component); /* los demas */
            break;
        }
        break;
      case 'catsucursal':
        switch (rol) {
          case 1:
            this.acceso(true, component); /* SuperAdministrador */
            break;
          case 2:
            this.acceso(true, component); /* Administrador */
            break;
          case 3:
            this.acceso(true, component); /* Encargado de sucursal */
            break;
          default:
            this.acceso(false, component); /* Empleado */
            break;
        }
        break;
      case 'catbanner':
        switch (rol) {
          case 1:
            this.acceso(true, component); /* SuperAdministrador */
            break;
          case 2:
            this.acceso(true, component); /* Administrador */
            break;
          case 3:
            this.acceso(true, component); /* Encargado de sucursal */
            break;
          default:
            this.acceso(false, component); /* Empleado */
            break;
        }
        break;
      case 'catcategoria':
        switch (rol) {
          case 1:
            this.acceso(true, component); /* SuperAdministrador */
            break;
          case 2:
            this.acceso(true, component); /* Administrador */
            break;
          case 3:
            this.acceso(false, component); /* Encargado de sucursal */
            break;
          default:
            this.acceso(false, component); /* Empleado */
            break;
        }
        break;
      case 'catingrediente':
          switch (rol) {
            case 1:
              this.acceso(true, component); /* SuperAdministrador */
              break;
            case 2:
              this.acceso(true, component); /* Administrador */
              break;
            case 3:
              this.acceso(true, component); /* Encargado de sucursal */
              break;
            default:
              this.acceso(false, component); /* Empleado */
              break;
          }
          break;
      case 'catpresentacion':
        switch (rol) {
          case 1:
            this.acceso(true, component); /* SuperAdministrador */
            break;
          case 2:
            this.acceso(true, component); /* Administrador */
            break;
          case 3:
            this.acceso(true, component); /* Encargado de sucursal */
            break;
          default:
            this.acceso(false, component); /* Empleado */
            break;
        }
        break;
      case 'catproducto':
        switch (rol) {
          case 1:
            this.acceso(true, component); /* SuperAdministrador */
            break;
          case 2:
            this.acceso(true, component); /* Administrador */
            break;
          case 3:
            this.acceso(true, component); /* Encargado de sucursal */
            break;
          default:
            this.acceso(false, component); /* Empleado */
            break;
        }
        break;
      case 'catextras':
        switch (rol) {
          case 1:
            this.acceso(true, component); /* SuperAdministrador */
            break;
          case 2:
            this.acceso(true, component); /* Administrador */
            break;
          case 3:
            this.acceso(true, component); /* Encargado de sucursal */
            break;
          default:
            this.acceso(false, component); /* Empleado */
            break;
        }
        break;
      case 'pedidos':
        switch (rol) {
          case 1:
            this.acceso(true, component); /* SuperAdministrador */
            break;
          case 2:
            this.acceso(true, component); /* Administrador */
            break;
          case 3:
            this.acceso(true, component); /* Encargado de sucursal */
            break;
          case 4:
            this.acceso(true, component); /* Empleado */
            break;
          default:
            this.acceso(false, component); /* demas */
            break;
        }
        break;
      case 'ventas':
        switch (rol) {
          case 1:
            this.acceso(true, component); /* SuperAdministrador */
            break;
          case 2:
            this.acceso(true, component); /* Administrador */
            break;
          case 3:
            this.acceso(true, component); /* Encargado de sucursal */
            break;
          default:
            this.acceso(false, component); /* Empleado */
            break;
        }
        break;
        case 'envios':
          switch (rol) {
            case 1:
              this.acceso(true, component); /* SuperAdministrador */
              break;
            case 2:
              this.acceso(true, component); /* Administrador */
              break;
            case 3:
              this.acceso(true, component); /* Encargado de sucursal */
              break;
            case 4:
              this.acceso(false, component); /* Empleado */
              break;
            default:
              this.acceso(false, component);  /* demas */
              break;
          }
          break;
          case 'dianolaboral':
          switch (rol) {
            case 1:
              this.acceso(true, component); /* SuperAdministrador */
              break;
            case 2:
              this.acceso(true, component); /* Administrador */
              break;
            case 3:
              this.acceso(true, component); /* Encargado de sucursal */
              break;
            default:
              this.acceso(false, component); /* Empleado */
              break;
          }
          break;
          case 'catusuario':
          switch (rol) {
            case 1:
              this.acceso(true, component); /* SuperAdministrador */
              break;
            case 2:
              this.acceso(true, component); /* Administrador */
              break;
            case 3:
              this.acceso(true, component); /* Encargado de sucursal */
              break;
            default:
              this.acceso(false, component); /* demas */
              break;
          }
          break;
          case 'entregadom':
        switch (rol) {
          case 1:
            this.acceso(true, component); /* SuperAdministrador */
            break;
          case 2:
            this.acceso(true, component); /* Administrador */
            break;
          case 3:
            this.acceso(true, component); /* Encargado de sucursal */
            break;
          default:
            this.acceso(false, component); /* Empleado */
            break;
        }
        break;

          default:
            Swal.fire({
              icon: 'warning',
              text: 'Módulo no desarrollado'
            });
            break;
    }
  }

  acceso(acceder: boolean, component: any) {
    if (acceder) {
      this.router.navigate(['panel/' + component]);
    } else {

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: '¡Acceso no permitido!',
        showConfirmButton: true,
        timer: 7500
      });
      // console.log('acceso no permitido');
      return;
    }

    /* this.auth.acceso(Number(sessionStorage.getItem('id')), idmodulo, idsubmodulo).subscribe( (resp: any[]) => {
      if (resp) {
        if (resp[0].idrespuesta === 0) {
           Swal.fire({
            type: 'warning',
            text: 'No tiene acceso al módulo'
           });
        } else {
          this.router.navigate( [component] );
        }
      }
   }, (err) => {
    switch (err.status) {
     case 0:
         Swal.fire({
           type: 'warning',
           text: 'No hay conexión con el servidor'
         });
         break;
      case 409:
        Swal.fire({
         type: 'error',
         text: 'Error ' + err.status + ' de la atención de notificaciones en el servidor'
        });
        break;
       case 500:
           Swal.fire({
             type: 'error',
             text: 'Error ' + err.status + ' de la atención de notificaciones en el servidor'
           });
           break;
     }
   });
   } */
  }


}
