import { Component, OnInit, ViewChild } from '@angular/core';

import { MatDialog} from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';

import { CategoriaModel } from '../../models/categoria/categoria.model';
import { CategoriaService } from '../../services/categoria.service';
import { SucursalDialogComponent } from '../sucursal-dialog/sucursal-dialog.component';
import { CategoriasDialogComponent } from '../categorias-dialog/categorias-dialog.component';
import { CategoriasImprimirDialogComponent } from '../categorias-imprimir-dialog/categorias-imprimir-dialog.component';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.css']
})
export class CategoriasComponent implements OnInit {

  categoriamodel: CategoriaModel;
  displayedColumns: string[] = [];
  datasource: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  tabla: any[];

  constructor(private dialog: MatDialog,
              public paginatorInt: MatPaginatorIntl,
              private serviceCategoria: CategoriaService) { }

  ngOnInit(): void {
            this.categoriamodel = new CategoriaModel();
            this.categoriamodel.idusuario = Number(sessionStorage.idu);
            this.categoriamodel.idrol = Number(sessionStorage.idr);
            this.categoriamodel.idsucursal = Number(sessionStorage.ids);
            this.categoriamodel.idcategoria = 0;


            // if (validaacceso(Number(sessionStorage.idr))) {
            //   console.log('acceso correcto');
            // } else {

            //   Swal.fire({
            //     position: 'center',
            //     icon: 'warning',
            //     title: '¡Acceso no permitido!',
            //     showConfirmButton: true,
            //     timer: 7500
            //   });

            //   // console.log('acceso no permitido');
            //   return;
            // }

            // function validaacceso(rol: number) {
            //   let acceso = false;
            //   switch (rol) {
            //     case 1:
            //       acceso = true; // Super Admin
            //       break;
            //     case 2:
            //       acceso = true; // Administrador (Tienda)
            //       break;
            //     case 3:
            //       acceso = false; // Administrador (Sucursal)
            //       break;
            //     case 4:
            //       acceso = false; // Empleado
            //       break;
            //     case 5:
            //       acceso = false; // Cliente
            //       break;
            //     case 6:
            //       acceso = false; // Repartidor
            //       break;
            //     // default:
            //     //   this.router.navigateByUrl('/');
            //     //   break;
            //   }
            //   return acceso;
            // }


            this.getGridCategoria();

            this.paginatorInt.firstPageLabel = 'Primera página';
            this.paginatorInt.lastPageLabel = 'Última página';
            this.paginatorInt.itemsPerPageLabel = 'Registro por página';
            this.paginatorInt.nextPageLabel     = 'Siguiente página';
            this.paginatorInt.previousPageLabel = 'Anterior página';
  }

  reloadgrid() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.getGridCategoria();
    Swal.close();
  }

  acceso(row) {
    this.openDialogCategoria(row);
  }

  openDialogImprimir(rows): void {
    // if ( rows === undefined ) {
      const dialogRef = this.dialog.open( CategoriasImprimirDialogComponent , {
        data: {titulo: 'Vista previa',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.90),
      });
      dialogRef.afterClosed().subscribe(result => {
       // this.getGridSucursal();
      });
    // }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tablaFilter(filterValue.trim().toLowerCase());
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  tablaFilter(cadena: string) {

    // llenar la tabla para el reporte
    // const cadena = filterValue.trim().toLowerCase();
    const tablatemp = [];
    for (let i=0; i < this.tabla.length; i++) {
      if (this.tabla[i].categoria.trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].tienda.trim().toLowerCase().indexOf(cadena) > -1
         ) {
        tablatemp.push(this.tabla[i]);
      }
    }
    this.tabla = [];
    this.tabla = tablatemp;
    console.log(tablatemp);
  }


  getGridCategoria() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumns = ['idcategoria', 'estaactivo', 'categoria', 'actions'];
    this.serviceCategoria.getGridCatCategoria(this.categoriamodel).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.tabla = resp;
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de categorias en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de categorias en el servidor'
            });
            break;

      }
    });
  }

  openDialogCategoria(rows: any) {
    if ( rows === undefined ) {
      const dialogRef = this.dialog.open( CategoriasDialogComponent , {
        data: {titulo: 'Agregar la categoría',
          row: '',
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridCategoria();
      });
    } else {
      const dialogRef = this.dialog.open( CategoriasDialogComponent , {
        data: {titulo: 'Modificar la categoría',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.80),
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getGridCategoria();
      });
    }

  }

  UpdBajaCategoria(UsuarioRow: any) {
    this.bajaCategoria(UsuarioRow);
  }

  bajaCategoria(row: any) {

    const username = row.categoria;

    // operacion
    // 1. insertar
    // 2. actualizar
    // 3. dar de baja
    // 4. restaurar usuario

    row.operacion = 3;
    // console.log(row);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Dar de baja ' + username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.serviceCategoria.abcCatCategoria(row).subscribe( (resp: any[]) => {
          Swal.close();

          // Swal.fire({
          //   icon: 'success',
          //   text: 'El extra ' + username + ' ha sido dado de baja.'
          // });

          // si regresa respuesta el servicio
          if (resp) {

            if (resp['idrespuesta'] > 0) {
              Swal.fire({
                icon: 'success',
                text: resp['respuesta']
              });
              this.getGridCategoria();
              } else {
              Swal.fire({
                icon: 'error',
                text: resp['respuesta']
              });
              }
          }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del panel de categorías'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del panel de categorías'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'La categoría ' + username + ' ha sido dada de baja.',
            'success'
          );

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La baja de la categoría no fue realizada!',
          'warning'
        );
      }
    });

  }

  updRestaurarCategoria(row: any) {

    this.restaurarCategoria(row);

  }

  restaurarCategoria(row: any) {

    const username = row.categoria;

    // operacion
    // 1. insertar
    // 2. modificar
    // 3. dar de baja
    // 4. restaurar usuario

    row.operacion = 4;
    // console.log(row);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Restaurar ' + username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.serviceCategoria.abcCatCategoria(row).subscribe( (resp: any[]) => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            text: 'La categoría' + username + ' ha sido restaurada.'
          });

          // si regresa respuesta el servicio
          if (resp) {

            if (resp['idrespuesta'] > 0) {
              Swal.fire({
                icon: 'success',
                text: resp['respuesta']
              });
              this.getGridCategoria();
              } else {
              Swal.fire({
                icon: 'error',
                text: resp['respuesta']
              });
              }
          }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del panel de categorías'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del panel de categorías'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'La categoría ' + username + ' ha sido restaurada.',
            'success'
          );

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La restauración de la categoría no fue realizada.',
          'warning'
        );
      }
    });

  }

}
