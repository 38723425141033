export class AdminUsuariosModel {
    idusuario: number;
    idrol: number;
    idsucursal: number;
    username: string;
    password: string;
    nombre: string;
    appaterno: string;
    apmaterno: string;
    email: string;
    fotobase64: string;
    urlfotoperfil: string;
    telefono: string;
    movil: string;
    estatus: number;
    operacion: number;
}

export class UsuarioLoginModel {
    username: string;
    password: string;
    nombre: string;
    email: string;
    usuario: string;
    app: string;
}

export class InvitadoModel {
    idinvitado: number;
    email: string;
    telefono: string;
    operacion: number;
}

export class UsuarioGridModel {
    idrol: number;
    movil: string;
    ins_fecha: string;
    upd_fecha: string;
    ap_paterno: string;
    idsucursal: number;
    nombre: string;
    ap_materno: string;
    idusuario: number;
    tienda: string;
    estatus: number;
    sucursal: string;
    fotobase64: string;
    url_foto_perfil: string;
    telefono: string;
    email: string;
    username: string;
    idtienda: number;
    operacion: number;
    password: string;
    confirmpwd: string;

}

export class UsuarioParamModel {
    idusuario: number;
}
