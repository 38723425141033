import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { IngredienteService } from '../../services/ingrediente.service';
import { CombosService } from '../../services/combos.service';
import { CoberturaSucursalModel } from '../../models/ingrediente/ingrediente.model';



@Component({
  selector: 'app-ingrediente-cubierta-sucursal-dialog',
  templateUrl: './ingrediente-cubierta-sucursal-dialog.component.html',
  styleUrls: ['./ingrediente-cubierta-sucursal-dialog.component.css']
})
export class IngredienteCubiertaSucursalDialogComponent implements OnInit {

  coberturaSucursalModel: CoberturaSucursalModel;

  idsucursal: number;
  idrol: number;

  formulario: FormGroup;
  submitted = false;

  combosucursal: any[];
  combocobertura: any[];

  constructor(public dialogRef: MatDialogRef<IngredienteCubiertaSucursalDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private ingredienteservice: IngredienteService,
              private combosservicio: CombosService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      idcobertura: ['', Validators.required],
      idsucursal: ['', Validators.required],
      estaactivo: ['']
    }, {
    });

    this.idsucursal = Number(sessionStorage.getItem('ids'));
    this.idrol = Number(sessionStorage.getItem('idr'));

    this.coberturaSucursalModel = new CoberturaSucursalModel();
    // var input = document.getElementById('idtienda');
    // input.disabled = false;

   // this.catpresentacionesmodel.idtienda = 0;
   // this.idrol = 1;
   // this.idsucursal = 0;
    this.getComboSucursal();
    this.getComboCubierta();

    if (this.data.row.idcobertura_sucursales > 0) {
      this.coberturaSucursalModel = this.data.row;
    //  this.catpresentacionesmodel.personas = this.data.row.personas;
    }

    // if (this.catpresentacionesmodel.idpresentacion === 0) {
    //   this.catpresentacionesmodel.idtienda = 0;
    // }
    // this.extrasmodel.idsucursal = Number(sessionStorage.getItem('ids'));

    this.coberturaSucursalModel.idusuario = Number(sessionStorage.getItem('idu'));
    if (this.coberturaSucursalModel.estaactivo) {
        this.coberturaSucursalModel.estaactivo = true;
    } else {
        this.coberturaSucursalModel.estaactivo = false;
    }

  }

  // variable para acceder a los campos del formulario
  get f() { return this.formulario.controls; }

  get idsucursalNoValido() {
    return this.formulario.get('idsucursal').invalid;
  }

  get idcoberturaNoValido() {
    return this.formulario.get('idcobertura').invalid;
  }

  abcCoberturaSucursal() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    this.coberturaSucursalModel.operacion = this.coberturaSucursalModel.idcobertura_sucursales > 0 ? 2 : 1;
    this.coberturaSucursalModel.idusuario = Number(sessionStorage.getItem('idu'));
    // console.log(this.extrasmodel);
    // if (Number(sessionStorage.getItem('idr')) > 1 ) {
    //   this.panSucursalCatalogoModel.idtienda = 0;
    // }

    this.ingredienteservice.abcCatCubiertasSucursal(this.coberturaSucursalModel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.coberturaSucursalModel.operacion === 1) {
          this.coberturaSucursalModel.idcobertura_sucursales = resp['idrespuesta'] !== -1 || resp['idrespuesta'] !== -2 ? resp['idrespuesta'] : 0 ;
          this.coberturaSucursalModel.operacion = this.coberturaSucursalModel.operacion === 1 ? 2 : 2;
        }
        if (resp) {
          if (resp['idrespuesta'] > 0) {
            Swal.fire({
              icon: 'success',
              text: resp['respuesta']
            });
           } else {
            Swal.fire({
              icon: 'error',
              text: resp['respuesta']
            });
           }
        }
        }, (err) => {
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado la sucursal del cubiertas'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado la sucursal del cubiertas'
                });
                break;
          }
        });
  }

  getComboSucursal() {
    this.combosservicio.getComboPanelSucursal(this.idsucursal, this.idrol).subscribe( (resp: any[]) => {
        this.combosucursal = resp;
        console.log(this.combosucursal);
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }

  getComboCubierta() {
    this.combosservicio.getComboCoberturas().subscribe( (resp: any[]) => {
        this.combocobertura = resp;
        console.log(this.combocobertura);
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {

    this.submitted = true;

   // se detiene si el formulario es invalido
    if (this.formulario.invalid) {
        return;
    }
    this.abcCoberturaSucursal();

    // valores visibles del formmulario
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
    // valores del modelo que se pasa al abc
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.extrasmodel, null, 4));

  }

}
