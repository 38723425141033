import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { PedidoService } from '../../services/pedido.service';



@Component({
  selector: 'app-pagorespuesta',
  templateUrl: './pagorespuesta.component.html',
  styleUrls: ['./pagorespuesta.component.css']
})
export class PagorespuestaComponent implements OnInit {
  cargoid: string;

  constructor(private pedidoService: PedidoService,
              private router: Router) { 
                this.cargoid = this.router.parseUrl(this.router.url).queryParams['id'];
               }

  ngOnInit(): void {
    this.pagorespuesta(this.cargoid);
    sessionStorage.setItem("cargoId",this.cargoid);
  }

  pagorespuesta(cargoid:string) {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Verificando pago espere un momento, por favor.'
    });
    Swal.showLoading();
    this.pedidoService.pagoRespuesta(cargoid).subscribe( (resp: any) => {
      setTimeout(() => {
        Swal.close();
          if(resp.status === 'completed'){
            /* enviar mensaje push al repartidor */


            /* presenta pantalla de respuesta exitosa */
            this.router.navigateByUrl('pagoexitoso');
          } else {
            this.router.navigateByUrl('pagorechazado/' + cargoid);
          }
      }, 7000);
    }, (err) => {
      Swal.close();
      switch (err.status) {
        case 0:
            Swal.fire({
              icon: 'warning',
              text: 'No hay conexión con el servidor'
            });
            break;
        case 409:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en Openpay'
            });
            break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' inviable conectar con Openpay'
            });
            break;
        default: 
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status
            });
            break;
      }
    });
  }

  

}


