import { Component, OnInit, ViewChild } from '@angular/core';
import { SucursalModel, ParamSucursalModel } from './../../models/sucursal/sucursal.model';

import { MatDialog} from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';

import { SucursalService } from '../../services/sucursal.service';
import { SucursalDialogComponent } from '../sucursal-dialog/sucursal-dialog.component';
import { SucursalImprimirDialogComponent } from '../sucursal-imprimir-dialog/sucursal-imprimir-dialog.component';
import { SucursalFechaEntregaDomDesacDialogComponent } from '../sucursal-fecha-entrega-dom-desac-dialog/sucursal-fecha-entrega-dom-desac-dialog.component';

@Component({
  selector: 'app-sucursal',
  templateUrl: './sucursal.component.html',
  styleUrls: ['./sucursal.component.css']
})

export class SucursalComponent implements OnInit {
  paramsucursalmodel: ParamSucursalModel;
  sucursalmodel: SucursalModel;
  displayedColumns: string[] = [];
  datasource: any;
  tabla: any[];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;


  constructor(private catsucursal: SucursalService,
              private dialog: MatDialog,
              public paginatorInt: MatPaginatorIntl) { }

  ngOnInit(): void {

    this.sucursalmodel = new SucursalModel();
    this.paramsucursalmodel = new ParamSucursalModel();
    this.paramsucursalmodel.idsucursal = Number(sessionStorage.ids);
    this.paramsucursalmodel.idrol = Number(sessionStorage.idr);

    // if (validaacceso(Number(sessionStorage.idr))) {
    //   console.log('acceso correcto');
    // } else {

    //   Swal.fire({
    //     position: 'center',
    //     icon: 'warning',
    //     title: '¡Acceso no permitido!',
    //     showConfirmButton: true,
    //     timer: 7500
    //   });

    //   // console.log('acceso no permitido');
    //   return;
    // }

    // function validaacceso(rol: number) {
    //   let acceso = false;
    //   switch (rol) {
    //     case 1:
    //       acceso = true; // Super Admin
    //       break;
    //     case 2:
    //       acceso = true; // Administrador (Tienda)
    //       break;
    //     case 3:
    //       acceso = true; // Administrador (Sucursal)
    //       break;
    //     case 4:
    //       acceso = false; // Empleado
    //       break;
    //     case 5:
    //       acceso = false; // Cliente
    //       break;
    //     case 6:
    //       acceso = false; // Repartidor
    //       break;
    //     // default:
    //     //   this.router.navigateByUrl('/');
    //     //   break;
    //   }
    //   return acceso;
    // }


    this.getGridSucursal();

    this.paginatorInt.firstPageLabel = 'Primera página';
    this.paginatorInt.lastPageLabel = 'Última página';
    this.paginatorInt.itemsPerPageLabel = 'Registro por página';
    this.paginatorInt.nextPageLabel     = 'Siguiente página';
    this.paginatorInt.previousPageLabel = 'Anterior página';

  }

  reloadgrid() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.getGridSucursal();
    Swal.close();
  }

  acceso(  idmodulo: number, idsubmodulo: number, row) {
    this.openDialogSucursal(row);
  }

  getGridSucursal() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumns = ['idsucursal', 'estaactivo', 'entregadomicilio', 'aplicacostoenvio', 'sucursal', 'abreviatura', 'direccion', 'telefono', 'entrada', 'salida', 'actions'];
    this.catsucursal.getSucursalCatalogoGrid(this.paramsucursalmodel).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.tabla = resp;
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de sucursales en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de sucursales en el servidor'
            });
            break;

      }
    });
  }

  openDialogSucursal(rows): void {
    if ( rows === undefined ) {
      const dialogRef = this.dialog.open( SucursalDialogComponent , {

        data: {titulo: 'Agregar la sucursal',
          row: '',
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridSucursal();
      });
    } else {
      const dialogRef = this.dialog.open( SucursalDialogComponent , {
        data: {titulo: 'Actualizar sucursal',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.80),
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getGridSucursal();
      });
    }
  }

  openDialogImpimir(rows): void {
    // if ( rows === undefined ) {
      const dialogRef = this.dialog.open( SucursalImprimirDialogComponent , {
        data: {titulo: 'Vista previa',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 1.00),
      });
      dialogRef.afterClosed().subscribe(result => {
       // this.getGridSucursal();
      });
    // }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tablaFilter(filterValue.trim().toLowerCase());
    this.dataSource.filter = filterValue.trim().toLowerCase();

  }

  tablaFilter(cadena: string) {

    // llenar la tabla para el reporte
    // const cadena = filterValue.trim().toLowerCase();
    const tablatemp = [];
    for (let i=0; i < this.tabla.length; i++) {
      if (this.tabla[i].direccion.trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].abreviatura.trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].sucursal.trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].telefono.trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].entrada.trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].salida.trim().toLowerCase().indexOf(cadena) > -1
         ) {
        tablatemp.push(this.tabla[i]);
      }
    }
    this.tabla = [];
    this.tabla = tablatemp;
    console.log(tablatemp);

  }

  UpdBajaSucursal(UsuarioRow: any) {
    this.bajaSucursal(UsuarioRow);
  }

  bajaSucursal(row: any) {

    const username = row.sucursal;

    // operacion
    // 1. insertar
    // 2. actualizar
    // 3. dar de baja
    // 4. restaurar usuario

    row.operacion = 3;
    row.idusuario = Number(sessionStorage.idu);
    // console.log(row);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Dar de baja ' + username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.catsucursal.abcSucursal(row).subscribe( (resp: any[]) => {
          Swal.close();

          // Swal.fire({
          //   icon: 'success',
          //   text: 'El extra ' + username + ' ha sido dado de baja.'
          // });

          // si regresa respuesta el servicio
          if (resp) {

            if (resp[0].idrespuesta > 0) {
              Swal.fire({
                icon: 'success',
                text: resp[0].respuesta
              });
              this.getGridSucursal();
              } else {
              Swal.fire({
                icon: 'error',
                text: resp[0].respuesta
              });
              }
          }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del panel de sucursales'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del panel de sucursales'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'La sucursal ' + username + ' ha sido dada de baja.',
            'success'
          );

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La baja no fue realizada!',
          'warning'
        );
      }
    });

  }

  updRestaurarSucursal(row: any) {

    this.restaurarSucursal(row);

  }

  restaurarSucursal(row: any) {

    const username = row.sucursal;

    // operacion
    // 1. insertar
    // 2. actualizar
    // 3. dar de baja
    // 4. restaurar usuario

    row.operacion = 4;
    row.idusuario = Number(sessionStorage.idu);
    // console.log(row);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Restaurar ' + username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.catsucursal.abcSucursal(row).subscribe( (resp: any[]) => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            text: 'La sucursal ' + username + ' ha sido restaurada.'
          });

          this.getGridSucursal();

          // si regresa respuesta el servicio
          // if (resp) {

          //   if (resp['idrespuesta'] > 0) {
          //     Swal.fire({
          //       icon: 'success',
          //       text: resp['respuesta']
          //     });
          //     this.getGridExtras();
          //     } else {
          //     Swal.fire({
          //       icon: 'error',
          //       text: resp['respuesta']
          //     });
          //     }
          // }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del panel de sucursales'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del panel de sucursales'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'La sucursal ' + username + ' ha sido restaurada.',
            'success'
          );

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La restauración no fue realizada!',
          'warning'
        );
      }
    });

  }

  // activar o desactivar entrega a domicilio

  updDesactivarEntregaDomicilio(UsuarioRow: any) {
    this.desactivarEntregaDomicilio(UsuarioRow);
  }

  desactivarEntregaDomicilio(row: any) {

    const username = row.sucursal;

    // operacion
    // 1. insertar
    // 2. actualizar
    // 3. dar de baja
    // 4. restaurar usuario
    // 5. desactivar entrega a domicilio
    // 6. activar entrega a domicilio

    row.operacion = 5;
    row.idusuario = Number(sessionStorage.idu);
    // console.log(row);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Desactivar la entrega a domicilio de ' + username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.catsucursal.abcSucursal(row).subscribe( (resp: any[]) => {
          Swal.close();

          // Swal.fire({
          //   icon: 'success',
          //   text: 'El extra ' + username + ' ha sido dado de baja.'
          // });

          // si regresa respuesta el servicio
          if (resp) {

            if (resp[0].idrespuesta > 0) {
              Swal.fire({
                icon: 'success',
                text: resp[0].respuesta
              });
              this.getGridSucursal();
              } else {
              Swal.fire({
                icon: 'error',
                text: resp[0].respuesta
              });
              }
          }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del panel de sucursales'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del panel de sucursales'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'La sucursal ' + username + ' ha sido desactivada la entrega a domicilio.',
            'success'
          );

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La desactivacion de entrega a domicilio no fue realizada!',
          'warning'
        );
      }
    });

  }


  updActivarEntregaDomicilio(UsuarioRow: any) {
    this.activarEntregaDomicilio(UsuarioRow);
  }

  activarEntregaDomicilio(row: any) {

    const username = row.sucursal;

    // operacion
    // 1. insertar
    // 2. actualizar
    // 3. dar de baja
    // 4. restaurar usuario
    // 5. desactivar entrega a domicilio
    // 6. activar entrega a domicilio

    row.operacion = 6;
    row.idusuario = Number(sessionStorage.idu);
    // console.log(row);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Activar la entrega a domicilio de ' + username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.catsucursal.abcSucursal(row).subscribe( (resp: any[]) => {
          Swal.close();

          // Swal.fire({
          //   icon: 'success',
          //   text: 'El extra ' + username + ' ha sido dado de baja.'
          // });

          // si regresa respuesta el servicio
          if (resp) {

            if (resp[0].idrespuesta > 0) {
              Swal.fire({
                icon: 'success',
                text: resp[0].respuesta
              });
              this.getGridSucursal();
              } else {
              Swal.fire({
                icon: 'error',
                text: resp[0].respuesta
              });
              }
          }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del panel de sucursales'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del panel de sucursales'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'La sucursal ' + username + ' ha sido activada la entrega a domicilio.',
            'success'
          );

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La activacion de entrega a domicilio no fue realizada!',
          'warning'
        );
      }
    });

  }

  openDialogFechaEntregaDesactivar(rows: any) {
    if ( rows !== undefined ) {
      const dialogRef = this.dialog.open( SucursalFechaEntregaDomDesacDialogComponent , {
        data: {titulo: 'Desactivar fechas de entrega domicilio',
          row: rows,
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridSucursal();
      });
    }

  }

  // activar o desactivar costo de envio

  updDesactivarCostoEnvio(UsuarioRow: any) {
    this.desactivarCostoEnvio(UsuarioRow);
  }

  desactivarCostoEnvio(row: any) {

    const username = row.sucursal;

    // operacion
    // 1. insertar
    // 2. actualizar
    // 3. dar de baja
    // 4. restaurar usuario
    // 5. desactivar entrega a domicilio
    // 6. activar entrega a domicilio

    row.operacion = 7;
    row.idusuario = Number(sessionStorage.idu);
    // console.log(row);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Desactivar el costo de envio de ' + username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.catsucursal.abcSucursal(row).subscribe( (resp: any[]) => {
          Swal.close();

          // Swal.fire({
          //   icon: 'success',
          //   text: 'El extra ' + username + ' ha sido dado de baja.'
          // });

          // si regresa respuesta el servicio
          if (resp) {

            if (resp[0].idrespuesta > 0) {
              Swal.fire({
                icon: 'success',
                text: resp[0].respuesta
              });
              this.getGridSucursal();
              } else {
              Swal.fire({
                icon: 'error',
                text: resp[0].respuesta
              });
              }
          }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del panel de sucursales'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del panel de sucursales'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'La sucursal ' + username + ' ha sido desactivado el costo de envio.',
            'success'
          );

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La desactivacion del costo de envio no fue realizada!',
          'warning'
        );
      }
    });

  }


  updActivarAplicaCostoEnvio(UsuarioRow: any) {
    this.activarAplicaCostoEnvio(UsuarioRow);
  }

  activarAplicaCostoEnvio(row: any) {

    const username = row.sucursal;

    // operacion
    // 1. insertar
    // 2. actualizar
    // 3. dar de baja
    // 4. restaurar usuario
    // 5. desactivar entrega a domicilio
    // 6. activar entrega a domicilio
    // 7. desactivar costo de envio
    // 8. activar costo de envio
    

    row.operacion = 8;
    row.idusuario = Number(sessionStorage.idu);
    // console.log(row);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Activar el costo de envio de ' + username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.catsucursal.abcSucursal(row).subscribe( (resp: any[]) => {
          Swal.close();

          // Swal.fire({
          //   icon: 'success',
          //   text: 'El extra ' + username + ' ha sido dado de baja.'
          // });

          // si regresa respuesta el servicio
          if (resp) {

            if (resp[0].idrespuesta > 0) {
              Swal.fire({
                icon: 'success',
                text: resp[0].respuesta
              });
              this.getGridSucursal();
              } else {
              Swal.fire({
                icon: 'error',
                text: resp[0].respuesta
              });
              }
          }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del panel de sucursales'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del panel de sucursales'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'La sucursal ' + username + ' ha sido activado el costo de envio.',
            'success'
          );

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La activacion del costo de envio no fue realizada!',
          'warning'
        );
      }
    });

  }


}
