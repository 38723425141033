import { Component, OnInit, ViewChild, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import Swal from 'sweetalert2';

import { ProductoModel, ProductoParamModel } from './../../models/producto/producto.model';
import { PedidoModel } from '../../models/pedido/pedido.model';
import { GridModel } from '../../models/pedido/pedido.model';
import { ProductoService } from '../../services/producto.service';
import { PedidoService } from '../../services/pedido.service';
import { PedidosCambiofechaDialogComponent } from '../pedidos-cambiofecha-dialog/pedidos-cambiofecha-dialog.component';


@Component({
  selector: 'app-pedido-busqueda-valida',
  templateUrl: './pedido-busqueda-valida.component.html',
  styleUrls: ['./pedido-busqueda-valida.component.css']
})
export class PedidoBusquedaValidaComponent implements OnInit {
  pedidosmodel: PedidoModel;
  gridmodel: GridModel;
  tabla: any[];
  idrol: number;
  displayedColumns: string[] = [];
  datasource: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource: any;
  arreglo: any[] = [];
  imagenPerfilvacia: string;
  

  constructor(private dialog: MatDialog,
              public dialogRef: MatDialogRef<PedidoBusquedaValidaComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private productoservice: ProductoService,
              private pedidoservice: PedidoService,
              public paginatorInt: MatPaginatorIntl
    
    
    ) { }

  ngOnInit(): void {
    this.pedidosmodel = new PedidoModel();
    this.gridmodel = new GridModel();
    this.pedidosmodel.idusuario = Number(sessionStorage.idu);
    this.pedidosmodel.idrol = Number(sessionStorage.idr);
    this.idrol = Number(sessionStorage.getItem('idr'));
    this.getGridPedidos();
  }

  getGridPedidos() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumns = ['idpedido', 'estatus_pedido', 'idopenpay_cargo', 'fecha_pedido', 'fecha_entrega', 'no_orden', 'total', 'actions'];    
    this.gridmodel.f_inicial = this.gridmodel.f_inicial == undefined || this.gridmodel == undefined ? '' : this.gridmodel.f_inicial;
    this.gridmodel.f_final = this.gridmodel.f_final == undefined || this.gridmodel == undefined ? '' : this.gridmodel.f_final;
    this.pedidoservice.getPedidoValidaGrid(this.pedidosmodel.idusuario, this.pedidosmodel.idrol, this.gridmodel).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.tabla = resp;
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de extras en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de extras en el servidor'
            });
            break;

      }
    });
  }

  getColorEstatusRow(estatus: number) {
    let colorletra: string;
    switch (estatus) {
      case 2: {
        colorletra = '#d01d17';
        break;
      }
      case 3: {
        colorletra = '#8c1819';
        break;
      }
      case 4: {
        colorletra = '#391410';
        break;
      }

    }

    return colorletra;
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  limpiarGrid() {
    this.dataSource = [];
    this.dataSource.paginator = [];
    this.gridmodel.f_final = '';
    this.gridmodel.f_inicial = '';
  }


}
