import { Component, OnInit, ViewChild, Inject, ViewChildren, AfterViewInit, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';

import { IngredienteService } from '../../services/ingrediente.service';
import { IngredientePanDialogComponent } from '../ingrediente-pan-dialog/ingrediente-pan-dialog.component';
import { IngredienteCubiertaDialogComponent } from '../ingrediente-cubierta-dialog/ingrediente-cubierta-dialog.component';
import { IngredienteRellenoDialogComponent } from '../ingrediente-relleno-dialog/ingrediente-relleno-dialog.component';
import { PanCatalogoModel, PanSucursalCatalogoModel, RellenoCatalogoModel, RellenoSucursalModel, CoberturaCatalogoModel, CoberturaSucursalModel } from '../../models/ingrediente/ingrediente.model';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { IngredientePanSucursalDialogComponent } from '../ingrediente-pan-sucursal-dialog/ingrediente-pan-sucursal-dialog.component';
import { IngredienteRellenoSucursalDialogComponent } from '../ingrediente-relleno-sucursal-dialog/ingrediente-relleno-sucursal-dialog.component';
import { IngredienteCubiertaSucursalDialogComponent } from '../ingrediente-cubierta-sucursal-dialog/ingrediente-cubierta-sucursal-dialog.component';
import { IngredientePanPresentacionDialogComponent } from '../ingrediente-pan-presentacion-dialog/ingrediente-pan-presentacion-dialog.component';


@Component({
  selector: 'app-ingredientes',
  templateUrl: './ingredientes.component.html',
  styleUrls: ['./ingredientes.component.css']
})
export class IngredientesComponent implements OnInit {

  panCatalogomodel: PanCatalogoModel;
  rellenoCatalogomodel: RellenoCatalogoModel;
  coberturaCatalogomodel: CoberturaCatalogoModel;

  panCatalogoSucursalmodel: PanSucursalCatalogoModel;
  rellenoCatalogoSucursalmodel: RellenoSucursalModel;
  cubiertaCatalogoSucursalmodel: CoberturaSucursalModel;

  displayedColumns: string[] = [];
  displayedColumnsP: string[] = [];
  displayedColumnsR: string[] = [];

  displayedColumnsPS: string[] = [];
  displayedColumnsRS: string[] = [];
  displayedColumnsCS: string[] = [];

  datasourcePanes: any;
  datasourcePanesSucursal: any;
  datasourceRellenos: any;
  datasourceRellenosSucursal: any;
  datasourceCubiertas: any;
  datasourceCubiertasSucursal: any;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('panesSucursalPaginator') panesSucursalPaginator: MatPaginator;
  @ViewChild('rellenosPaginator') rellenosPaginator: MatPaginator;
  @ViewChild('rellenosSucursalPaginator') rellenosSucursalPaginator: MatPaginator;
  @ViewChild('cubiertasPaginator') cubiertasPaginator: MatPaginator;
  @ViewChild('cubiertasSucursalPaginator') cubiertasSucursalPaginator: MatPaginator;



  // @ViewChild(MatPaginator, {static: true}) paginatorPS: MatPaginator;
  
// @ViewChildren(MatPaginator) paginatorPS = new QueryList<MatPaginator>();
  
//  @ViewChild('panesPaginator', { read: MatPaginator, static: true }) panesPaginator: MatPaginator;
//  @ViewChild('panesSucursalPaginator', { read: MatPaginator, static: true }) panesSucursalPaginator: MatPaginator;

  dataSource: MatTableDataSource<any>;

  imagenPerfilvacia: string;
  formulario: FormGroup;
  submitted = false;

  idsucursal: number;

  constructor(
              private serviceIngredientes: IngredienteService,
              private dialog: MatDialog,
              public paginatorInt: MatPaginatorIntl,
              public paginatorIntPS: MatPaginatorIntl,
              private formBuilder: FormBuilder
    ) { }

  ngOnInit(): void {

    this.idsucursal = Number(sessionStorage.getItem('ids'));

    this.panCatalogomodel = new PanCatalogoModel();
    this.panCatalogoSucursalmodel = new PanSucursalCatalogoModel();

    this.rellenoCatalogomodel = new RellenoCatalogoModel();
    this.rellenoCatalogoSucursalmodel = new RellenoSucursalModel();

    this.coberturaCatalogomodel = new CoberturaCatalogoModel();
    this.cubiertaCatalogoSucursalmodel = new CoberturaSucursalModel();

    this.imagenPerfilvacia = './assets/images/sinimagen.jpg';
    // this.formulario = this.formBuilder.group({
    //   cobertura: ['', Validators.required]

    // }, {
    // });

    // grid de los catalogos de ingredientes
    this.getGridPanesCatalogo();
    this.getGridRellenosCatalogo();
    this.getGridCubiertasCatalogo();
    // grid de las sucursales de los ingredientes
    this.getGridPanesCatalogoSucursal();
    this.getGridRellenosCatalogoSucursal();
    this.getGridCubiertasCatalogoSucursal();

    this.paginatorInt.firstPageLabel = 'Primera página';
    this.paginatorInt.lastPageLabel = 'Última página';
    this.paginatorInt.itemsPerPageLabel = 'Registro por página';
    this.paginatorInt.nextPageLabel     = 'Siguiente página';
    this.paginatorInt.previousPageLabel = 'Anterior página';

    // panes sucursal
    this.paginatorIntPS.firstPageLabel = 'Primera página';
    this.paginatorIntPS.lastPageLabel = 'Última página';
    this.paginatorIntPS.itemsPerPageLabel = 'Registro por página';
    this.paginatorIntPS.nextPageLabel     = 'Siguiente página';
    this.paginatorIntPS.previousPageLabel = 'Anterior página';
  }

  // PANES

  reloadGridPanesCatalogo() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.getGridPanesCatalogo();
    Swal.close();
  }

  getGridPanesCatalogo() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumnsP = ['pan', 'actions'];
    this.serviceIngredientes.getGridCatPanes().subscribe( (resp: any[]) => {
          Swal.close();
          this.datasourcePanes = new MatTableDataSource<any>(resp);
          this.datasourcePanes.paginator = this.paginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de panes en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de panes en el servidor'
            });
            break;

      }
    });
  }

  applyFilterPanesCatalogo(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasourcePanes.filter = filterValue.trim().toLowerCase();
  }

  openDialogPanes(rows: any) {
    if ( rows === undefined ) {
      const dialogRef = this.dialog.open( IngredientePanDialogComponent , {
        data: {titulo: 'Agregar el pan',
          row: '',
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridPanesCatalogo();
      });
    } else {
      const dialogRef = this.dialog.open( IngredientePanDialogComponent , {
        data: {titulo: 'Modificar el pan',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.80),
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getGridPanesCatalogo();
      });
    }

  }

  // --------------------------

  reloadGridPanesCatalogoSucursal() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.getGridPanesCatalogoSucursal();
    Swal.close();
  }

  getGridPanesCatalogoSucursal() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumnsPS = ['pan', 'sucursal', 'estaactivo', 'actions'];
    this.serviceIngredientes.getGridCatPanesSucursal(this.idsucursal).subscribe( (resp: any[]) => {
          Swal.close();
          this.datasourcePanesSucursal = new MatTableDataSource<any>(resp);
          this.datasourcePanesSucursal.paginator = this.panesSucursalPaginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de panes sucursal en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de panes surcursal en el servidor'
            });
            break;

      }
    });
  }

  applyFilterPanesCatalogoSucursal(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasourcePanesSucursal.filter = filterValue.trim().toLowerCase();
  }

  openDialogPanesSucursal(rows: any) {
    if ( rows === undefined ) {
      const dialogRef = this.dialog.open( IngredientePanSucursalDialogComponent , {
        data: {titulo: 'Agregar la sucursal del pan',
          row: '',
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridPanesCatalogoSucursal();
      });
    } else {
      const dialogRef = this.dialog.open( IngredientePanSucursalDialogComponent , {
        data: {titulo: 'Modificar la sucursal del pan',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.80),
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getGridPanesCatalogoSucursal();
      });
    }

  }

  openDialogPanesPrsentacion(rows: any) {
    if ( rows !== undefined ) {
      const dialogRef = this.dialog.open( IngredientePanPresentacionDialogComponent , {
        data: {titulo: 'Presentaciones del pan',
          row: rows,
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridPanesCatalogoSucursal();
      });
    }

  }


  // fin PANES

  // RELLENOS

  reloadGridRellenosCatalogo() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.getGridRellenosCatalogo();
    Swal.close();
  }

  getGridRellenosCatalogo() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumnsR = ['relleno', 'url_img_relleno', 'actions'];
    this.serviceIngredientes.getGridCatRellenos().subscribe( (resp: any[]) => {
          Swal.close();
          this.datasourceRellenos = new MatTableDataSource<any>(resp);
          this.datasourceRellenos.paginator = this.rellenosPaginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de rellenos en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de rellenos en el servidor'
            });
            break;

      }
    });
  }

  applyFilterRellenosCatalogo(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasourceRellenos.filter = filterValue.trim().toLowerCase();
  }

  openDialogRelleno(rows: any) {
    if ( rows === undefined ) {
      const dialogRef = this.dialog.open( IngredienteRellenoDialogComponent , {
        data: {titulo: 'Agregar el relleno',
          row: '',
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridRellenosCatalogo();
      });
    } else {
      const dialogRef = this.dialog.open( IngredienteRellenoDialogComponent , {
        data: {titulo: 'Modificar el relleno',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.80),
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getGridRellenosCatalogo();
      });
    }

  }

  // -------------------------
  reloadGridRellenosCatalogoSucursal() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.getGridRellenosCatalogoSucursal();
    Swal.close();
  }

  getGridRellenosCatalogoSucursal() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumnsRS = ['relleno', 'sucursal', 'estaactivo', 'actions'];
    this.serviceIngredientes.getGridCatRellenosSucursal(this.idsucursal).subscribe( (resp: any[]) => {
          Swal.close();
          this.datasourceRellenosSucursal = new MatTableDataSource<any>(resp);
          this.datasourceRellenosSucursal.paginator = this.rellenosSucursalPaginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de rellenos por sucursal en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de rellenos por surcursal en el servidor'
            });
            break;

      }
    });
  }

  applyFilterRellenosCatalogoSucursal(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasourceRellenosSucursal.filter = filterValue.trim().toLowerCase();
  }

  openDialogRellenosSucursal(rows: any) {
    if ( rows === undefined ) {
      const dialogRef = this.dialog.open( IngredienteRellenoSucursalDialogComponent , {
        data: {titulo: 'Agregar la sucursal del relleno',
          row: '',
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridRellenosCatalogoSucursal();
      });
    } else {
      const dialogRef = this.dialog.open( IngredienteRellenoSucursalDialogComponent , {
        data: {titulo: 'Modificar la sucursal del relleno',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.80),
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getGridRellenosCatalogoSucursal();
      });
    }

  }

  // fin de RELLENOS

  // CUBIERTAS

  reloadGridCubiertasCatalogo() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.getGridCubiertasCatalogo();
    Swal.close();
  }

  getGridCubiertasCatalogo() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumns = ['cobertura', 'url_img_cobertura', 'actions'];
    this.serviceIngredientes.getGridCatCoberturas().subscribe( (resp: any[]) => {
          Swal.close();
          this.datasourceCubiertas = new MatTableDataSource<any>(resp);
          this.datasourceCubiertas.paginator = this.cubiertasPaginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de cubiertas en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de cubiertas en el servidor'
            });
            break;

      }
    });
  }

  applyFilterCubiertasCatalogo(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasourceCubiertas.filter = filterValue.trim().toLowerCase();
  }

  openDialogCubierta(rows: any) {
    if ( rows === undefined ) {
      const dialogRef = this.dialog.open( IngredienteCubiertaDialogComponent , {
        data: {titulo: 'Agregar la cubierta',
          row: '',
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridCubiertasCatalogo();
      });
    } else {
      const dialogRef = this.dialog.open( IngredienteCubiertaDialogComponent , {
        data: {titulo: 'Modificar la cubierta',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.80),
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getGridCubiertasCatalogo();
      });
    }

  }

  // -------------------------
  reloadGridCubiertasCatalogoSucursal() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.getGridCubiertasCatalogoSucursal();
    Swal.close();
  }

  getGridCubiertasCatalogoSucursal() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumnsCS = ['cobertura', 'sucursal', 'estaactivo', 'actions'];
    this.serviceIngredientes.getGridCatCubiertasSucursal(this.idsucursal).subscribe( (resp: any[]) => {
          Swal.close();
          this.datasourceCubiertasSucursal = new MatTableDataSource<any>(resp);
          this.datasourceCubiertasSucursal.paginator = this.cubiertasSucursalPaginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de cubiertas por sucursal en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de cubiertas por surcursal en el servidor'
            });
            break;

      }
    });
  }

  applyFilterCubiertasCatalogoSucursal(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasourceCubiertasSucursal.filter = filterValue.trim().toLowerCase();
  }

  openDialogCubiertasSucursal(rows: any) {
    if ( rows === undefined ) {
      const dialogRef = this.dialog.open( IngredienteCubiertaSucursalDialogComponent , {
        data: {titulo: 'Agregar la sucursal de la cubierta',
          row: '',
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridCubiertasCatalogoSucursal();
      });
    } else {
      const dialogRef = this.dialog.open( IngredienteCubiertaSucursalDialogComponent , {
        data: {titulo: 'Modificar la sucursal de la cubierta',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.80),
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getGridCubiertasCatalogoSucursal();
      });
    }

  }

}
