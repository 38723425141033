import { Component, OnInit, Inject, ElementRef, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CombosService } from '../../services/combos.service';

import { SucursalService } from '../../services/sucursal.service';
import { FechasEntregaDomDesactivadosModel, SucursalModel } from '../../models/sucursal/sucursal.model';
import {  Form, NgForm, FormControl } from '@angular/forms';

@Component({
  selector: 'app-sucursal-fecha-entrega-dom-desac-abc-dialog',
  templateUrl: './sucursal-fecha-entrega-dom-desac-abc-dialog.component.html',
  styleUrls: ['./sucursal-fecha-entrega-dom-desac-abc-dialog.component.css']
})
export class SucursalFechaEntregaDomDesacAbcDialogComponent implements OnInit {

  formulario: FormGroup;
  submitted = false;
  combotiendas: any[];
  idrol: number;
  fechaentregamodel: FechasEntregaDomDesactivadosModel;
  // paramdianolaboralmodel: ParamDianolaboralModel;
  fechaentrega: string;

  constructor(public dialogRef: MatDialogRef<SucursalFechaEntregaDomDesacAbcDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private service : SucursalService,
              private combosservicio: CombosService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.idrol = Number(sessionStorage.idr);
    this.fechaentregamodel = new FechasEntregaDomDesactivadosModel();
    // this.paramdianolaboralmodel = new ParamDianolaboralModel();

    this.fechaentregamodel.idsucursal = Number(this.data.idsucursal);
    this.fechaentregamodel.usuario_creada = Number(sessionStorage.idu);
    
    console.log(this.data.row);
    console.log(this.fechaentrega);

    if (this.data.row.id > 0) {
      this.fechaentregamodel = this.data.row;
      this.fechaentrega = this.data.row.fecha_entrega_dom;
    }

  }

  onSubmit() {
    this.submitted = true;
      // detener si el formulario es invalido
    /* if (this.formulario.invalid) {
          return;
    } */
    this.abcFechaEntregaDesac();
  }

  abcFechaEntregaDesac() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    // this.sucursalmodel.logitud = this.sucursalmodel.longitud;
    // si trae un registro el valor de iddiafestivo es mayor a 0 entonces operacion 2 modificar
    // en caso contrario es operacion 1 Agregar
    this.fechaentregamodel.operacion = this.fechaentregamodel.id > 0 ? 2 : 1;
    // if (this.idrol > 1) {
    //   this.fechaentregamodel.idtienda = 1; // esta hardcodeado
    // }
    console.log(this.fechaentregamodel);  
    this.service.abcfechaentregadomdesactivar(this.fechaentregamodel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.fechaentregamodel.operacion === 1) {
          this.fechaentregamodel.id = resp[0].idrespuesta !== -1 || resp[0].idrespuesta !== -2 ? resp[0].idrespuesta : 0 ;
          this.fechaentregamodel.operacion = this.fechaentregamodel.operacion === 1 ? 2 : 2;
        }
        if (resp) {
          if (resp[0].idrespuesta > 0) {
            Swal.fire({
              icon: 'success',
              text: resp[0].respuesta,
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           } else {
            Swal.fire({
              icon: 'error',
              text: resp[0].respuesta,
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           }
        }
        }, (err) => {
         Swal.close();
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor o revise su conexión a internet.',
                confirmButtonColor: '#391410',
                confirmButtonText: 'ACEPTAR'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado de fechas.',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado de fechas.',
                  confirmButtonColor: '#391410',
                  confirmButtonText: 'ACEPTAR'
                });
                break;
          }
        });

  }

  getComboTienda() {
    this.combosservicio.getComboTiendas().subscribe( (resp: any[]) => {
        this.combotiendas = resp;
        console.log(this.combotiendas);
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor o revise su conexión a internet.',
            confirmButtonColor: '#391410',
            confirmButtonText: 'ACEPTAR'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor',
          confirmButtonColor: '#391410',
          confirmButtonText: 'ACEPTAR'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
            break;
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  

}
