import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSettings } from '../models/appsettings';
import { VentasParamModel, VentasTotalesModel, VentasIndicador3Model } from '../models/ventas/venta.model';


@Injectable({
  providedIn: 'root'
})
export class VentaService {

  private header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  .append('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  private headerJSON = new HttpHeaders().set('Content-Type', 'application/json');

  private headerQP = new HttpHeaders().set('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  constructor(private http: HttpClient) { }

  getQueryParam( query: string) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.get(url, { headers: this.headerQP });
  }

  getFormParam( query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.header });
  }

  getFormParamJSON(query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.headerJSON });
  }

  getGridVentas(data: VentasParamModel) {
    return this.getQueryParam('indicadores/ventasporproducto?idsucursal=' + data.idsucursal + '&idrol=' + data.idrol + '&fecha_inicial=' + data.fecha_inicial + '&fecha_final=' + data.fecha_final);
  }

  getGraficaVentaSucursal(data: VentasParamModel) {
    return this.getQueryParam('indicadores/ventassucursales?idsucursal=' + data.idsucursal + '&idrol=' + data.idrol + '&fecha_inicial=' + data.fecha_inicial + '&fecha_final=' + data.fecha_final);
  }

  getGraficaVentaTotales(data: VentasParamModel) {
    return this.getQueryParam('indicadores/ventastotales?idsucursal=' + data.idsucursal + '&idrol=' + data.idrol + '&fecha_inicial=' + data.fecha_inicial + '&fecha_final=' + data.fecha_final);
  }

  getGraficaVentaSucursalProducto(data: VentasParamModel) {
    return this.getQueryParam('indicadores/ventasporproducto?idsucursal=' + data.idsucursal + '&idrol=' + data.idrol + '&fecha_inicial=' + data.fecha_inicial + '&fecha_final=' + data.fecha_final);
  }


}
