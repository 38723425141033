export class ExtrasModel {
    idextra: number;
    idsucursal: number;
    idrol: number;
    idusuario: number;
    extra: string;
    sucursal: string;
    precio: number;
    telefono: string;
    requiereDist: boolean;
    operacion: number;
    estaActivo: boolean;
    tienda: string;
}
