import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Dimensions,  ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

import { CoberturaCatalogoModel } from '../../models/ingrediente/ingrediente.model';
import { IngredienteService } from '../../services/ingrediente.service';


@Component({
  selector: 'app-ingrediente-cubierta-dialog',
  templateUrl: './ingrediente-cubierta-dialog.component.html',
  styleUrls: ['./ingrediente-cubierta-dialog.component.css']
})
export class IngredienteCubiertaDialogComponent implements OnInit {

  formulario: FormGroup;
  submitted = false;
  ingredientemodel: CoberturaCatalogoModel;
  verImagenes: boolean;

  constructor(public dialogRef: MatDialogRef<IngredienteCubiertaDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder,
              private ingredienteService: IngredienteService) { }

  // imageCropper
  imageChangedEvent: any = '';
  croppedImage: any = '';

  ngOnInit(): void {
      this.verImagenes = false;
      this.formulario = this.formBuilder.group({
      //  cobertura: ['', Validators.required],
          cobertura: ['', Validators.required]
      }, {
      });
    //  this.ingredientemodel.idcobertura = 0;
      this.ingredientemodel = new CoberturaCatalogoModel();
      if (this.data.row.idcobertura > 0) {
        this.verImagenes = true;
        this.ingredientemodel = this.data.row;
      }

  }

  // variable para acceder a los campos del formulario
  get f() { return this.formulario.controls; }

  get coberturaNoValido() {
    return this.formulario.get('cobertura').invalid;
  }

  abcCatCobertura() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    this.ingredientemodel.operacion = this.ingredientemodel.idcobertura > 0 ? 2 : 1;
    this.ingredientemodel.idusuario = Number(sessionStorage.getItem('idu'));
    console.log(this.ingredientemodel);
    this.ingredienteService.abcCatCoberturas(this.ingredientemodel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.ingredientemodel.operacion === 1) {
          this.ingredientemodel.idcobertura = resp['idrespuesta'] !== -1 || resp['idrespuesta'] !== -2 ? resp['idrespuesta'] : 0 ;
          this.ingredientemodel.operacion = this.ingredientemodel.operacion === 1 ? 2 : 2;
        }
        if (resp) {
          if (resp['idrespuesta'] > 0) {
            Swal.fire({
              icon: 'success',
              text: resp['respuesta'],
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           } else {
            Swal.fire({
              icon: 'error',
              text: resp['respuesta'],
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           }
        }
        }, (err) => {
         Swal.close();
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor o revise su conexión a internet.',
                confirmButtonColor: '#391410',
                confirmButtonText: 'ACEPTAR'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado del catálogo de cubiertas.',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado del catálogo de cubiertas.',
                  confirmButtonColor: '#391410',
                  confirmButtonText: 'ACEPTAR'
                });
                break;
          }
        });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {

    this.submitted = true;

    // se detiene si el formulario es invalido
    if (this.formulario.invalid) {
        return;
    }
    this.abcCatCobertura();

    // valores visibles del formmulario
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
    // valores del modelo que se pasa al abc
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.extrasmodel, null, 4));

  }

  fileChangeEvent(event1: any): void {
    this.imageChangedEvent = event1;
  }

  imageCropped(event1: ImageCroppedEvent) {
    this.croppedImage = event1.base64;
    this.ingredientemodel.imagenb64 = this.croppedImage;
  }

  imageLoaded() {
    // show cropper
    // tslint:disable-next-line: deprecation
  //  event = null;
  }

  cropperReady() {
    // cropper ready
    // this.productomodel.imagenb64 = this.croppedImage;
    // console.log("principal");
    // console.log(this.productomodel.imagenb64);
  }

  loadImageFailed() {
    // show message
  }

}
