import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { BannerService } from '../../services/banner.service';
import { BannerParamModel, BannerModel } from '../../models/banner/banner.model';
import { Dimensions,  ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { CombosService } from '../../services/combos.service';



@Component({
  selector: 'app-banner-dialog',
  templateUrl: './banner-dialog.component.html',
  styleUrls: ['./banner-dialog.component.css']
})
export class BannerDialogComponent implements OnInit {

  verImagenes: boolean;

  parambannerlmodel: BannerParamModel;
  bannermodel: BannerModel;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  combosucursales: any[];

  idsucursal: number;
  idrol: number;

  constructor(public dialogRef: MatDialogRef<BannerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private bannerservice: BannerService,
              private comboservice: CombosService) { }

  ngOnInit(): void {
    this.bannermodel = new BannerModel();
    this.parambannerlmodel = new BannerParamModel();

    this.verImagenes = false;

    this.idsucursal = 0;
    this.idrol = 1;
    this.getComboSucursal();

    this.bannermodel.idbanner_sucursal = 0;
    if (this.data.row.idbanner_sucursal > 0) {
      this.bannermodel = this.data.row;
      this.verImagenes = true;
    }

  }

  getComboSucursal() {
    this.comboservice.getComboPanelSucursal(this.idsucursal, this.idrol).subscribe( (resp: any[]) => {
        this.combosucursales = resp;
        console.log(this.combosucursales);
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }


  idSucursalUsuario(idSucursalUsuario: any, idrolUsuario: any) {
    throw new Error("Method not implemented.");
  }
  idrolUsuario(idSucursalUsuario: any, idrolUsuario: any) {
    throw new Error("Method not implemented.");
  }



  abcCatBanner() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    this.bannermodel.operacion = this.bannermodel.idbanner_sucursal > 0 ? 2 : 1;
    this.bannerservice.abcBannerProducto(this.bannermodel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.bannermodel.operacion === 1) {
          this.bannermodel.idbanner_sucursal = resp['idrespuesta'] !== -1 || resp['idrespuesta'] !== -2 ? resp['idrespuesta'] : 0 ;
          this.bannermodel.operacion = this.bannermodel.operacion === 1 ? 2 : 2;
        }
        if (resp) {
          if (resp['idrespuesta'] > 0) {
            Swal.fire({
              icon: 'success',
              text: resp['respuesta']
            });
           } else {
            Swal.fire({
              icon: 'error',
              text: resp['respuesta']
            });
           }
        }
        }, (err) => {
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado del catálogo de banner'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado del catálogo de banner'
                });
                break;
          }
        });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
      this.bannermodel.imagenbase64 = this.croppedImage;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
      // cropper ready
     // this.bannermodel.imagenbase64 = this.croppedImage;
      // console.log(this.bannermodel.imagenbase64);
  }

  loadImageFailed() {
      // show message
  }

}
