export class TiendaModel {
    idtienda: number;
    idusuario: number;
    nombre_comercial: string;
    telefono: string;
    esactivo: boolean;
    email: string;
    movil: string;
    upd_fecha: string;
    ins_fecha: string;
    operacion: number;
    idrespuesta: number;
    respuesta: string;
}
