import { Component, OnInit, Inject, ElementRef, NgZone } from '@angular/core';
import { SucursalModel, ParamSucursalModel } from './../../models/sucursal/sucursal.model';
import { SucursalService } from '../../services/sucursal.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { AgmCoreModule, AgmMap, MapsAPILoader, MouseEvent } from '@agm/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CombosService } from '../../services/combos.service';

@Component({
  selector: 'app-sucursal-dialog',
  templateUrl: './sucursal-dialog.component.html',
  styleUrls: ['./sucursal-dialog.component.css']
})
export class SucursalDialogComponent implements OnInit {

  formulario: FormGroup;
  submitted = false;
  paramsucursalmodel: ParamSucursalModel;
  sucursalmodel: SucursalModel;
  mapType: string;
  latitud: number;
  longitud: number;
  address: string;
  private geoCoder;
  combotiendas: any[];
  idrol: number;

  constructor(public dialogRef: MatDialogRef<SucursalDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private catservice: SucursalService,
              private mapa: AgmCoreModule,
              private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              private combosservicio: CombosService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.idrol = Number(sessionStorage.idr);
    this.sucursalmodel = new SucursalModel();
    this.paramsucursalmodel = new ParamSucursalModel();
    this.getComboTienda();
    this.mapType = 'satellite';

    this.sucursalmodel.idsucursal = Number(sessionStorage.ids);
    this.sucursalmodel.idusuario = Number(sessionStorage.idu);
    this.sucursalmodel.latitud = '19.532799';
    this.sucursalmodel.longitud = '-96.908224';
    if (this.data.row.idsucursal > 0) {
      this.sucursalmodel = this.data.row;
    }

    this.latitud =  Number(this.sucursalmodel.latitud);
    this.longitud = Number(this.sucursalmodel.longitud);

    // console.log(this.latitud);
    // console.log(this.longitud);

    // correo: ['', [Validators.required, Validators.email]],

    this.formulario = this.formBuilder.group({
      idtienda: [''],
      sucursal: ['', Validators.required],
      abreviatura: ['', [Validators.required, Validators.maxLength(3), Validators.minLength(3), Validators.pattern('[A-Za-z0-9]+$')]],
      telefono: ['', Validators.required],
      entrada: ['', [Validators.required, Validators.pattern('^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])$')]],
      salida: ['',  [Validators.required, Validators.pattern('^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])$')]],
      direccion: ['', Validators.required],
      latitud: ['', Validators.required],
      longitud: ['', Validators.required],
      radio_repartidor: ['', Validators.required]
    },
    {
       validators: this.catservice.validaHorario('entrada', 'salida')
      // los parametros de esta funcion recibe como parametros los validator entrada y salida
    });

  }

  get f() { return this.formulario.controls; }

  get idtiendaNoValido() {
    return this.formulario.get('idtienda').invalid;
  }

  get sucursalNoValido() {
    return this.formulario.get('sucursal').invalid;
  }

  get abreviaturaNoValido() {
    return this.formulario.get('abreviatura').invalid;
  }

  get telefonoNoValido() {
    return this.formulario.get('telefono').invalid;
  }

  get entradaNoValido() {
    return this.formulario.get('entrada').invalid;
  }

  get salidaNoValido() {
    // return this.formulario.get('salida').invalid;
    const entrada = this.formulario.get('entrada').value;
    const salida = this.formulario.get('salida').value;
    return ( entrada < salida ) ? false : true;
  }

  get direccionNoValido() {
    return this.formulario.get('direccion').invalid;
  }

  get latitudNoValido() {
    return this.formulario.get('latitud').invalid;
  }

  get longitudNoValido() {
    return this.formulario.get('longitud').invalid;
  }

  get radio_repartidorNoValido() {
    return this.formulario.get('radio_repartidor').invalid;
  }

  onSubmit() {
      this.submitted = true;
        // detener si el formulario es invalido
      if (this.formulario.invalid) {
            return;
      }
      this.abcCatSucursal();
  }

  abcCatSucursal() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    // this.sucursalmodel.logitud = this.sucursalmodel.longitud;
    this.sucursalmodel.operacion = this.sucursalmodel.idsucursal > 0 ? 2 : 1;
    if (this.idrol > 1) {
      this.sucursalmodel.idtienda = 1; // esta hardcodeado
    }
    this.catservice.abcSucursal(this.sucursalmodel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.sucursalmodel.operacion === 1) {
          this.sucursalmodel.idsucursal = resp[0].idrespuesta !== -1 || resp[0].idrespuesta !== -2 ? resp[0].idrespuesta : 0 ;
          this.sucursalmodel.operacion = this.sucursalmodel.operacion === 1 ? 2 : 2;
        }
        if (resp) {
          if (resp[0].idrespuesta > 0) {
            Swal.fire({
              icon: 'success',
              text: resp[0].respuesta,
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           } else {
            Swal.fire({
              icon: 'error',
              text: resp[0].respuesta,
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           }
        }
        }, (err) => {
         Swal.close();
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor o revise su conexión a internet.',
                confirmButtonColor: '#391410',
                confirmButtonText: 'ACEPTAR'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado del catálogo de sucursales',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado del catálogo de sucursales',
                  confirmButtonColor: '#391410',
                  confirmButtonText: 'ACEPTAR'
                });
                break;
          }
        });

  }

  getComboTienda() {
    this.combosservicio.getComboTiendas().subscribe( (resp: any[]) => {
        this.combotiendas = resp;
        console.log(this.combotiendas);
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor o revise su conexión a internet.',
            confirmButtonColor: '#391410',
            confirmButtonText: 'ACEPTAR'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor',
          confirmButtonColor: '#391410',
          confirmButtonText: 'ACEPTAR'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
            break;
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeLocation(event: any) {
  //  this.sucursalmodel.latitud = event.coords.lat;
  //  this.sucursalmodel.longitud = event.coords.lng;
    // this.destination.latitude = event.coords.lat;
    // this.destination.longitude = event.coords.lng;
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.sucursalmodel.latitud = String($event.coords.lat);
    this.sucursalmodel.longitud = String($event.coords.lng);
   // this.getAddress(this.latitude, this.longitude);
  }


}
