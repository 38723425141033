import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { PedidoService } from '../../services/pedido.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pagorechazado',
  templateUrl: './pagorechazado.component.html',
  styleUrls: ['./pagorechazado.component.css']
})
export class PagorechazadoComponent implements OnInit {
  cargoid: string;
  estatusCargo: string;
  estatusMensaje: string;
  estatusCargoSpanish: string;
  estatusMensajeSpanish: string;
  codigoerror: string;  

  constructor(private pedidoService: PedidoService,
              private router: ActivatedRoute) { 
              this.cargoid = router.snapshot.paramMap.get('cargoid');
            }

  ngOnInit(): void {

    this.pedidocargorespuesta(this.cargoid);
  }

  pedidocargorespuesta(cargoid:string) {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Obteniendo la informacion del rechazo.'
    });
    Swal.showLoading();
    
    this.pedidoService.getEstatusCargoPedidoNew(cargoid).subscribe( (resp: any) => {
      Swal.close();
      setTimeout(() => {
        
        console.log(resp);
         if(resp.status == 'completed'){ 
           this.estatusCargo = 'Completada';
           this.estatusMensaje = String(resp.errorMessage);
           this.codigoerror = String(resp.errorCode);     
         } else {
          this.estatusCargo = resp.status;
          this.estatusMensaje = String(resp.errorMessage);
          this.codigoerror = String(resp.errorCode); 
          switch (this.estatusCargo) {
            case "failed":
              this.estatusCargoSpanish = "Cargo Fallido";
              break;
            case "charge_pending":
              this.estatusCargoSpanish = "Cargo pendiente, el cliente debe consultar su banca si le realizó el cargo."
              this.estatusMensaje = "Cargo pendiente, el cliente debe consultar su banca si le realizó el cargo."
              break;
          
            default:
              this.estatusCargoSpanish = "-" + this.estatusCargo;
              break;
          };
          switch (this.codigoerror) {
              case "1000":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Hay un problema con el servicio de open pay."
              break;
              case "1001":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El cvv debe ser de 3 digitos."
              break;
              case "1002":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La respuesta no fue autenticada o es incorrecta."
              break;
              case "1003":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La operacion no puede proceder por que estan incorrectos los datos de entrada."
              break;
              case "1004":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El servicio de open pay no esta disponible."
              break;
              case "1005":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Un recurso requerido no existe."
              break;
              case "1006":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Conflicto Ya existe una transacción con el mismo ID de pedido."
              break;
              case "1007":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La transferencia de fondos entre la cuenta bancaria o tarjeta y la cuenta de Openpay fue rechazada."
              break;
              case "1008":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Una de las cuentas requeridas está desactivada."
              break;
              case "1009":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El cuerpo de la solicitud es demasiado grande."
              break;
              case "1010":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La clave pública se usa para realizar una solicitud que requiere la clave privada, o la clave privada se usa desde Javascript."
              break;
              case "1011":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El recurso se eliminó anteriormente."
              break;
              case "1012":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El monto de la transacción está fuera de los límites."
              break;
              case "1013":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La operación no está permitida en el recurso."
              break;
              case "1014":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La cuenta está inactiva."
              break;
              case "1015":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "No se pudo obtener ninguna respuesta de la puerta de enlace."
              break;
              case "1016":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El correo electrónico del comerciante ya ha sido procesado."
              break;
              case "1017":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La pasarela de pago no está disponible en este momento, inténtalo de nuevo más tarde."
              break;
              case "1018":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El número de reintentos de carga es superior al permitido."
              break;
              case "1020":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El número de dígitos decimales no es válido para esta moneda."
              break;
              case "2001":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La cuenta de banco con esta CLABE ya se encuentra registrada en el cliente."
              break;
              case "2002":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La tarjeta con este número ya se encuentra registrada en el cliente."
              break;
              case "2003":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El cliente con este identificador externo (External ID) ya existe."
              break;
              case "2004":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La fecha de expiración de la tarjeta es anterior a la fecha actual."
              break;
              case "2005":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La fecha de la tarjeta ha expirado."
              break;
              case "2006":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El código de seguridad de la tarjeta (CVV2) no fue proporcionado."
              break;
              case "2007":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El número de tarjeta es de prueba, solamente puede usarse en Sandbox."
              break;
              case "2008":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La tarjeta no es valida para pago con puntos."
              break;
              case "2009":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El código de seguridad de la tarjeta (CVV2) es inválido."
              break;
              case "2010":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Autenticación 3D Secure fallida."
              break;
              case "2011":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Tipo de tarjeta no soportada."
              break;
              case "3001":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La tarjeta fue declinada por el banco."
              break;
              case "3002":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La tarjeta ha expirado."
              break;
              case "3003":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La tarjeta no tiene fondos suficientes."
              break;
              case "3004":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La tarjeta ha sido identificada como una tarjeta robada."
              break;
              case "3005":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La tarjeta en riesgo de ser detectada por el sistema antifraude."
              break;
              case "3006":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La operación no esta permitida para este cliente o esta transacción."
              break;
              case "3008":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La tarjeta no es soportada en transacciones en linea."
              break;
              case "3009":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La tarjeta fue reportada como perdida."
              break;
              case "3010":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El banco ha restringido la tarjeta."
              break;
              case "3011":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El banco ha solicitado que la tarjeta sea retenida. Contacte al banco."
              break;
              case "3012":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Se requiere solicitar al banco autorización para realizar este pago."
              break;
              case "3201":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Comerciante no autorizada para usar promociones."
              break;
              case "3203":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Promoción no valida para este tipo de tarjetas."
              break;
              case "3204":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El monto de la transacción es menor al mínimo permitido para la promoción."
              break;
              case "3205":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Promoción no permitida."
              break;
              case "15000":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Not Authenticated /Account Not Verified; Transaction denied"
              break;
              case "15003":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Operación no permitida."
              break;
              case "4001":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La cuenta de open pay no tiene fondos suficientes"
              break;
              case "4002":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La operacion no se puede completar"
              break;

            default:
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Respuesta";
              break;
          }
         };
      }, 3000);
      Swal.fire({
        allowOutsideClick: false,
      //  icon: 'info',
        text: 'Finalizar',
        confirmButtonText: 'Aceptar'
      });
        
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' en Openpay'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' inviable conectar con Openpay'
            });
            break;
        default: 
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status
            });
            break;     
      }
    });
  }

}
