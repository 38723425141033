export class PanCatalogoModel {
    idpan: number;
    pan: string;
    idusuario: number;
    operacion: number;
}

export class PanSucursalCatalogoModel {
    idpanes_sucursales: number;
    idsucursal: number;
    idpan: number;
    estaactivo: boolean;
    idusuario: number;
    operacion: number;
}

export class PanPresentacionCatalogoModel {
    idtienda: number;
    idsucursal: number;
    idpanespresentacion: number;
    idpresentacion: number;
    idpan: number;
    url_img_panpresentacion: string;
    imagenbase64: string;
    estaactivo: boolean;
    idusuario: number;
    operacion: number;
}


export class CoberturaCatalogoModel {
    idcobertura: number;
    cobertura: string;
    url_img_cobertura: string;
    imagenb64: string;
    idusuario: number;
    operacion: number;
}

export class CoberturaSucursalModel {
    idcobertura_sucursales: number;
    idsucursal: number;
    idcobertura: number;
    cobertura: string;
    estaactivo: boolean;
    url_img_cobertura: string;
    imagenb64: string;
    idusuario: number;
    operacion: number;
}

export class RellenoCatalogoModel {
    idrelleno: number;
    relleno: string;
    url_img_relleno: string;
    imagenb64: string;
    idusuario: number;
    operacion: number;
}

export class RellenoSucursalModel {
    idrellenos_sucursales: number;
    idsucursal: number;
    idrelleno: number;
    relleno: string;
    estaactivo: boolean;
    idusuario: number;
    operacion: number;
}






