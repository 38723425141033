import { Component, OnInit, ViewChild, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import Swal from 'sweetalert2';


import { ProductoModel, ProductoParamModel } from './../../models/producto/producto.model';

import { ProductoService } from '../../services/producto.service';
import { PedidoService } from '../../services/pedido.service';
import { PedidoDetalleModel } from '../../models/pedido/pedido.model';
import { PedidosCambiofechaDialogComponent } from '../pedidos-cambiofecha-dialog/pedidos-cambiofecha-dialog.component';

@Component({
  selector: 'app-pedido-detalle',
  templateUrl: './pedido-detalle.component.html',
  styleUrls: ['./pedido-detalle.component.css']
})
export class PedidoDetalleComponent implements OnInit {

  constructor(private dialog: MatDialog,
              public dialogRef: MatDialogRef<PedidoDetalleComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private productoservice: ProductoService,
              private pedidoservice: PedidoService,
              public paginatorInt: MatPaginatorIntl) {

                interface ExtrasItem {
                  idcarrito_extras: number;
                  extra: string;
                  distribucion: string;
                  cantidad: string;
                  precio: string;
                }

                interface Producto {
                  idproducto: number;
                  producto: string;
                  url_imagen: string;
                  cantidad: string;
                  extras: Array<ExtrasItem>;
                }

                const ELEMENT_DATA = new Array<Producto>();

               }

  paramproductolmodel: ProductoParamModel;
  producto: ProductoModel;
  pedidodetalle: PedidoDetalleModel;

  displayedColumns: string[] = [];

  arreglo: any[] = [];
  imagenPerfilvacia: string;
 datasource: any;
 @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
 dataSource: MatTableDataSource<any>;


ngOnInit(): void {

    this.imagenPerfilvacia = './assets/iconouser/pastel1.jpg';
    this.pedidodetalle = new PedidoDetalleModel();
    this.paramproductolmodel = new ProductoParamModel();

    this.pedidodetalle = this.data.row;
    // console.log(this.pedidodetalle);
    this.getGridProductos();

    this.paginatorInt.firstPageLabel = 'Primera página';
    this.paginatorInt.lastPageLabel = 'Última página';
    this.paginatorInt.itemsPerPageLabel = 'Registro por página';
    this.paginatorInt.nextPageLabel     = 'Siguiente página';
    this.paginatorInt.previousPageLabel = 'Anterior página';
  }

  getGridProductos() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumns = ['idproducto', 'url_imagen_miniatura', 'producto', 'cantidad', 'presentacion', 'leyenda', 'extras'];
    this.pedidoservice.getDetallePedido( Number(this.pedidodetalle.idpedido)).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.arreglo = Array.from(resp);
         // this.dataSource = this.arreglo;
          this.dataSource.paginator = this.paginator;
          // console.log(this.arreglo);
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de productos en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de productos en el servidor'
            });
            break;

      }
    });
  }

  // opencambiarFechaEntrega(rows): void {
  //     const dialogRef = this.dialog.open( PedidosCambiofechaDialogComponent , {
  //       data: {titulo: 'Cambio de fecha de entrega',
  //       row: rows
  //     },
  //     disableClose: true,
  //     height: String(screen.height * 0.90),
  //     width: String(screen.width * 0.90),
  //     });
  //     dialogRef.afterClosed().subscribe(result => {
  //      // this.getGridSucursal();
  //     });
    
  // }

  getColorEstatusRow(estatus: number) {
    let colorletra: string;
    switch (estatus) {
      case 2: {
        colorletra = '#d01d17';
        break;
      }
      case 3: {
        colorletra = '#8c1819';
        break;
      }
      case 4: {
        colorletra = '#391410';
        break;
      }

    }

    return colorletra;
  }

accesoDetalle(row) {
    this.getGridProductos();
  }

applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

onNoClick(): void {
    this.dialogRef.close();
  }


}
