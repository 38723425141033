import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { MatDialog} from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import * as jsPDF from 'jspdf';
import * as Chart from 'chart.js';

import { VentaService } from '../../services/venta.service';
import { VentasParamModel, VentasSucursalModel, VentasTotalesModel, VentasIndicador3Model } from '../../models/ventas/venta.model';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { decode } from 'punycode';
import * as jQuery from 'jquery';
import { VentasprodImprimirDialogComponent } from '../ventasprod-imprimir-dialog/ventasprod-imprimir-dialog.component';
import { ResumenVentasComponent } from '../resumen-ventas/resumen-ventas.component';



export const DD_MM_YYYY_Format = {
  parse: {
      dateInput: 'LL',
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.css'],
  providers: [{provide: MAT_DATE_FORMATS, useValue: DD_MM_YYYY_Format}]
})


export class VentasComponent implements OnInit {


  // gráficas
  ventasParamModel: VentasParamModel = new VentasParamModel();
  ventasIndicador1Model: VentasSucursalModel = new VentasSucursalModel();
  ventasTotalesModel: VentasTotalesModel = new VentasTotalesModel();
 // ventasIndicador3Model: VentasIndicador3Model = new VentasIndicador3Model();
  canvas: any;
  ctx: any;
  tabla: any[];

  @ViewChild('tabladiv') tabladiv: ElementRef;
  @ViewChild('graficaVentasSucursales') graficaventassucursales: ElementRef;
  cosecutivo: number;
  displayedColumns: string[] = [];
  datasource: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  fechaIni: Date;
  fechaFin: Date;

  tipografica: string;


  constructor(private dialog: MatDialog,
              public paginatorInt: MatPaginatorIntl,
              private serviceVentas: VentaService) { }

  ngOnInit(): void {
    this.fechaIni = new Date();
    this.fechaFin = new Date();

    this.ventasParamModel.idsucursal = Number(sessionStorage.ids);
    this.ventasParamModel.idrol = Number(sessionStorage.idr);

    this.ventasParamModel.fecha_inicial = '';
    this.ventasParamModel.fecha_final = '';

    switch (this.ventasParamModel.idrol) {
      case 1 : {
              this.tipografica = 'bar';
              break;
            }
      case 2 : {
              this.tipografica = 'bar';
              break;
            }
      case 3 : {
              this.tipografica = 'bar';
              break;
      }
      default : {
              this.tipografica = 'bar';
              break;
      }

    }

    this.MuestraGrafica( this.tipografica );


    function generaFecha(data: Date): any {
      const dd =  data.getDate();
      const mm = data.getMonth() + 1;
      const aaaa = data.getFullYear();
      return dd + '/' + mm + '/' + aaaa;
    }

    this.getVentasTotales();

    function graficaVentasTotales(mycanvas: any, myctx: any, respondidos: number, pendientes: number): number {
            mycanvas = document.getElementById('myChart');
            myctx = mycanvas.getContext('2d');
            const myChart = new Chart(myctx, {
              type: 'doughnut',
              data: {
                  labels: ['Respondidos', 'Pendientes'],
                  datasets: [{
                      label: 'Avance',
                      data: [respondidos, pendientes],
                      backgroundColor: [
                          'rgba(41, 138, 8, 1)',
                          'rgba(255, 206, 86, 1)'
                      ],
                      borderWidth: 1
                  }]
              },
              options: {
                responsive: false,
                display: true,
                legend: {
                  display: true,
                  position: 'bottom'
                }
              }
            });
            return 0;
    }


    function graficaVentasSucursal2( mycanvas: any, myctx: any, datos): number {

        
          const sucursal = [];
          const totalventa = [];
          const numeroventas = [];
          for (const index in datos) {
            sucursal[index] = [datos[index].sucursal];
            totalventa[index] =  Math.round(Number(datos[index].total_venta));
            numeroventas[index] = Math.round(Number(datos[index].numero_ventas));
          }
          mycanvas = document.getElementById('graficaVentasSucursales');
          myctx = mycanvas.getContext('2d');
          const myChart = new Chart(mycanvas, {
            backgroundColor: '#ffff',
            type: 'bar',
            options: {
              scales: {
                 xAxes: [ {}, {} ],
                 yAxes: [{
                     id: 'totalventa',
                     ticks: {
                      // Incluir signo de pesos al eje y.
                      callback(value, index, values) {
                          return '$' + value;
                      }
                    }
                 }, {
                     id: 'numeroventas'
                 }]
              },
              responsive: true,
              display: true,
              legend: {
                display: true,
                position: 'top'
              },
              animation: {
                duration: 2500,
              },
            },
            data: {
                labels: sucursal,
                datasets: [
                  {
                    label: 'Total ventas',
                    data: totalventa,
                    borderColor: '#d01d17',
                    fill: true,
                    backgroundColor: 'rgba(208, 29, 23, 0.4)',
                    yAxisID: 'totalventa'
                  },
                  {
                    label: 'Número ventas',
                    data: numeroventas,
                    borderColor: '#8c1819',
                    backgroundColor: 'rgba(140, 24, 25, 0.4)',
                    fill: true,
                    yAxisID: 'numeroventas'
                  }
                ]
            }
          });
          return 0;
    }

    function graficaVentasSucursalProducto( mycanvas: any, myctx: any, datos): number {

      // console.log('entró graficaVentasSucursalProducto');
          const producto = [];
          const ventaproducto = [];
          const numeroventas = [];
          // tslint:disable-next-line: forin
          for (const index in datos) {
            producto[index] = [datos[index].producto];
            ventaproducto[index] =  Number(datos[index].venta_producto);
           // numeroventas[index] = Math.round(Number(datos[index].numero_ventas));
          }
          // console.log('entró grafica');
          mycanvas = document.getElementById('graficaVentasSucursalesProducto');
          myctx = mycanvas.getContext('2d');
          const myChart = new Chart(mycanvas, {
            backgroundColor: '#ffff',
            type: 'horizontalBar',
            options: {
              scales: {
                 xAxes: [ {} ],
                 yAxes: [{
                     id: 'ventaproducto',
                     ticks: {
                      // Incluir signo de pesos al eje y.
                      callback(value, index, values) {
                          return value;
                      }
                    }
                 }]
              },
              responsive: true,
              display: true,
              legend: {
                display: true,
                position: 'top'
              },
              animation: {
                duration: 2500,
              },
            },
            data: {
                labels: producto,
                datasets: [
                  {
                    label: 'Total ventas',
                    data: ventaproducto,
                    borderColor: '#d01d17',
                    fill: true,
                    backgroundColor: 'rgba(208, 29, 23, 0.4)',
                    yAxisID: 'ventaproducto'
                  }
                  // {
                  //   label: 'Número ventas',
                  //   data: numeroventas,
                  //   borderColor: '#8c1819',
                  //   backgroundColor: 'rgba(140, 24, 25, 0.4)',
                  //   fill: true,
                  //   yAxisID: 'numeroventas'
                  // }
                ]
            }
          });
          return 0;
      }

    // this.ventasParamModel.idsucursal = 1;
    this.getGridVentasProducto();

  }

  MuestraGrafica( tipo: string ) {

     this.serviceVentas.getGraficaVentaSucursal(this.ventasParamModel).subscribe( (resp: any) => {
        // console.log('entró grafica sucursales');
        // console.log(resp);
        const respuesta = graficaVentasSucursal2(this.canvas, this.ctx, resp);
       }, (err) => {

      });

     function graficaVentasSucursal2( mycanvas: any, myctx: any, datos): number {

        // ejemplo prueba
       // const sucursal = ['XALAPA', 'VERACRUZ', 'COATZACOALCOS'];
       //  const total_venta = [1000, 2000, 2500];
       //  const numero_ventas = [5, 6, 3];

        // recide el objeto y los divide en arreglos para pasarlos a la gráfica

        const sucursal = [];
        const totalventa = [];
        const numeroventas = [];
        // tslint:disable-next-line: forin
        for (const index in datos) {
          sucursal[index] = [datos[index].sucursal];
          totalventa[index] =  Math.round(Number(datos[index].total_venta));
          numeroventas[index] = Math.round(Number(datos[index].numero_ventas));
        }
        // console.log('entró grafica');
        mycanvas = document.getElementById('graficaVentasSucursales');
        myctx = mycanvas.getContext('2d');
        const myChart = new Chart(mycanvas, {
          // type: 'line',
          backgroundColor: '#ffffff',
          type: tipo,
          options: {
            scales: {
               xAxes: [ {}, {} ],
               yAxes: [{
                   id: 'totalventa',
                   ticks: {
                    // Incluir signo de pesos al eje y.
                    callback(value, index, values) {
                        return '$' + value;
                    }
                  }
               }, {
                   id: 'numeroventas'
               }]
            },
            responsive: true,
            display: true,
            legend: {
              display: true,
              position: 'top'
            },
            animation: {
              duration: 2500,
            },
          },
          data: {
              labels: sucursal,
              datasets: [
                {
                  label: 'Total ventas',
                  data: totalventa,
                  borderColor: '#d01d17',
                  fill: true,
                  backgroundColor: 'rgba(208, 29, 23)',
                  yAxisID: 'totalventa'
                },
                {
                  label: 'Número ventas',
                  data: numeroventas,
                  borderColor: '#8c1819',
                  backgroundColor: 'rgba(140, 24, 25)',
                  fill: true,
                  yAxisID: 'numeroventas'
                }
              ]
          }
        });
        return 0;
      }

  }

  reloadgrid() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.getGridVentasProducto();
    Swal.close();
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {

    console.log(event.value);
    const dd =  event.value.getDate();
    const mm = event.value.getMonth() + 1;
    const aaaa = event.value.getFullYear();
    this.ventasParamModel.fecha_inicial = aaaa + '-' + (mm < 9 ? '0'+mm : mm) + '-' + (dd < 9 ? '0'+ dd : dd);
    console.log(this.ventasParamModel.fecha_inicial);
    if (this.ventasParamModel.fecha_inicial !== '' && this.ventasParamModel.fecha_final !== '') {
      this.getCargarGraficaSucursales();
      this.getGridVentasProducto();
      this.getVentasTotales();
    }

  }

  addEventF(type: string, event: MatDatepickerInputEvent<Date>) {

    console.log(event.value);
    const ddf =  event.value.getDate();
    const mmf = event.value.getMonth() + 1;
    const aaaaf = event.value.getFullYear();
    this.ventasParamModel.fecha_final = aaaaf + '-' + (mmf < 9 ? '0'+mmf : mmf) + '-' + (ddf < 9 ? '0'+ ddf : ddf);
    console.log(this.ventasParamModel.fecha_final);
    if (this.ventasParamModel.fecha_inicial !== '' && this.ventasParamModel.fecha_final !== '') {
      this.getCargarGraficaSucursales();
      this.getGridVentasProducto();
      this.getVentasTotales();
    }

  }

  

  getVentasTotales(){
    this.serviceVentas.getGraficaVentaTotales(this.ventasParamModel).subscribe( (resp: any) => {
      this.ventasTotalesModel.tienda = resp[0].tienda;
      this.ventasTotalesModel.total_venta =  Number(resp[0].ventas_pesos);
      this.ventasTotalesModel.numero_ventas = Number(resp[0].no_ventas);
      }, (err) => {

  });
  }

getCargarGraficaSucursales() {
        function graficaVentasSucursal2( mycanvas: any, myctx: any, datos): number {

          // recide el objeto y los divide en arreglos para pasarlos a la gráfica

          const sucursal = [];
          const totalventa = [];
          const numeroventas = [];
          // tslint:disable-next-line: forin
          for (const index in datos) {
            sucursal[index] = [datos[index].sucursal];
            totalventa[index] =  Math.round(Number(datos[index].total_venta));
            numeroventas[index] = Math.round(Number(datos[index].numero_ventas));
          }
          // console.log('entró grafica');
          mycanvas = document.getElementById('graficaVentasSucursales');
          myctx = mycanvas.getContext('2d');
          const myChart = new Chart(mycanvas, {
            backgroundColor: '#ffff',
            type: 'line',
            options: {
              scales: {
                 xAxes: [ {}, {} ],
                 yAxes: [{
                     id: 'totalventa',
                     ticks: {
                      // Incluir signo de pesos al eje y.
                      callback(value, index, values) {
                          return '$' + value;
                      }
                    }
                 }, {
                     id: 'numeroventas'
                 }]
              },
              responsive: true,
              display: true,
              legend: {
                display: true,
                position: 'top'
              },
              animation: {
                duration: 2500,
              },
            },
            data: {
                labels: sucursal,
                datasets: [
                  {
                    label: 'Total ventas',
                    data: totalventa,
                    borderColor: '#d01d17',
                    fill: true,
                    backgroundColor: 'rgba(208, 29, 23, 0.4)',
                    yAxisID: 'totalventa'
                  },
                  {
                    label: 'Número ventas',
                    data: numeroventas,
                    borderColor: '#8c1819',
                    backgroundColor: 'rgba(140, 24, 25, 0.4)',
                    fill: true,
                    yAxisID: 'numeroventas'
                  }
                ]
            }
          });
          return 0;
      }
        this.serviceVentas.getGraficaVentaSucursal(this.ventasParamModel).subscribe( (resp: any) => {
        const respuesta = graficaVentasSucursal2(this.canvas, this.ctx, resp);
      }, (err) => {

      });
}

getGridVentasProducto() {
    this.displayedColumns = ['idproducto', 'sucursal', 'producto', 'venta_producto'];

    this.serviceVentas.getGridVentas(this.ventasParamModel).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.tabla = resp;
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      // Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de ventas en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de ventas en el servidor'
            });
            break;

      }
    });
  }

  openDialogImprimir(rows): void {
    // if ( rows === undefined ) {
      const dialogRef = this.dialog.open( VentasprodImprimirDialogComponent, {
        data: {titulo: 'Vista previa',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.90),
      });
      dialogRef.afterClosed().subscribe(result => {
       // this.getGridSucursal();
      });
    // }
  }


applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

verPDF() {
    this.abrirPDF();
  }

abrirPDF(): void {

    const DATA = this.tabladiv.nativeElement;
    const doc = new jsPDF('p', 'pt', 'a4');
    doc.fromHTML(DATA.innerHTML, 15, 15);
    doc.output('dataurlnewwindow');

  }

descargarPDF() {
     // get size of report page
    const reportPageHeight = $('#divgraficasventas').innerHeight();
    const reportPageWidth = $('#divgraficasventas').innerWidth();

    // create a new canvas object that we will populate with all other canvas objects
    const pdfCanvas = $('<canvas />').attr({
      id: 'canvaspdf',
      width: reportPageWidth,
      height: reportPageHeight
    });

    // keep track canvas position
 
    // const pdfctx = $(pdfCanvas)[0].getContext('2d');
    // let pdfctxX = 0;
    // let pdfctxY = 0;
    // const buffer = 100;

    // for each chart.js chart
    $('canvas').each(function(index) {
      // get the chart height/width
      const canvasHeight = $(this).innerHeight();
      const canvasWidth = $(this).innerWidth();

      // draw the chart into the new canvas
      // pdfctx.drawImage($(this)[0], pdfctxX, pdfctxY, canvasWidth, canvasHeight);
      // pdfctxX += canvasWidth + buffer;

      // our report page is in a grid pattern so replicate that in the new canvas
      // if (index % 2 === 1) {
      //   pdfctxX = 0;
      //   pdfctxY += canvasHeight + buffer;
      // }
    });

    // create new pdf and add our new canvas as an image
    // const pdf = new jsPDF('l', 'pt', [reportPageWidth, reportPageHeight]);
    // pdf.addImage($(pdfCanvas)[0], 'PNG', 0, 0);

    // download the pdf
    // pdf.save('graficas.pdf');

}

reportePDF(): void {

    const DATA = this.graficaventassucursales.nativeElement;
    const doc = new jsPDF('p', 'pt', 'a4');

    const handleElement = {
      '#editor'(element, renderer) {
        return true;
      }
    };
    doc.fromHTML(DATA.innerHTML, 15, 15, {
      width: 200,
      elementHandlers: handleElement
    });

    doc.save('reporteventas.pdf');
  }

  openDialogFechaReporteVentas(): void {
    const dialogRef = this.dialog.open( ResumenVentasComponent , {
      data: {titulo: 'Reporte resumen de ventas por sucursal'
    },
    disableClose: true,
    height: String(screen.height * 0.90),
    width: String(screen.width * 0.90),
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  
  }

}
