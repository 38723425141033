export class ProductoModel {
    idproducto: number;
    idsucursal: number;
    idcategoria: number;
    idtienda: number;
    categoria: string;
    presentacion: string;
    nombre: string;
    orden: number;
    sucursal: string;
    precio_normal: number;
    precio_promocion: number;
    descripcion: string;
    imagenb64: string;
    url_imagen: string;
    imagen_minb64: string;
    url_imagen_miniatura: string;
    aplicaiva: boolean;
    ieps: boolean;
    estadisponible: boolean;
    usuarioupd: string;
    operacion: number;
    usuarioinserto: string;
    entregainmediata: boolean;
    idusuario: number;
    tieneextras: boolean;
    usaleyenda: boolean;
    enhome: boolean;
    tienecereza: boolean;
    tieneotrosextras: boolean;
    objetoimpuesto: string;
}

export class ProductosSucursalesModel {
    idproductos_sucursales: number;
    idtienda: number;
    idsucursal: number;
    idproducto: number;
    estaactivo: boolean;
    idusuario: number;
    operacion: number;
}

export class ProductosPresentacionModel {
    idtienda: number;
    idsucursal: number;
    idproductos_presentacion: number;
    idproducto: number;
    idpresentacion: number;
    idcategoria: number;
    precio_normal: string;
    precio_promocion: string;
    estadisponible: boolean;
    entregainmediata: boolean;
    solosucursal: boolean;
    formatradicional: boolean;
    formacorazon: boolean;
    formarectangular: boolean;
    idusuario: number;
    operacion: number;
}

export class ProductoParamModel {
    idsucursal: number;
    idproducto: number;
    idpresentacion: number;
    idcategoria: number;
    idrol: number;
}

export class ExtrasModel {
    idextra: number;
    idsucursal: number;
    extra: string;
    precio: number;
    requiereDist: boolean;
    operacion: number;
}

export class ComboCategoriaModel {
    idcategoria: number;
    categoria: string;
}

export class ComboPresentacionModel {
    presentacion: string;
    estadisponible: boolean;
    idpresentacion: number;
}

export class CatPresentacionesModel {
    idpresentacion: number;
    idtienda: number;
    presentacion: string;
    estadisponible: boolean;
    entregainmediata: boolean;
    personas: string;
    operacion: number;
    idusuario: number;
    idrol: number;
    idsucursal: number;
}

// export class ProductoIngredienteModel {
//     idtipopresentacion: number;
//     idpresentacion: number;
//     tipopresentacion: string;
//     presentacion: string;
//     estadisponible: boolean;
//     imagenb64: string;
//     // tslint:disable-next-line: variable-name
//     url_imagen: string;
//     operacion: number;
//     idusuario: number;
//     idrol: number;
//     personas: string;
// }

export class ProductosCategoriaModel {
    idproductos_categoria: number;
    idproducto: number;
    idcategoria: number;
    categoria: string;
    estadisponible: boolean;
    idusuario: number;
    operacion: number;
}

export class ProductoIngredienteModel {
    idingredientes: number;
    idproducto: number;
    idpanes_sucursales: number;
    idrellenos_sucursales: number;
    idcobertura_sucursales: number;
    pan: string;
    relleno: string;
    cobertura: string;
   // imagenb64: string;
    // tslint:disable-next-line: variable-name
  //  url_imagen: string;
    idusuario: number;
    operacion: number;
}
