import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { PedidoService } from '../../services/pedido.service';
import { PedidoModel, RepartidorPedidoModel } from '../../models/pedido/pedido.model';
import { registerLocaleData } from '@angular/common';

import { PedidosComponent } from '../pedidos/pedidos.component';


import { PushService } from '../../services/push.service';

@Component({
  selector: 'app-pedidos-asig-repartidor',
  templateUrl: './pedidos-asig-repartidor.component.html',
  styleUrls: ['./pedidos-asig-repartidor.component.css']
})
export class PedidosAsigRepartidorComponent implements OnInit {

   repartidosModel: RepartidorPedidoModel;
   pedidoModel: PedidoModel;

   comborepartidores: any[];

   idseleccionado: number;

  constructor(public dialogRef: MatDialogRef<PedidosAsigRepartidorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private pushService: PushService,
              private pedidoService: PedidoService) {
                this.idseleccionado = this.data.idrepartidor;
              }

  ngOnInit(): void {

      this.pedidoModel = new PedidoModel();
      this.pedidoModel = this.data.row;
      this.repartidosModel = new RepartidorPedidoModel();
      this.getComboRepartidores();

  }

  PutAsignarRepartidor() {

  
    if (this.pedidoModel.idrepartidor === 0) {

        Swal.fire({
          icon: 'error',
          text: 'Debe seleccionar un repartidor, para cambiar a Pendiente de entrega y aparezca en la app delivery.',
          confirmButtonText: 'Aceptar'
         });

    } else {

      this.UpdateRepartidorAsignado();
      //  Swal.fire(
      //   'Good job!',
      //   'You clicked the button!',
      //   'success'
      //  );
       Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Asignación realizada exitosamente',
          showConfirmButton: true,
          timer: 8000,
          confirmButtonText: 'Aceptar'
        });
      // this.onNoClick();

    }

  }

  UpdateRepartidorAsignado() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Actualizando el estatus del pedido'
    });
    Swal.showLoading();
    
    this.pedidoService.updRepartidorAsignado(this.pedidoModel).subscribe( (resp: any[]) => {
      Swal.close();
      // if (this.extrasmodel.operacion === 1) {
      //   this.extrasmodel.idextra = resp[0].['idrespuesta'] !== -1 || resp[0].['idrespuesta'] !== -2 ? resp[0].['idrespuesta'] : 0 ;
      //   this.extrasmodel.operacion = this.extrasmodel.operacion === 1 ? 2 : 2;
      // }
    
      // if (resp) {
        /* 15-12-22 nuevo enviar mensaje push al disp del repartidor */
        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Enviando mensaje al repartidor'
        });
        Swal.showLoading();
        setTimeout(() => {
           console.log('Mensaje push->' + this.pedidoModel.idpedido);
           console.log('Mensaje push->' + this.pedidoModel.forma_pago);
           console.log('Mensaje push->' + this.pedidoModel.total);
           this.enviarMsjPush(this.pedidoModel.idpedido, this.pedidoModel.forma_pago, this.pedidoModel.total);
        }, 3000);
        Swal.close();
        

      // }
      }, (err) => {
       Swal.close(); 
       switch (err.status) {
        case 0:
            Swal.fire({
              icon: 'warning',
              text: 'No hay conexión con el servidor',
              confirmButtonText: 'Aceptar'
            });
            break;
         case 409:
           Swal.fire({
            icon: 'error',
            text: 'Error ' + err.status + ' en el guardado del catálogo de extras',
            confirmButtonText: 'Aceptar'
           });
           break;
          case 500:
              Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del catálogo de extras',
                confirmButtonText: 'Aceptar'
              });
              break;
        }
      });
  }

  getComboRepartidores() {
    /// cual es el rol de los repartidores
    this.repartidosModel.idsucursal = Number(this.pedidoModel.idsucursal); 
   // this.repartidosModel.idsucursal = Number(sessionStorage.ids);
    this.repartidosModel.idrol = Number(sessionStorage.idr);
    console.log(this.repartidosModel);
    this.getRepartidoresCombo();
  }


  getRepartidoresCombo() {
    this.pedidoService.getRepartidoresSucursal(this.repartidosModel.idsucursal).subscribe( (resp: any[]) => {
        this.comborepartidores = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor',
            confirmButtonText: 'Aceptar'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error  en dependencias ' + err.status + ' en el servidor',
          confirmButtonText: 'Aceptar'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error  en dependencias ' + err.status + ' en el servidor',
              confirmButtonText: 'Aceptar'
            });
            break;
      }
    });
  }

  getRepartidores() {
    this.pedidoService.getListaRepartidores(this.repartidosModel).subscribe( (resp: any[]) => {
        this.comborepartidores = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor',
            confirmButtonText: 'Aceptar'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error  en dependencias ' + err.status + ' en el servidor',
          confirmButtonText: 'Aceptar'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error  en dependencias ' + err.status + ' en el servidor',
              confirmButtonText: 'Aceptar'
            });
            break;
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  /* Nuevo 09-12-22 00:20 enviar mensaje push  */

  enviarMsjPush(idpedido: number, formapago: string, total: string) {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Enviando mensaje al repartidor...'
    });
    Swal.showLoading();
    this.pedidoService.getPedidoRepartidor(idpedido).subscribe( (res: any[]) => {
      console.log(res);
      const valortoken = res[0].tokendisp;
      const valorno_orden = res[0].no_orden;
      const valorcuerpo = 'No: ' + res[0].no_orden + ' Pago: ' + '$' + total + ' ' + formapago;
      const valorTitulo = 'Nuevo pedido';
      if (res) {
         this.pushService.enviarPush(valortoken, valorno_orden, valorTitulo, valorcuerpo).subscribe(resp => {
            console.log(resp);
         });      
      }
      Swal.close();
    }, (err) => {
      Swal.close();
       switch (err.status) {
        case 0:
            Swal.fire({
              icon: 'warning',
              text: 'No hay conexión con el servidor'
            });
            break;
         case 409:
           Swal.fire({
            icon: 'error',
            text: 'Error ' + err.status + ' en la asignación de repartidor'
           });
           break;
         case 500:
           Swal.fire({
            icon: 'error',
            text: 'Error ' + err.status + ' en la asignación de repartidor'
           });
           break;
        }
      });
    
  }

}
