import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSettings } from '../models/appsettings';
import { EnvioCostoModel } from '../models/envio/envio.model';


@Injectable({
  providedIn: 'root'
})
export class EnvioService {

  private header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  .append('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  private headerJSON = new HttpHeaders().set('Content-Type', 'application/json');

  private headerQP = new HttpHeaders().set('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  constructor(private http: HttpClient) { }

  getQueryParam( query: string) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.get(url, { headers: this.headerQP });
  }

  getFormParam( query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.header });
  }

  getFormParamJSON(query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.headerJSON });
  }

  getGridCatEnvioCosto(envioCostoModel: EnvioCostoModel ) {
    return this.getQueryParam('costoenvios/leercostosenviospanel?idsucursal=' + envioCostoModel.idsucursal + '&idrol=' + envioCostoModel.idrol);
  }

  abcCatEnvioCosto(data: EnvioCostoModel) {
    const params = new HttpParams({
      fromObject: {
        idcosto_envio: String(data.idcosto_envio === undefined ? 0 : data.idcosto_envio),
        idtienda: String(data.idtienda === undefined ? 0 : data.idtienda),
      //  idsucursal: String(data.idsucursal === undefined ? 0 : data.idsucursal),
      //  idrol: String(data.idrol === undefined ? 0 : data.idrol),
        rangoinicial: data.rangoinicial === undefined ? '' : data.rangoinicial,
        rangofinal: data.rangofinal === undefined ? '' : data.rangofinal,
        costo: data.costo === undefined ? '' : data.costo,
        estaactivo: String( data.estaactivo === undefined ? 0 : data.estaactivo), // (true estaactivo, false no estaactivo)
        idusuario: String(data.idusuario === undefined ? 0 : data.idusuario),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar)
      }
    });
    return this.getFormParam('costoenvios/abccostoenviopanel', params);
  }


}
