import { Component, OnInit, Inject, ElementRef, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CombosService } from '../../services/combos.service';

import { DianolaboralService } from '../../services/dianolaboral.service';
import { DianolaboralModel, ParamDianolaboralModel } from '../../models/dianolaboral/dianolaboral.model';
import {  Form, NgForm, FormControl } from '@angular/forms';


@Component({
  selector: 'app-dianolaboral-dialog',
  templateUrl: './dianolaboral-dialog.component.html',
  styleUrls: ['./dianolaboral-dialog.component.css']
})
export class DianolaboralDialogComponent implements OnInit {

  formulario: FormGroup;
  submitted = false;
  combotiendas: any[];
  idrol: number;
  dianolaboralmodel: DianolaboralModel;
  paramdianolaboralmodel: ParamDianolaboralModel;
  diafestivo: string;

  constructor(public dialogRef: MatDialogRef<DianolaboralDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private servicedianolab : DianolaboralService,
              private combosservicio: CombosService,
              private formBuilder: FormBuilder
            ) { }

  ngOnInit(): void {
    this.idrol = Number(sessionStorage.idr);
    this.dianolaboralmodel = new DianolaboralModel();
    this.paramdianolaboralmodel = new ParamDianolaboralModel();

    this.dianolaboralmodel.idsucursal = Number(sessionStorage.ids);
    this.dianolaboralmodel.idusuario = Number(sessionStorage.idu);
    
    console.log(this.data.row);
    console.log(this.diafestivo);

    if (this.data.row.iddiafestivo > 0) {
      this.dianolaboralmodel = this.data.row;
      this.diafestivo = this.data.row.diafestivo;
    }
    
    /* this.formulario = this.formBuilder.group({

      diafestivo: ['', Validators.required],
      descripcion: ['', [Validators.required, Validators.maxLength(80), Validators.minLength(3)]],

    },
    {
     }); */

  }

  /* get f() { return this.formulario.controls; }

  get descripcionNoValido() {
    return this.formulario.get('descripcion').invalid;
  } */

  onSubmit() {
    this.submitted = true;
      // detener si el formulario es invalido
    /* if (this.formulario.invalid) {
          return;
    } */
    this.abcDiaNoLaboral();
  }

  abcDiaNoLaboral() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    // this.sucursalmodel.logitud = this.sucursalmodel.longitud;
    // si trae un registro el valor de iddiafestivo es mayor a 0 entonces operacion 2 modificar
    // en caso contrario es operacion 1 Agregar
    this.dianolaboralmodel.operacion = this.dianolaboralmodel.iddiafestivo > 0 ? 2 : 1;
    if (this.idrol > 1) {
      this.dianolaboralmodel.idtienda = 1; // esta hardcodeado
    }
    console.log(this.dianolaboralmodel);  
    this.servicedianolab.abcDianolaboral(this.dianolaboralmodel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.dianolaboralmodel.operacion === 1) {
          this.dianolaboralmodel.iddiafestivo = resp[0].idrespuesta !== -1 || resp[0].idrespuesta !== -2 ? resp[0].idrespuesta : 0 ;
          this.dianolaboralmodel.operacion = this.dianolaboralmodel.operacion === 1 ? 2 : 2;
        }
        if (resp) {
          if (resp[0].idrespuesta > 0) {
            Swal.fire({
              icon: 'success',
              text: resp[0].respuesta,
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           } else {
            Swal.fire({
              icon: 'error',
              text: resp[0].respuesta,
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           }
        }
        }, (err) => {
         Swal.close();
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor o revise su conexión a internet.',
                confirmButtonColor: '#391410',
                confirmButtonText: 'ACEPTAR'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado de fechas no laborales.',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado de fechas no laborales',
                  confirmButtonColor: '#391410',
                  confirmButtonText: 'ACEPTAR'
                });
                break;
          }
        });

  }

  getComboTienda() {
    this.combosservicio.getComboTiendas().subscribe( (resp: any[]) => {
        this.combotiendas = resp;
        console.log(this.combotiendas);
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor o revise su conexión a internet.',
            confirmButtonColor: '#391410',
            confirmButtonText: 'ACEPTAR'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor',
          confirmButtonColor: '#391410',
          confirmButtonText: 'ACEPTAR'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
            break;
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }




}
