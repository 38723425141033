import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

import { TiendaModel } from '../../models/tienda/tienda.model';
import { TiendaService } from '../../services/tienda.service';

@Component({
  selector: 'app-tienda-dialog',
  templateUrl: './tienda-dialog.component.html',
  styleUrls: ['./tienda-dialog.component.css']
})
export class TiendaDialogComponent implements OnInit {

   formulario: FormGroup;
   submitted = false;

   tiendaModel: TiendaModel;

  constructor(public dialogRef: MatDialogRef<TiendaDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private serviceTienda: TiendaService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {


    this.tiendaModel = new TiendaModel();
    this.tiendaModel.idtienda = 0;
    if (this.data.row.idtienda > 0) {
      this.tiendaModel = this.data.row;
    }
    // /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    // [a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$
    // validar los campos del formulario
    this.formulario = this.formBuilder.group({
      nombre_comercial: ['', Validators.required],
     // correo: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      // tslint:disable-next-line: quotemark
      correo: ['', [Validators.required, Validators.pattern("^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")]],
      movil: ['', Validators.required],
      telefono: ['', Validators.required]
    });

  }

  get f() { return this.formulario.controls; }

  get emailNoValido() {
    return this.formulario.get('correo').invalid;
  }

  onSubmit() {

      this.submitted = true;

      // detener si el formulario es invalido
      if (this.formulario.invalid) {
            return;
      }

      this.abcCatTienda();

  }

  abcCatTienda() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    this.tiendaModel.operacion = this.tiendaModel.idtienda > 0 ? 2 : 1;
    this.serviceTienda.abcCatTienda(this.tiendaModel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.tiendaModel.operacion === 1) {
          this.tiendaModel.idtienda = resp[0].idrespuesta !== -1 || resp[0].idrespuesta !== -2 ? resp[0].idrespuesta : 0 ;
          this.tiendaModel.operacion = this.tiendaModel.operacion === 1 ? 2 : 2;
        }
        if (resp) {
          // console.log(resp[0].idrespuesta);
          // console.log(resp[0].respuesta);
          // console.log(resp);

          if (resp[0].idrespuesta > 0) {
            Swal.fire({
              icon: 'success',
              text: resp[0].respuesta,
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           } else {
            Swal.fire({
              icon: 'error',
              text: resp[0].respuesta,
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           }
        }
        }, (err) => {
         Swal.close();
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor',
                confirmButtonColor: '#391410',
                confirmButtonText: 'ACEPTAR'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado del catálogo de tiendas',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado del catálogo de tiendas',
                  confirmButtonColor: '#391410',
                  confirmButtonText: 'ACEPTAR'
                });
                break;
          }
        });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
