import { Component, OnInit, Inject, ElementRef, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CombosService } from '../../services/combos.service';

import { SucursalService } from '../../services/sucursal.service';
import { LimiteProdCarritoDomModel, SucursalModel } from '../../models/sucursal/sucursal.model';
import {  Form, NgForm, FormControl } from '@angular/forms';


@Component({
  selector: 'app-sucursal-limite-carrito-pedido-dom-abc-dialog',
  templateUrl: './sucursal-limite-carrito-pedido-dom-abc-dialog.component.html',
  styleUrls: ['./sucursal-limite-carrito-pedido-dom-abc-dialog.component.css']
})
export class SucursalLimiteCarritoPedidoDomAbcDialogComponent implements OnInit {

  formulario: FormGroup;
  submitted = false;
  combotiendas: any[];
  idrol: number;
  limiteprodcarrtitodommodel: LimiteProdCarritoDomModel;
  // paramdianolaboralmodel: ParamDianolaboralModel;
  cantidad: string;

  constructor(public dialogRef: MatDialogRef<SucursalLimiteCarritoPedidoDomAbcDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private service : SucursalService,
              private combosservicio: CombosService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.idrol = Number(sessionStorage.idr);
    this.limiteprodcarrtitodommodel = new LimiteProdCarritoDomModel();
    // this.paramdianolaboralmodel = new ParamDianolaboralModel();

    this.limiteprodcarrtitodommodel.idsucursal = Number(this.data.idsucursal);
    this.limiteprodcarrtitodommodel.usuario_creada = Number(sessionStorage.idu);
    
    console.log(this.data.row);
    console.log(this.cantidad);

    if (this.data.row.id > 0) {
      this.limiteprodcarrtitodommodel = this.data.row;
      this.cantidad = this.data.row.cantidad;
    }

  }

  onSubmit() {
    this.submitted = true;
      // detener si el formulario es invalido
    /* if (this.formulario.invalid) {
          return;
    } */
    this.abcSucursalLimiteCarritoPedidoDom();
  }

  abcSucursalLimiteCarritoPedidoDom() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    // si trae un registro el valor de id es mayor a 0 entonces operacion 2 modificar
    // en caso contrario es operacion 1 Agregar
    this.limiteprodcarrtitodommodel.operacion = this.limiteprodcarrtitodommodel.id > 0 ? 2 : 1;
    // if (this.idrol > 1) {
    //   this.fechaentregamodel.idtienda = 1; // esta hardcodeado
    // }
    console.log(this.limiteprodcarrtitodommodel);  
    this.service.abcLimiteProdCarritoDom(this.limiteprodcarrtitodommodel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.limiteprodcarrtitodommodel.operacion === 1) {
          this.limiteprodcarrtitodommodel.id = resp[0].idrespuesta !== -1 || resp[0].idrespuesta !== -2 ? resp[0].idrespuesta : 0 ;
          this.limiteprodcarrtitodommodel.operacion = this.limiteprodcarrtitodommodel.operacion === 1 ? 2 : 2;
        }
        if (resp) {
          if (resp[0].idrespuesta > 0) {
            Swal.fire({
              icon: 'success',
              text: resp[0].respuesta,
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
            this.onNoClick();
           } else {
            Swal.fire({
              icon: 'error',
              text: resp[0].respuesta,
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           }
        }
        }, (err) => {
         Swal.close();
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor o revise su conexión a internet.',
                confirmButtonColor: '#391410',
                confirmButtonText: 'ACEPTAR'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado de registro.',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado de registro.',
                  confirmButtonColor: '#391410',
                  confirmButtonText: 'ACEPTAR'
                });
                break;
          }
        });

  }

  // getComboTienda() {
  //   this.combosservicio.getComboTiendas().subscribe( (resp: any[]) => {
  //       this.combotiendas = resp;
  //       console.log(this.combotiendas);
  //   }, (err) => {
  //    switch (err.status) {
  //     case 0:
  //         Swal.fire({
  //           icon: 'warning',
  //           text: 'No hay conexión con el servidor o revise su conexión a internet.',
  //           confirmButtonColor: '#391410',
  //           confirmButtonText: 'ACEPTAR'
  //         });
  //         break;
  //      case 409:
  //        Swal.fire({
  //         icon: 'error',
  //         text: 'Error en sucursal ' + err.status + ' en el servidor',
  //         confirmButtonColor: '#391410',
  //         confirmButtonText: 'ACEPTAR'
  //        });
  //        break;
  //       case 500:
  //           Swal.fire({
  //             icon: 'error',
  //             text: 'Error en sucursal ' + err.status + ' en el servidor',
  //             confirmButtonColor: '#391410',
  //             confirmButtonText: 'ACEPTAR'
  //           });
  //           break;
  //     }
  //   });
  // }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
