import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import Swal from 'sweetalert2';

import { EnvioCostoModel } from '../../models/envio/envio.model';
import { EnvioService } from '../../services/envio.service';
import { CombosService } from '../../services/combos.service';

@Component({
  selector: 'app-enviocosto-dialog',
  templateUrl: './enviocosto-dialog.component.html',
  styleUrls: ['./enviocosto-dialog.component.css']
})
export class EnviocostoDialogComponent implements OnInit {

  formulario: FormGroup;
  submitted = false;

  envioCostomodel: EnvioCostoModel;
  combotiendas: any[];
  idtienda: number;
  idrol: number;

  constructor(public dialogRef: MatDialogRef<EnviocostoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private serviceenvioCosto: EnvioService,
              private formBuilder: FormBuilder,
              private comboservice: CombosService) { }

  ngOnInit(): void {
      this.idrol = Number(sessionStorage.idr);

      this.formulario = this.formBuilder.group({
        idtienda: [''],
        rangoinicial: ['', Validators.required],
        rangofinal: ['', Validators.required],
        costo: ['', [Validators.required, Validators.min(0)] ],
        estaactivo: ['']
      }, {
      });

      this.envioCostomodel = new EnvioCostoModel();
     // this.envioCostomodel.idcosto_envio = 0;
    //  this.envioCostomodel.idtienda = 0;
      this.getComboTienda();

      if (this.data.row.idcosto_envio > 0) {
          this.envioCostomodel = this.data.row;
      }

      this.envioCostomodel.idsucursal = Number(sessionStorage.ids);
      this.envioCostomodel.idusuario = Number(sessionStorage.idu);
      this.envioCostomodel.idrol = Number(sessionStorage.idr);

        // if (this.categoriamodel.estaactivo) {
        //     this.categoriamodel.estaactivo = true;
        // } else {
        //     this.categoriamodel.estaactivo = false;
        // }

      console.log(this.envioCostomodel);
  }

  get f() { return this.formulario.controls; }

  getComboTienda() {
    this.comboservice.getComboTiendas().subscribe( (resp: any[]) => {
        this.combotiendas = resp;
        console.log(this.combotiendas);
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor',
            confirmButtonColor: '#391410',
            confirmButtonText: 'ACEPTAR'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor',
          confirmButtonColor: '#391410',
          confirmButtonText: 'ACEPTAR'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
            break;
      }
    });
  }

  abcCatEnvioCosto() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    this.envioCostomodel.operacion = this.envioCostomodel.idcosto_envio > 0 ? 2 : 1;

    if (this.idrol > 1) {
      this.envioCostomodel.idtienda = 1;
    }

    console.log(this.envioCostomodel);
    this.serviceenvioCosto.abcCatEnvioCosto(this.envioCostomodel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.envioCostomodel.operacion === 1) {
          this.envioCostomodel.idcosto_envio = resp['idrespuesta'] !== -1 || resp['idrespuesta'] !== -2 ? resp['idrespuesta'] : 0 ;
          this.envioCostomodel.operacion = this.envioCostomodel.operacion === 1 ? 2 : 2;
        }
        if (resp) {
          if (resp['idrespuesta'] > 0) {
            Swal.fire({
              icon: 'success',
              text: resp['respuesta'],
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           } else {
            Swal.fire({
              icon: 'error',
              text: resp['respuesta'],
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           }
        }
        }, (err) => {
         Swal.close();
         switch (err.status) {
          case 406:
              Swal.fire({
                icon: 'warning',
                text: 'El rango ya existe.',
                confirmButtonColor: '#391410',
                confirmButtonText: 'ACEPTAR'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado del catálogo de envios',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado del catálogo de envios',
                  confirmButtonColor: '#391410',
                  confirmButtonText: 'ACEPTAR'
                });
                break;
          }
        });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {

    this.submitted = true;

    // se detiene si el formulario es invalido
    if (this.formulario.invalid) {
        return;
    }
    this.abcCatEnvioCosto();

    // valores visibles del formmulario
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
    // valores del modelo que se pasa al abc
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.extrasmodel, null, 4));

  }

}
