import { Component, OnInit, ViewChild  } from '@angular/core';
import { CatPresentacionesModel } from '../../models/producto/producto.model';


import { MatDialog} from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';

import { ProductoService } from '../../services/producto.service';
import { PresentacionesDialogComponent } from '../presentaciones-dialog/presentaciones-dialog.component';
import { PresentacionesImprimirDialogComponent } from '../presentaciones-imprimir-dialog/presentaciones-imprimir-dialog.component';

@Component({
  selector: 'app-presentaciones',
  templateUrl: './presentaciones.component.html',
  styleUrls: ['./presentaciones.component.css']
})
export class PresentacionesComponent implements OnInit {
  presentacionesmodel: CatPresentacionesModel;
  displayedColumns: string[] = [];
  datasource: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  tabla: any[];
  constructor(private productoservice: ProductoService,
              private dialog: MatDialog,
              public paginatorInt: MatPaginatorIntl) {
              }

  ngOnInit(): void {
          this.presentacionesmodel = new CatPresentacionesModel();
          this.presentacionesmodel.idpresentacion = 0;
          this.presentacionesmodel.idusuario = Number(sessionStorage.idu);
          this.presentacionesmodel.idrol = Number(sessionStorage.idr);
          this.getGridPresentaciones();

          this.paginatorInt.firstPageLabel = 'Primera página';
          this.paginatorInt.lastPageLabel = 'Última página';
          this.paginatorInt.itemsPerPageLabel = 'Registro por página';
          this.paginatorInt.nextPageLabel     = 'Siguiente página';
          this.paginatorInt.previousPageLabel = 'Anterior página';

  }

  getGridPresentaciones() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumns = ['idpresentacion', 'sucursal', 'estadisponible', 'entregainmediata', 'presentacion', 'personas', 'actions'];
    this.productoservice.getGridPresentaciones(this.presentacionesmodel.idusuario, this.presentacionesmodel.idrol).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.tabla = resp;
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid del catálogo de presentaciones en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid del catálogo de presentaciones en el servidor'
            });
            break;

      }
    });
  }

  acceso(row) {
    this.openDialogPresentaciones(row);
  }

  openDialogPresentaciones(rows: any): void {
    if ( rows === undefined ) {
      const dialogRef = this.dialog.open( PresentacionesDialogComponent , {

        data: {titulo: 'Agregar la presentación',
          row: '',
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridPresentaciones();
      });
    } else {
      const dialogRef = this.dialog.open( PresentacionesDialogComponent , {
        data: {titulo: 'Modificar la presentación',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.80),
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getGridPresentaciones();
      });
    }
  }

  openDialogImprimir(rows): void {
    // if ( rows === undefined ) {
      const dialogRef = this.dialog.open( PresentacionesImprimirDialogComponent , {
        data: {titulo: 'Vista previa',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.90),
      });
      dialogRef.afterClosed().subscribe(result => {
       // this.getGridSucursal();
      });
    // }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tablaFilter(filterValue.trim().toLowerCase());
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  tablaFilter(cadena: string) {

    // llenar la tabla para el reporte
    // const cadena = filterValue.trim().toLowerCase();
    const tablatemp = [];
    for (let i=0; i < this.tabla.length; i++) {
      if (this.tabla[i].presentacion.trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].personas.trim().toLowerCase().indexOf(cadena) > -1
         ) {
        tablatemp.push(this.tabla[i]);
      }
    }
    this.tabla = [];
    this.tabla = tablatemp;
  }

  reloadgrid() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.getGridPresentaciones();
    Swal.close();
  }

  

}
