import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'estatuspedido'
})
export class EstatuspedidoPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    if (args != null) {

    switch (value) {
      case 0: return '';
      case 1: return '';
      case 2: return 'Pendiente';
      case 3: return '';
      case 4: return 'Entregado';
      case 5: return '';
      case 6: return '';
      case 7: return '';
    }
  }
    return null;
  }

}
