import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TiendaModel } from '../models/tienda/tienda.model';
import { AppSettings } from '../models/appsettings';


@Injectable({
  providedIn: 'root'
})
export class TiendaService {

  private header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  .append('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  private headerJSON = new HttpHeaders().set('Content-Type', 'application/json');

  private headerQP = new HttpHeaders().set('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  constructor(private http: HttpClient) { }

  getQueryParam( query: string) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.get(url, { headers: this.headerQP });
  }

  getFormParam( query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.header });
  }

  getFormParamJSON(query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.headerJSON });
  }

  getGridCatTienda() {
      return this.getQueryParam('tiendas/leertiendaspanel');
  }

  abcCatTienda(data: TiendaModel) {

    const params = new HttpParams({
      fromObject: {
        idtienda: String(data.idtienda === undefined ? 0 : data.idtienda),
        nombre_comercial: data.nombre_comercial === undefined ? '' : data.nombre_comercial,
        telefono: data.telefono === undefined ? '' : data.telefono,
        movil: data.movil === undefined ? '' : data.movil,
        email: data.email === undefined ? '' : data.email,
        idusuario: String(data.idusuario === undefined ? 0 : data.idusuario),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar, 3 dar de baja)
      }
    });
    return this.getFormParam('tiendas/abctiendas', params);

  }

}
