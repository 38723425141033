import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import * as jsPDF from 'jspdf';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-sucursal-imprimir-dialog',
  templateUrl: './sucursal-imprimir-dialog.component.html',
  styleUrls: ['./sucursal-imprimir-dialog.component.css']
})
export class SucursalImprimirDialogComponent implements OnInit {

  displayedColumns: string[] = [];
  datasource: any;
  dataSource: MatTableDataSource<any>;

  @ViewChild('tabla') tabla: ElementRef;
  @ViewChild('htmlData') htmlData: ElementRef;


  constructor(public dialogRef: MatDialogRef<SucursalImprimirDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any
              ) { }

  ngOnInit(): void {
  //  console.log(this.data);
  //  this.dataSource =  this.data;
  this.getGridSucursal();
  }

  getGridSucursal() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumns = ['idsucursal', 'estaactivo', 'sucursal', 'abreviatura', 'direccion', 'telefono', 'entrada', 'salida'];
    this.dataSource = new MatTableDataSource<any>(this.data.row);
  //  console.log(this.data.row);
    Swal.close();

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  imprimir(cmpName) {
     const printContents = document.getElementById(cmpName).innerHTML;
     const originalContents = document.body.innerHTML;

     document.body.innerHTML = printContents;

     window.print();

     document.body.innerHTML = originalContents;
  }

  public openPDF(): void {
    const DATA = this.htmlData.nativeElement;
    const doc = new jsPDF('landscape', 'pt', 'letter', true);
    doc.fromHTML(DATA.innerHTML, 15, 15);
    doc.output('dataurlnewwindow');
  }


  public downloadPDF(): void {
    const DATA = this.tabla.nativeElement;
    const doc = new jsPDF('p', 'pt', 'a4');

    const handleElement = {
      '#editor'(element, renderer) {
        return true;
      }
    };
    doc.fromHTML(DATA.innerHTML, 15, 15, {
      width: 200,
      elementHandlers: handleElement
    });

    doc.save('sucursales.pdf');
  }

}
