export class SucursalModel {
    idsucursal: number;
    idtienda: number;
    sucursal: string;
    abreviatura: string;
    tienda: string;
    latitud: string;
    longitud: string;
    radio_repartidor: string;
    entrada: string;
    salida: string;
    horario: string;
    telefono: string;
    limiteproduccion: string;
    direccion: string;
    idusuario: number;
    operacion: number;
    estaactivo: boolean;
    aplicacostoenvio: boolean;

}

export class ParamSucursalModel {
    idsucursal: number;
    idtienda: number;
    idrol: number;
}

export class SucursalFavoritaModel {
    idsucursal: number;
    idtienda: number;
    sucursal: string;
    tienda: string;
    latitud: string;
    longitud: string;
    entrada: string;
    salida: string;
    limiteproduccion: string;
    direccion: string;
    telefono: string;
}

export class FechasEntregaDomDesactivadosModel {
    id: number;
    idsucursal: number;
    fecha_entrega_dom: string;
    nodisponible_entregadom: boolean;
    usuario_creada: number;
    operacion: number;
    mensaje: string;
}

export class LimiteProdCarritoDomModel {
    id: number;
    idsucursal: number;
    cantidad: number;
    activo: boolean;
    usuario_creada: number;
    operacion: number;
}

export class LimiteProdPedidoModel {
    id: number;
    idsucursal: number;
    idtipoentrega: number;
    maxproductos: number;
    maxpedidos: number;
    activo: boolean;
    usuario_creada: number;
    operacion: number;
    tipoentrega: string;
}

