import { Component, OnInit, Inject, ViewChild } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import Swal from 'sweetalert2';

import { CombosService } from '../../services/combos.service';
import {  NgForm } from '@angular/forms';


import { ProductoModel, ProductoParamModel } from './../../models/producto/producto.model';
import { PedidoModel } from '../../models/pedido/pedido.model';
import { GridModel } from '../../models/pedido/pedido.model';
import { PedidoService } from '../../services/pedido.service';
import { ProductoService } from '../../services/producto.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { PedidoDetalleComponent } from '../pedido-detalle/pedido-detalle.component';
import { EstatusPedidoDetalleComponent } from '../estatus-pedido-detalle/estatus-pedido-detalle.component';


@Component({
  selector: 'app-pedido-pendiente-pago-dialog',
  templateUrl: './pedido-pendiente-pago-dialog.component.html',
  styleUrls: ['./pedido-pendiente-pago-dialog.component.css']
})
export class PedidoPendientePagoDialogComponent implements OnInit {
  pedidosmodel: PedidoModel;
  gridmodel: GridModel;
  tabla: any[];
  idrol: number;
  displayedColumns: string[] = [];
  datasource: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource: any;
  arreglo: any[] = [];
  imagenPerfilvacia: string;
  fecha: string;
  respEstatus: string;

  constructor(private dialog: MatDialog,
    public dialogRef: MatDialogRef<PedidoPendientePagoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productoservice: ProductoService,
    private pedidoservice: PedidoService,
    public paginatorInt: MatPaginatorIntl) 
    { }

  ngOnInit(): void {
    this.pedidosmodel = new PedidoModel();
    this.gridmodel = new GridModel();
    this.pedidosmodel.idusuario = Number(sessionStorage.idu);
    this.pedidosmodel.idrol = Number(sessionStorage.idr);
    this.idrol = Number(sessionStorage.getItem('idr'));
    this.getFechaActual();
    this.getGridPedidosPendientePago();
  }

  getGridPedidosPendientePago() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumns = ['idpedido', 'no_orden', 'fecha_pedido', 'fecha_entrega', 'total', 'nombrecompleto','email','telefono1','idopenpay_cargo','actions'];    
    this.gridmodel.f_inicial = this.gridmodel.f_inicial == undefined || this.gridmodel == undefined ? '' : this.gridmodel.f_inicial;
    this.gridmodel.f_final = this.gridmodel.f_final == undefined || this.gridmodel == undefined ? '' : this.gridmodel.f_final;
    this.pedidoservice.getPedidoPendientePagoGrid(this.pedidosmodel.idusuario, this.pedidosmodel.idrol, this.gridmodel).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.tabla = resp;
          console.log(resp);
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de extras en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de extras en el servidor'
            });
            break;

      }
    });
  }

  getColorEstatusRow(estatus: number) {
    let colorletra: string;
    switch (estatus) {
      case 2: {
        colorletra = '#d01d17';
        break;
      }
      case 3: {
        colorletra = '#8c1819';
        break;
      }
      case 4: {
        colorletra = '#391410';
        break;
      }

    }

    return colorletra;
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  limpiarGrid() {
    this.dataSource = [];
    this.dataSource.paginator = [];
    this.gridmodel.f_final = '';
    this.gridmodel.f_inicial = '';
  }

  cambiarEstatusPedido(row,idestatus_pedido){
    Swal.fire({
      title: '¿Esta seguro de cambiar el estatus del pedido?',
      text: "Al cambiar el estatus ya no podrá regresarlo",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.pedidoservice.cambiarEstatusPedido(row, idestatus_pedido).subscribe( (resp: any[]) => {
          Swal.close();    
          Swal.fire({
            icon: 'success',
            text: 'Operación realizada.'
          });
          /*  refresca el grid */
          this.getGridPedidosPendientePago();
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el cambio de estatus'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el cambio de estatus'
                  });
                  break;
            }
          });
      }
    })

  }

  getFechaActual(){  
      
    this.pedidoservice.getFechaActual().subscribe( (resp: any[]) => {
      // this.fecha = resp[0].fecha_actual;
      this.gridmodel.f_inicial = resp[0].fecha_actual;
      this.gridmodel.f_final = resp[0].fecha_actual;

      }, (err) => {
        switch (err.status) {
        case 0:
            Swal.fire({
              icon: 'warning',
              text: 'No hay conexión con el servidor'
            });
            break;
         case 409:
           Swal.fire({
            icon: 'error',
            text: 'Error ' + err.status + ' para la fecha actual en el servidor'
           });
           break;
          case 500:
              Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' para la fecha actual en el servidor'
              });
              break;
      
        }
      });
  }

  botonEstatusCargo(row: any){
      llamarfuncionesEstatusCargo(row);
  }

  openDialogProducto(rows): void {
    if ( rows === undefined ) {
      const dialogRef = this.dialog.open( PedidoDetalleComponent , {

        data: {titulo: 'Producto del pedido',
          row: '',
        },
        disableClose: true,
        height: String(screen.height * 0.80),
        width: String(screen.width * 0.20),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
        // this.getGridProductos();
      });
    } else {
      const dialogRef = this.dialog.open( PedidoDetalleComponent , {
        data: {titulo: 'Productos del pedido',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.80),
      width: String(screen.width * 0.20),
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  accesoDetalle(row) {
    this.openDialogProducto(row);
 }


  openDialogEstatusCargoOpenpay(rows): void {
      
    const dialogRef = this.dialog.open( EstatusPedidoDetalleComponent , {
      data: {titulo: 'Consulta estatus cargo Open pay',
      row: rows
    },
    disableClose: true,
    height: String(screen.height * 0.80),
    width: String(screen.width * 0.20),
    });
    dialogRef.afterClosed().subscribe(result => {
    });
    
    
      
  }

}

function getEstatusCargoPedido(row: any){
  var pservice: PedidoService;
  // Swal.fire({
  //   allowOutsideClick: false,
  //   icon: 'info',
  //   text: 'Consultando info...'
  // });
  // Swal.showLoading();
    pservice.getEstatusCargoPedido(row.idopenpay_cargo).subscribe( (resp: any) => {   
    // Swal.close();
    //setTimeout(() => {
      // Swal.close();
    //}, 4000);  
     this.respEstatus = resp; 
    
    // this.getGridPedidos();
    return resp;
    }, (err) => {
      // Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
        case 409:
          Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' en el consulta de estatus cargo'
          });
          break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el consulta de estatus cargo'
            });
            break;
      }
    });

    return this.respEstatus;

  }

  function getMuestraCargoPedido(row: any){
    Swal.fire({
      icon: 'success',
      text: 'Estatus:' + row,
    });
    return row;
  }

  async function llamarfuncionesEstatusCargo(row){

   var respEstatus: any;

    const result1 = await getEstatusCargoPedido(row);
    const result2 = await getMuestraCargoPedido(respEstatus);

    // setTimeout(() => {
    //   Swal.fire({
    //     icon: 'success',
    //     text: 'Estatus:' + this.respEstatus,
    //  });
    // }, 3000);

    // Swal.close();

  } 
