import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { AppSettings } from '../models/appsettings';
import { PedidoModel, RepartidorPedidoModel, PedidoEscanerCodigoModel, GridModel } from '../models/pedido/pedido.model';
import { FacturacionModel } from '../models/facturacion/facturacion.model';

@Injectable({
  providedIn: 'root'
})
export class PedidoService {

  private header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  .append('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  private headerQP = new HttpHeaders().set('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  private headerJSON = new HttpHeaders().set('Content-Type', 'application/json')
  .append('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  constructor(private http: HttpClient) { }

  getQueryParam( query: string) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.get(url, { headers: this.headerQP });
  }

  getFormParam( query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.header });
  }

  getJsonParam( query: string, params: any) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, JSON.stringify(params), { headers: this.headerJSON });
  }

  getFormPutParam( query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.put(url, params, { headers: this.header });
  }

  getUsoCFDI() {
    return this.getQueryParam('facturacion/getusocfdi');
  }

  getDatosFacturacionPanel(idusuario: number, esinvitado: boolean, idpedido: number) {
    return this.getQueryParam('facturacion/getdatosfacturacionpanel?idusuario=' + idusuario + '&esinvitado=' + esinvitado + '&idpedido=' + idpedido);
  }

  abcDatosFacturacionPanel(data: FacturacionModel, idpedido: number) {
    const params =  {
      idpedido: String(idpedido == undefined ? 0 :idpedido),
        iddatosfacturacion: String(data.iddatosfacturacion === undefined ? 0 : data.iddatosfacturacion),
        idusocfdi: data.idusocfdi === undefined ? '' : data.idusocfdi,
        id: String(data.id === undefined ? 0 : data.id),
        esinvitado: String(data.esinvitado === undefined ? false : data.esinvitado),
        nombre: data.nombre === undefined ? '' : data.nombre,
        rfc: data.rfc === undefined ? '' : data.rfc,
        email:  data.email === undefined ? '' : data.email,
        direccion: data.direccion === undefined ? '' : data.direccion,
        domiciliofiscalreceptor: data.domiciliofiscalreceptor === undefined ? '' : data.domiciliofiscalreceptor,
        codigoregimenfiscalreceptor: data.codigoregimenfiscalreceptor === undefined ? '' : data.codigoregimenfiscalreceptor,
        operacion: String( data.operacion === undefined ? 0 : data.operacion)
      };
    return this.getJsonParam('facturacion/abcdatosfacturacionpanel', params);
  }

  getPedidoGrid(idusuario: number, idrol: number, g: GridModel) {
    return this.getQueryParam('pedidos/leerpedidos?idusuario=' + idusuario + '&idrol=' + idrol + '&f_inicial=' + g.f_inicial + '&f_final=' + g.f_final);
  }

  

  modificarFechaPedidoPanel( idpedido: number, fecha_entrega: string ) {
    const params = new HttpParams({
      fromObject: {
        idpedido: String(idpedido === undefined ? 0 : idpedido),
        fecha_entrega: String( fecha_entrega === undefined ? '' : fecha_entrega)
      }
    });
    return this.getFormPutParam('pedidos/fechaentregapanel', params);
  }

  getListaRepartidores(repartidorPedidoModel: RepartidorPedidoModel) {
    return this.getQueryParam('pedidos/leerrepartidores?idsucursal=' + repartidorPedidoModel.idsucursal + '&idrol=' + repartidorPedidoModel.idrol);
  }

  cambiarEstatusPedido(data: PedidoModel, idestatus_pedido ) {
    const params = new HttpParams({
      fromObject: {
        idestatus_pedido: String(idestatus_pedido == undefined ? 0 : idestatus_pedido),
        idpedido: String(data.idpedido === undefined ? 0 : data.idpedido)
       }
    });
    return this.getFormPutParam('pedidos/estatuspedidopanel', params);
  }

  putPedidoEntragado(data: PedidoModel ) {
    const params = new HttpParams({
      fromObject: {
        idpedido: String(data.idpedido === undefined ? 0 : data.idpedido)
       }
    });
    return this.getFormPutParam('pedidos/entregapedido', params);
  }

  getDetallePedido(idpedido: number) {
    return this.getQueryParam('pedidos/leerdetallepedidopanel?idpedido=' + idpedido);
  }

  getReportePasteles(idsucursal: number, fecha_entrega: string) {
    return this.getQueryParam('reportepasteles/getreportepasteles?idsucursal=' + idsucursal + '&fecha_entrega=' + fecha_entrega);
  }

  getFechaActual() {
    return this.getQueryParam('reportepasteles/getfechaactual');
  }

  putPedidoNoDisponible(data: PedidoModel ) {
    const params = new HttpParams({
      fromObject: {
        idpedido: String(data.idpedido === undefined ? 0 : data.idpedido),
        idopenpay_cargo: data.idopenpay_cargo === undefined ? '' : data.idopenpay_cargo,
        idcliente_openpay: data.idcliente_openpay === undefined ? '' : data.idcliente_openpay,
        no_orden: data.no_orden === undefined ? '' : data.no_orden,
        total: data.total === undefined ? '' : data.total
       }
    });
    return this.getFormPutParam('pedidos/productonodisponible', params);
  }

  getEscanearCodigoPedido(data: PedidoEscanerCodigoModel) {
    return this.getQueryParam('pedidos/decodificarqrpanel?no_orden=' +  data.no_orden );
  }

  // monitor de la entrega del pedido

  getEntregaPedido(idpedido: number) {
    return this.getQueryParam('reparto/entregasencursopanel?idpedido=' + idpedido);
  }

  getGridPedidosAsignadosRepartidor(idrepartidor: number) {
    return this.getQueryParam('reparto/pedidosasignadospanel?idrepartidor=' + idrepartidor);
  }

  pagoExitoso(cargoid: string ) {
    const params = new HttpParams({
      fromObject: {
        cargoid: cargoid === undefined ? '' : cargoid
       }
    });
    return this.getFormParam('secure3d/pagoexitoso', params);
  }

  pagoRespuesta(cargoid: string) {
    return this.getQueryParam('secure3d/respuestapago?cargoid=' +  cargoid );
  }

  /* servicio para buscar pedidos pendientes de pago y para contracargos 05/11/2021  */
  
  getPedidoPendientePagoGrid(idusuario: number, idrol: number, g: GridModel) {
    return this.getQueryParam('pedidos/leerpedidospendientepago?idusuario=' + idusuario + '&idrol=' + idrol + '&f_inicial=' + g.f_inicial + '&f_final=' + g.f_final);
  }

  getEstatusCargoPedido(cargoid: string) {
    return this.getQueryParam('pedidos/leerestatuscargopedido?idopenpay_cargo=' + cargoid );
  }

  getEstatusCargoPedidoNew(cargoid: string) {
    return this.getQueryParam('pedidos/leerestatuscargopedidonew?idopenpay_cargo=' + cargoid );
  }

  getPedidoValidaGrid(idusuario: number, idrol: number, g: GridModel) {
    return this.getQueryParam('pedidos/leerpedidospendientepago?idusuario=' + idusuario + '&idrol=' + idrol + '&f_inicial=' + g.f_inicial + '&f_final=' + g.f_final);
  }

  getReporteResumenVentas(idusuario: number, idrol: number, f_inicial: string, f_final: string , idsucursal: number) {
    return this.getQueryParam('pedidos/leerreporteresumenventas?idusuario=' + idusuario + '&idrol=' + idrol + '&f_inicial=' + f_inicial + '&f_final=' + f_final + '&idsucursal=' + idsucursal);
  }

  // actualizacion 03 dic 2022

  getRegFiscalReceptor() {
    return this.getQueryParam('facturacion/getregfiscalreceptor');
  }

  updRepartidorAsignado( data: PedidoModel ) {
    const params = new HttpParams({
      fromObject: {
        idpedido: String(data.idpedido === undefined ? 0 : data.idpedido),
        idrepartidor: String( data.idrepartidor === undefined ? 0 : data.idrepartidor)
      }
    });
    return this.getFormPutParam('pedidos/asignarrepartidor', params);
  }

  /* nuevo 09-12-22 00:30 lee los datos del repartidor del pedido */

  // updRepartidorAsignado( data: PedidoModel ) {
  //   const params = new HttpParams({
  //     fromObject: {
  //       idpedido: String(data.idpedido === undefined ? 0 : data.idpedido),
  //       idrepartidor: String( data.idrepartidor === undefined ? 0 : data.idrepartidor),
  //       idsucursal: String( data.idsucursal === undefined ? 0 : data.idsucursal)
  //     }
  //   });
  //   return this.getFormPutParam('pedidos/asignarrepartidor', params);
  // }

  getPedidoRepartidor(idpedido) {
    return this.getQueryParam('pedidos/leerpedidorepartidor?idpedido=' + idpedido);
  }

  /* nuevo 15-12-22 00:30 lee los datos del repartidor por sucursal para el combo de asignar repartidor*/
  
  getRepartidoresSucursal(idsucursal) {
    return this.getQueryParam('pedidos/leerrepartidoressucursal?idsucursal=' + idsucursal);
  }

  updWebCamAsignada( idsucursal: number, idusuario: number, nombre: string ) {
    const params = new HttpParams({
      fromObject: {
        idsucursal: String(idsucursal === undefined ? 0 : idsucursal),
        idusuario: String( idusuario === undefined ? 0 : idusuario),
        nombre: nombre === undefined ? '' : nombre
      }
    });
    return this.getFormPutParam('pedidos/asignarwebcam', params);
  }

}
