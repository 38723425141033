import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import Swal from 'sweetalert2';

import { BannerService } from '../../services/banner.service';
import { BannerModel, BannerParamModel } from '../../models/banner/banner.model';
import { BannerDialogComponent } from './../banner-dialog/banner-dialog.component';
import { BannerImprimirDialogComponent } from '../banner-imprimir-dialog/banner-imprimir-dialog.component';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  imagenPerfilvacia: string;
  parambannermodel: BannerParamModel;
  banner: BannerModel;
  displayedColumns: string[] = [];
  datasource: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  tabla: any[];

  encapsulation: ViewEncapsulation.None;

  constructor(private bannerservice: BannerService,
              private dialog: MatDialog,
              public paginatorInt: MatPaginatorIntl) { }

  ngOnInit(): void {

    this.imagenPerfilvacia = './assets/images/sinimagen.jpg';
    this.parambannermodel = new BannerParamModel();
    this.banner = new BannerModel();
    this.parambannermodel.idsucursal = Number(sessionStorage.ids);

   

    // if (validaacceso(Number(sessionStorage.idr))) {
    //   console.log('acceso correcto');
    // } else {

    //   Swal.fire({
    //     position: 'center',
    //     icon: 'warning',
    //     title: '¡Acceso no permitido!',
    //     showConfirmButton: true,
    //     timer: 7500
    //   });

    //   // console.log('acceso no permitido');
    //   return;
    // }

    // function validaacceso(rol: number) {
    //   let acceso = false;
    //   switch (rol) {
    //     case 1:
    //       acceso = true; // Super Admin
    //       break;
    //     case 2:
    //       acceso = true; // Administrador (Tienda)
    //       break;
    //     case 3:
    //       acceso = false; // Administrador (Sucursal)
    //       break;
    //     case 4:
    //       acceso = false; // Empleado
    //       break;
    //     case 5:
    //       acceso = false; // Cliente
    //       break;
    //     case 6:
    //       acceso = false; // Repartidor
    //       break;
    //     // default:
    //     //   this.router.navigateByUrl('/');
    //     //   break;
    //   }
    //   return acceso;
    // }

    this.getGridBanner();

    this.paginatorInt.firstPageLabel = 'Primera página';
    this.paginatorInt.lastPageLabel = 'Última página';
    this.paginatorInt.itemsPerPageLabel = 'Registro por página';
    this.paginatorInt.nextPageLabel     = 'Siguiente página';
    this.paginatorInt.previousPageLabel = 'Anterior página';
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tablaFilter(filterValue.trim().toLowerCase());
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  tablaFilter(cadena: string) {

    // llenar la tabla para el reporte
    // const cadena = filterValue.trim().toLowerCase();
    const tablatemp = [];
    for (let i=0; i < this.tabla.length; i++) {
      if (this.tabla[i].sucursal.trim().toLowerCase().indexOf(cadena) > -1 
          // this.tabla[i].abreviatura.trim().toLowerCase().indexOf(cadena) > -1 ||
          // this.tabla[i].sucursal.trim().toLowerCase().indexOf(cadena) > -1 ||
          // this.tabla[i].telefono.trim().toLowerCase().indexOf(cadena) > -1 ||
          // this.tabla[i].entrada.trim().toLowerCase().indexOf(cadena) > -1 ||
          // this.tabla[i].salida.trim().toLowerCase().indexOf(cadena) > -1
         ) {
        tablatemp.push(this.tabla[i]);
      }
    }
    this.tabla = [];
    this.tabla = tablatemp;
    console.log(tablatemp);

  }

  acceso(  idmodulo: number, idsubmodulo: number, row) {
    this.openDialogBanner(row);
   }

   getGridBanner() {
     Swal.fire({
       allowOutsideClick: false,
       icon: 'info',
       text: 'Cargando registros ...'
     });
     Swal.showLoading();
     this.displayedColumns = ['idbanner_sucursal', 'sucursal', 'estaactivo', 'url_imagen', 'actions'];
     this.bannerservice.getBannerProducto(this.parambannermodel.idsucursal).subscribe( (resp: any[]) => {
           Swal.close();
          //  console.log(resp);
           this.dataSource = new MatTableDataSource<any>(resp);
           this.tabla = resp;
           this.dataSource.paginator = this.paginator;
     }, (err) => {
       Swal.close();
       switch (err.status) {
       case 0:
           Swal.fire({
             icon: 'warning',
             text: 'No hay conexión con el servidor'
           });
           break;
        case 409:
          Swal.fire({
           icon: 'error',
           text: 'Error ' + err.status + ' para el grid de imagenes del banner en el servidor'
          });
          break;
         case 500:
             Swal.fire({
               icon: 'error',
               text: 'Error ' + err.status + ' para el grid de imagenes del banner en el servidor'
             });
             break;

       }
     });
   }

   openDialogBanner(rows): void {
     if ( rows === undefined ) {
       const dialogRef = this.dialog.open( BannerDialogComponent , {

         data: {titulo: 'Agregar imagen al banner',
           row: '',
         },
         disableClose: true,
         height: String(screen.height * 0.90),
         width: String(screen.width * 0.80),
         panelClass: 'full-screen-modal'
       });
       dialogRef.afterClosed().subscribe(result => {
        this.getGridBanner();
       });
     } else {
       const dialogRef = this.dialog.open( BannerDialogComponent , {
         data: {titulo: 'Actualizar imagen del banner',
         row: rows
       },
       disableClose: true,
       height: String(screen.height * 0.90),
       width: String(screen.width * 0.80),
       });
       dialogRef.afterClosed().subscribe(result => {
         this.getGridBanner();
       });
     }
   }

   UpdBajaBanner(UsuarioRow: any) {
    this.bajaBanner(UsuarioRow);
  }

  bajaBanner(row: any) {

    const username = 'foto';

    // operacion
    // 1. insertar
    // 2. actualizar
    // 3. dar de baja
    // 4. restaurar usuario

    row.operacion = 3;
    // console.log(row);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Dar de baja ' + username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.bannerservice.abcBannerProducto(row).subscribe( (resp: any[]) => {
          Swal.close();

          // Swal.fire({
          //   icon: 'success',
          //   text: 'El extra ' + username + ' ha sido dado de baja.'
          // });

          // si regresa respuesta el servicio
          if (resp) {

            if (resp['idrespuesta'] > 0) {
              Swal.fire({
                icon: 'success',
                text: resp['respuesta']
              });
              this.getGridBanner();
              } else {
              Swal.fire({
                icon: 'error',
                text: resp['respuesta']
              });
              }
          }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del panel del banner'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del panel del banner'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'La ' + username + ' ha sido dada de baja.',
            'success'
          );

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La baja de la foto no fue realizada!',
          'warning'
        );
      }
    });

  }

  updRestaurarBanner(row: any) {

    this.restaurarBanner(row);

  }

  restaurarBanner(row: any) {

    const username = 'foto';

    // operacion
    // 1. insertar
    // 2. modificar
    // 3. dar de baja
    // 4. restaurar usuario

    row.operacion = 4;
    // console.log(row);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Restaurar ' + username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.bannerservice.abcBannerProducto(row).subscribe( (resp: any[]) => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            text: 'La ' + username + ' ha sido restaurada.'
          });

          // si regresa respuesta el servicio
          if (resp) {

            if (resp['idrespuesta'] > 0) {
              Swal.fire({
                icon: 'success',
                text: resp['respuesta']
              });
              this.getGridBanner();
              } else {
              Swal.fire({
                icon: 'error',
                text: resp['respuesta']
              });
              }
          }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del panel de banner'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del panel de banner'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'La ' + username + ' ha sido restaurada.',
            'success'
          );

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La restauración de la foto no fue realizada.',
          'warning'
        );
      }
    });

  }

  reloadgrid() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.getGridBanner();
    Swal.close();
  }

  openDialogImprimir(rows): void {
    // if ( rows === undefined ) {
      const dialogRef = this.dialog.open( BannerImprimirDialogComponent , {
        data: {titulo: 'Vista previa',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.80),
      width: String(screen.width * 0.90),
      });
      dialogRef.afterClosed().subscribe(result => {
       // this.getGridSucursal();
      });
    // }
  }

}
