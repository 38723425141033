import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { IngredienteService } from '../../services/ingrediente.service';
import { CombosService } from '../../services/combos.service';
import { RellenoSucursalModel } from '../../models/ingrediente/ingrediente.model';


@Component({
  selector: 'app-ingrediente-relleno-sucursal-dialog',
  templateUrl: './ingrediente-relleno-sucursal-dialog.component.html',
  styleUrls: ['./ingrediente-relleno-sucursal-dialog.component.css']
})
export class IngredienteRellenoSucursalDialogComponent implements OnInit {

  rellenoSucursalCatalogoModel: RellenoSucursalModel;

  idsucursal: number;
  idrol: number;

  formulario: FormGroup;
  submitted = false;

  combosucursal: any[];
  comborelleno: any[];

  constructor(public dialogRef: MatDialogRef<IngredienteRellenoSucursalDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private ingredienteservice: IngredienteService,
              private combosservicio: CombosService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {

      this.formulario = this.formBuilder.group({
        idrelleno: ['', Validators.required],
        idsucursal: ['', Validators.required],
        estaactivo: ['']
      }, {
      });

      this.idsucursal = Number(sessionStorage.getItem('ids'));
      this.idrol = Number(sessionStorage.getItem('idr'));

      this.rellenoSucursalCatalogoModel = new RellenoSucursalModel();
      // var input = document.getElementById('idtienda');
      // input.disabled = false;

     // this.catpresentacionesmodel.idtienda = 0;
     // this.idrol = 1;
     // this.idsucursal = 0;
      this.getComboSucursal();
      this.getComboRelleno();
  
      if (this.data.row.idrellenos_sucursales > 0) {
        this.rellenoSucursalCatalogoModel = this.data.row;
      //  this.catpresentacionesmodel.personas = this.data.row.personas;
      }

      // if (this.catpresentacionesmodel.idpresentacion === 0) {
      //   this.catpresentacionesmodel.idtienda = 0;
      // }
      // this.extrasmodel.idsucursal = Number(sessionStorage.getItem('ids'));

      this.rellenoSucursalCatalogoModel.idusuario = Number(sessionStorage.getItem('idu'));
      if (this.rellenoSucursalCatalogoModel.estaactivo) {
          this.rellenoSucursalCatalogoModel.estaactivo = true;
      } else {
          this.rellenoSucursalCatalogoModel.estaactivo = false;
      }
  }

  // variable para acceder a los campos del formulario
  get f() { return this.formulario.controls; }

  get idsucursalNoValido() {
    return this.formulario.get('idsucursal').invalid;
  }

  get idrellenoNoValido() {
    return this.formulario.get('idrelleno').invalid;
  }

  abcRellenoSucursal() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    this.rellenoSucursalCatalogoModel.operacion = this.rellenoSucursalCatalogoModel.idrellenos_sucursales > 0 ? 2 : 1;
    this.rellenoSucursalCatalogoModel.idusuario = Number(sessionStorage.getItem('idu'));

    // console.log(this.extrasmodel);
    // if (Number(sessionStorage.getItem('idr')) > 1 ) {
    //   this.panSucursalCatalogoModel.idtienda = 0;
    // }

    this.ingredienteservice.abcCatRellenosSucursal(this.rellenoSucursalCatalogoModel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.rellenoSucursalCatalogoModel.operacion === 1) {
          this.rellenoSucursalCatalogoModel.idrellenos_sucursales = resp['idrespuesta'] !== -1 || resp['idrespuesta'] !== -2 ? resp['idrespuesta'] : 0 ;
          this.rellenoSucursalCatalogoModel.operacion = this.rellenoSucursalCatalogoModel.operacion === 1 ? 2 : 2;
        }
        if (resp) {
          if (resp['idrespuesta'] > 0) {
            Swal.fire({
              icon: 'success',
              text: resp['respuesta']
            });
           } else {
            Swal.fire({
              icon: 'error',
              text: resp['respuesta']
            });
           }
        }
        }, (err) => {
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado la sucursal del relleno'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado la sucursal del relleno'
                });
                break;
          }
        });
  }

  getComboSucursal() {
    this.combosservicio.getComboPanelSucursal(this.idsucursal, this.idrol).subscribe( (resp: any[]) => {
        this.combosucursal = resp;
        console.log(this.combosucursal);
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }

  getComboRelleno() {
    this.combosservicio.getComboRellenos().subscribe( (resp: any[]) => {
        this.comborelleno = resp;
        console.log(this.comborelleno);
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {

    this.submitted = true;

   // se detiene si el formulario es invalido
    if (this.formulario.invalid) {
        return;
    }
    this.abcRellenoSucursal();

    // valores visibles del formmulario
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
    // valores del modelo que se pasa al abc
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.extrasmodel, null, 4));

  }

}
