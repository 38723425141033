import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { CategoriaModel } from '../../models/categoria/categoria.model';
import { CategoriaService } from '../../services/categoria.service';
import { CombosService } from '../../services/combos.service';

@Component({
  selector: 'app-categorias-dialog',
  templateUrl: './categorias-dialog.component.html',
  styleUrls: ['./categorias-dialog.component.css']
})
export class CategoriasDialogComponent implements OnInit {

  formulario: FormGroup;
  submitted = false;

  categoriamodel: CategoriaModel;
  combotiendas: any[];
  idtienda: number;
  idrol: number;

  constructor(public dialogRef: MatDialogRef<CategoriasDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private servicecategoria: CategoriaService,
              private formBuilder: FormBuilder,
              private comboservice: CombosService) { }

  ngOnInit(): void {
      this.idrol = Number(sessionStorage.idr);
      this.formulario = this.formBuilder.group({
        idtienda: [''],
        categoria: ['', Validators.required]
      }, {
      });

      this.categoriamodel = new CategoriaModel();

      this.categoriamodel.idcategoria = 0;
     // this.categoriamodel.idtienda = 0;
      this.getComboTienda();


      if (this.data.row.idcategoria > 0) {
        this.categoriamodel = this.data.row;
      }

      this.categoriamodel.idsucursal = Number(sessionStorage.ids);
      this.categoriamodel.idusuario = Number(sessionStorage.idu);
      this.categoriamodel.idrol = Number(sessionStorage.idr);

      // if (this.categoriamodel.estaactivo) {
      //     this.categoriamodel.estaactivo = true;
      // } else {
      //     this.categoriamodel.estaactivo = false;
      // }

     // console.log(this.categoriamodel);
  }

  // variable para acceder a los campos del formulario
  get f() { return this.formulario.controls; }

  get idtiendaNoValido() {
    return this.formulario.get('idtienda').invalid;
  }

  get categoriaNoValido() {
    return this.formulario.get('categoria').invalid;
  }

  getComboTienda() {
    this.comboservice.getComboTiendas().subscribe( (resp: any[]) => {
        this.combotiendas = resp;
        console.log(this.combotiendas);
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor o revise su conexion a internet.',
            confirmButtonColor: '#391410',
            confirmButtonText: 'ACEPTAR'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor.',
          confirmButtonColor: '#391410',
          confirmButtonText: 'ACEPTAR'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor.',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
            break;
      }
    });
  }

  abcCatCategorias() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    this.categoriamodel.operacion = this.categoriamodel.idcategoria > 0 ? 2 : 1;
    if (this.idrol > 1) {
      this.categoriamodel.idtienda = 1;
    }
    console.log(this.categoriamodel);

    this.servicecategoria.abcCatCategoria(this.categoriamodel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.categoriamodel.operacion === 1) {
          this.categoriamodel.idcategoria = resp['idrespuesta'] !== -1 || resp['idrespuesta'] !== -2 ? resp['idrespuesta'] : 0 ;
          this.categoriamodel.operacion = this.categoriamodel.operacion === 1 ? 2 : 2;
        }
        if (resp) {
          if (resp['idrespuesta'] > 0) {
            Swal.fire({
              icon: 'success',
              text: resp['respuesta'],
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           } else {
            Swal.fire({
              icon: 'error',
              text: resp['respuesta'],
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           }
        }
        }, (err) => {
         Swal.close();
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor o revise su conexión a internet.',
                confirmButtonColor: '#391410',
                confirmButtonText: 'ACEPTAR'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado del catálogo de categorías.',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado del catálogo de categorías.',
                  confirmButtonColor: '#391410',
                  confirmButtonText: 'ACEPTAR'
                });
                break;
          }
        });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {

    this.submitted = true;

    // se detiene si el formulario es invalido
    if (this.formulario.invalid) {
        return;
    }
    this.abcCatCategorias();

    // valores visibles del formmulario
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
    // valores del modelo que se pasa al abc
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.extrasmodel, null, 4));

  }

}
