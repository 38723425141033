import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { PanPresentacionCatalogoModel } from './../../models/ingrediente/ingrediente.model';
import { IngredienteService } from '../../services/ingrediente.service';
import { ProductoService } from '../../services/producto.service';
import { CombosService } from '../../services/combos.service';
import {  NgForm } from '@angular/forms';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Dimensions,  ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-ingrediente-pan-presentacion-dialog',
  templateUrl: './ingrediente-pan-presentacion-dialog.component.html',
  styleUrls: ['./ingrediente-pan-presentacion-dialog.component.css']
})
export class IngredientePanPresentacionDialogComponent implements OnInit {

  Forma: NgForm;
  combocategorias: any[];
  combopresentaciones: any[];
  presentacionmodel: PanPresentacionCatalogoModel;
  displayedColumns: string[] = [];
  dataSource: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  idrol: number;
  idpan: number;
  imagenPerfilvacia: string;
  verImagenes: boolean;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  combosucursales: any[];
  combotiendas: any[];
  activaGuardar: boolean;
  visualizar: number;

  constructor(public dialogRef: MatDialogRef<IngredientePanPresentacionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private productoservice: ProductoService,
              private ingredienteservice: IngredienteService,
              private comboservice: CombosService,
              public paginatorInt: MatPaginatorIntl) { }

  ngOnInit(): void {
            this.visualizar = 0;
            this.activaGuardar = true;
            this.imagenPerfilvacia = './assets/images/sinimagen.jpg';
            this.verImagenes = false;
            this.presentacionmodel = new PanPresentacionCatalogoModel ();
            this.presentacionmodel.idpresentacion = 0;
            this.presentacionmodel.idusuario = Number(sessionStorage.getItem('idu'));
            this.presentacionmodel.idtienda = 0
            this.presentacionmodel.idsucursal = Number(sessionStorage.getItem('ids'));
            this.idrol = Number(sessionStorage.getItem('idr'));
            this.idpan = this.data.row.idpan;
            this.getComboTienda();
            this.getGridIngredientesPresentacion();

            this.presentacionmodel.imagenbase64 = '';

  }

  getComboTienda() {
    this.comboservice.getComboTiendas().subscribe( (resp: any[]) => {
        this.combotiendas = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor',
            confirmButtonColor: '#391410',
            confirmButtonText: 'ACEPTAR'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor',
          confirmButtonColor: '#391410',
          confirmButtonText: 'ACEPTAR'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
            break;
      }
    });
  }


  getComboSucursal(idtienda: number) {
    this.comboservice.getComboSucursal(idtienda == 0 ? 1 : idtienda).subscribe( (resp: any[]) => {
        this.combosucursales = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }

  getComboPresentacion(idsucursal: number) {
    this.comboservice.getComboPresentaciones(idsucursal).subscribe( (resp: any) => {
        this.combopresentaciones = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en combo presentacion ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en combo presentacion ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }

  getGridIngredientesPresentacion() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();

     switch (this.idrol) {
       case 1:
          this.displayedColumns = ['tienda', 'sucursal','estaactivo', 'presentacion', 'actions'];
         break;
       case 2:
        this.displayedColumns = ['sucursal','estaactivo', 'presentacion', 'actions'];
        
       break;     
       default:
        this.displayedColumns = ['sucursal','estaactivo', 'presentacion', 'actions'];
        
         break;
     }
    

    this.ingredienteservice.getGridCatPanesPresentacion(this.idpan, Number(sessionStorage.getItem('ids')) == 6 ? 0 : Number(sessionStorage.getItem('ids'))).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de presentaciones en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de presentaciones en el servidor'
            });
            break;

      }
    });
  }

  abcIngredientesPresentacion() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    this.presentacionmodel.operacion = this.presentacionmodel.idpanespresentacion > 0 ? 2 : 1;
    this.presentacionmodel.idpan = this.data.row.idpan;
    this.presentacionmodel.idusuario = Number(sessionStorage.getItem('idu'));
    if (this.presentacionmodel.operacion === 1) {
      this.presentacionmodel.url_img_panpresentacion = '';
    }
    this.ingredienteservice.abcCatPanesPresentacion(this.presentacionmodel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.presentacionmodel.operacion === 1) { // nuevo registro
          this.presentacionmodel.idpanespresentacion = resp['idrespuesta'] !== -1 || resp['idrespuesta'] !== -2 ? resp['idrespuesta'] : 0 ;
        }
        if (resp) {
          if (resp['idrespuesta'] > 0) {
            Swal.fire({
              icon: 'success',
              text: resp['respuesta']
            });
            this.getGridIngredientesPresentacion();
           } else {
            Swal.fire({
              icon: 'error',
              text: resp['respuesta']
            });
           }
        }
        }, (err) => {
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado de la presentación'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado de la presentación'
                });
                break;
          }
        });
  }

  actualizar(row) {     
      if(this.combosucursales == undefined){
        this.getComboSucursal(row.idtienda);
        this.getComboPresentacion(row.idsucursal);
      }
      this.presentacionmodel = row;
      this.visualizar = 1;
      if (this.presentacionmodel.url_img_panpresentacion === '' || this.presentacionmodel.url_img_panpresentacion === null) {
        this.verImagenes = false;
      } else {
        this.verImagenes = true;
      }
  }

  nuevo() {
    this.presentacionmodel = new PanPresentacionCatalogoModel ();
    this.presentacionmodel.idsucursal = 0;
    this.presentacionmodel.idpresentacion = 0;
    this.presentacionmodel.estaactivo = false;
    this.presentacionmodel.url_img_panpresentacion = '';
    this.verImagenes = false;
    this.visualizar = 1;
   // this.presentacionmodel.url_img_panpresentacion = this.imagenPerfilvacia;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
      this.presentacionmodel.imagenbase64 = this.croppedImage;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
      // cropper ready
     // this.bannermodel.imagenbase64 = this.croppedImage;
      // console.log(this.bannermodel.imagenbase64);
  }

  loadImageFailed() {
      // show message
  }

  reloadgrid() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.activaGuardar = false;
    this.getGridIngredientesPresentacion();
    this.activaGuardar = true;
    Swal.close();
  }

}
