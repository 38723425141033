import { Component, OnInit, SecurityContext } from '@angular/core';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-terminos',
  templateUrl: './terminos.component.html',
  styleUrls: ['./terminos.component.css']
})
export class TerminosComponent implements OnInit {

  srcpdf: any;

  pdfSource = 'https://en.ferntech.net/ilovechocolate/terminos/TerminosyCondicionesdeUso.pdf';
  safeUrl: SafeUrl;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
   // this.srcpdf = '../../assets/terminos.pdf';
    this.srcpdf = this.sanitizer.bypassSecurityTrustResourceUrl('https://en.ferntech.net/ilovechocolate/terminos/TerminosyCondicionesdeUso.pdf');
    this.safeUrl = this.sanitizer.bypassSecurityTrustUrl('https://en.ferntech.net/ilovechocolate/terminos/TerminosyCondicionesdeUso.pdf');
   //   this.safeUrl = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfSource));
    // this.srcpdf = '../../assets/legales.pdf';

  }

}
