export class BannerModel {
    // tslint:disable-next-line: variable-name
    idbanner_sucursal: number;
    idsucursal: number;
    url_imagen: string;
    imagenbase64: string;
    estaactivo: boolean;
    getMimeType: string;
    operacion: number;
    sucursal: string;
}

export class BannerParamModel {
    // tslint:disable-next-line: variable-name
    idbanner_sucursal: number;
    idsucursal: number;
    url_imagen: string;
    imagenbase64: string;
    estaactivo: boolean;
    operacion: number;
}
