import { Component, OnInit, Inject, ElementRef, NgZone, ViewChild } from '@angular/core';
import {  PedidoEntregaModel, PedidoRepartidorModel } from './../../models/pedido/pedido.model';
import { PedidoService } from '../../services/pedido.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { AgmCoreModule, AgmMap, MapsAPILoader, MouseEvent } from '@agm/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CombosService } from '../../services/combos.service';
import { timer, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';


@Component({
  selector: 'app-pedido-entrega-monitor-dialog',
  templateUrl: './pedido-entrega-monitor-dialog.component.html',
  styleUrls: ['./pedido-entrega-monitor-dialog.component.css']
})
export class PedidoEntregaMonitorDialogComponent implements OnInit {

  formulario: FormGroup;
  submitted = false;
  pedidoEntregamodel: PedidoEntregaModel;
  pedidoRepartidormodel: PedidoRepartidorModel;
  mapType: string;
  latitud: number;
  longitud: number;
  latitudd: number;
  longitudd: number;
  repartidor: string;
  destino: string;
  numeroorden: string;
  estatusorden: string;
  fechaentrega: string;
  address: string;
  private geoCoder;
  entregaPedido: any[];
  idpedido: number;
  reloj = timer(1000, 5000);
  colorlinea: string;
 // arrastrable: boolean;
  arrastrable = false;
  repartidorEtiqueta: string;
  destinoEtiqueta: string;
  iconRepartidor: string;
  iconDestino: string;
  displayedColumns: string[] = [];
  dataSource: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  pedidonumero: string;
  subscribeTimer: Subscription;
  selectedElement: any;

  constructor(public dialogRef: MatDialogRef<PedidoEntregaMonitorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private pedidoservice: PedidoService,
              private mapa: AgmCoreModule,
              private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              private combosservicio: CombosService,
              private formBuilder: FormBuilder) {
              this.idpedido = Number(this.data.row.idpedido);
              this.pedidonumero = this.data.row.no_orden;

             /* Este codigo inicia el conteo */
              const reloj = timer(1000, 5000);
              this.subscribeTimer = reloj.subscribe(val => {
                 console.log(this.idpedido);
                // this.getEntrega();
                 console.log('UBICACION.');
                 this.getUbicaccionRepartidor();
              });
             /* fin de inicio del conteo */

              this.repartidor = this.data.row.nombre_repartidor;

            //  this.latitudd = 19.495856;
            //  this.longitudd = -96.849749;
              this.repartidorEtiqueta = 'R';
              this.iconRepartidor = '../assets/images/mapaiconmoto.png';
              this.destinoEtiqueta = 'D';
              this.iconDestino = '../assets/images/mapaiconocliente.png';
              this.idpedido = Number(this.data.row.idpedido);
              console.log(this.idpedido);
              this.getEntrega();


              // this.latitud =  this.pedidoEntregamodel.latitud_origen;
              // this.longitud = this.pedidoEntregamodel.longitud_origen;
              // this.latitudd =  this.pedidoEntregamodel.latitud_destino;
              // this.longitudd = this.pedidoEntregamodel.longitud_destino;
            }
  ngOnInit(): void {

    this.pedidoEntregamodel = new PedidoEntregaModel();
    // this.mapType = 'roadmap';
    // this.mapType = 'satellite';
    // this.mapType = 'hybrid';
    // this.mapType = 'terrain';
    // this.mapType = 'styled_map';
    this.mapType = 'roadmap';

    this.getGridPedidosAsignados();


    // this.latitud =  Number(this.pedidoEntregamodel.latitud);
    // this.longitud = Number(this.pedidoEntregamodel.longitud);
 //   this.idpedido = Number(this.data.row.idpedido);
  //  this.getEntrega();
   // this.latitud =  this.pedidoEntregamodel.latitud_origen;
   // this.longitud = this.pedidoEntregamodel.longitud_origen;
  }

  getEntrega() {
    // Swal.fire({
    //   allowOutsideClick: false,
    //   icon: 'info',
    //   text: 'Acualizando la ubicación...'
    // });
    // Swal.showLoading();
    this.pedidoservice.getEntregaPedido(this.idpedido).subscribe( (resp: any[]) => {

     // this.pedidoEntregamodel = resp;
      this.latitud = Number(resp[0].latitud_origen);
      this.longitud = Number(resp[0].longitud_origen);
      this.latitudd = Number(resp[0].latitud_destino);
      this.longitudd = Number(resp[0].longitud_destino);
      this.destino = resp[0].nombre_recibe;
      this.address = resp[0].direccion;
      this.estatusorden = resp[0].estatus_pedido;
      this.fechaentrega = resp[0].fecha_entrega;
      // console.log(this.latitud);
      // console.log(this.longitud);
      // console.log(this.latitudd);
      // console.log(this.longitudd);
      if (resp[0].idestatus_pedido === 3) {
        this.colorlinea = 'red';
      } else {
        this.colorlinea = 'red';
      }
      // Swal.close();
    }, (err) => {
    //  Swal.close();
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor o revise su conexión a internet.',
            confirmButtonColor: '#391410',
            confirmButtonText: 'ACEPTAR'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor',
          confirmButtonColor: '#391410',
          confirmButtonText: 'ACEPTAR'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
            break;
      }
    });
  }

  getUbicaccionRepartidor() {
    // Swal.fire({
    //   allowOutsideClick: false,
    //   icon: 'info',
    //   text: 'Acualizando la ubicación...'
    // });
    // Swal.showLoading();
    this.pedidoservice.getEntregaPedido(this.idpedido).subscribe( (resp: any[]) => {

     // this.pedidoEntregamodel = resp;
      this.latitud = Number(resp[0].latitud_origen);
      this.longitud = Number(resp[0].longitud_origen);

      this.destino = resp[0].nombre_recibe;
      this.address = resp[0].direccion;
      this.estatusorden = resp[0].estatus_pedido;
      this.fechaentrega = resp[0].fecha_entrega;

      // console.log(this.latitud);
      console.log('pedido');
      console.log(this.idpedido);
      console.log('ubicacion');
      console.log(this.latitud);
      console.log(this.longitud);

      if (resp[0].idestatus_pedido === 4) {
        Swal.fire({
                   icon: 'success',
                   text: 'Pedido entregado con éxito',
                   confirmButtonColor: '#391410',
                   confirmButtonText: 'ACEPTAR'
                 });
        this.subscribeTimer.unsubscribe();
        this.dialogRef.close();
      }


      // if (resp[0].idestatus_pedido === 3) {
      //   this.colorlinea = 'red';
      // } else {
      //   this.colorlinea = 'red';
      // }
      // Swal.close();
    }, (err) => {
    //  Swal.close();
    //  switch (err.status) {
    //   case 0:
    //       Swal.fire({
    //         icon: 'warning',
    //         text: 'No hay conexión con el servidor o revise su conexión a internet.',
    //         confirmButtonColor: '#391410',
    //         confirmButtonText: 'ACEPTAR'
    //       });
    //       break;
    //    case 409:
    //      Swal.fire({
    //       icon: 'error',
    //       text: 'Error en sucursal ' + err.status + ' en el servidor',
    //       confirmButtonColor: '#391410',
    //       confirmButtonText: 'ACEPTAR'
    //      });
    //      break;
    //     case 500:
    //         Swal.fire({
    //           icon: 'error',
    //           text: 'Error en sucursal ' + err.status + ' en el servidor',
    //           confirmButtonColor: '#391410',
    //           confirmButtonText: 'ACEPTAR'
    //         });
    //         break;
    //  }
    });
  }

  getGridPedidosAsignados() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumns = ['estatus_pedido', 'no_orden', 'fecha_entrega', 'direccion'];
    this.pedidoservice.getGridPedidosAsignadosRepartidor(this.data.row.idrepartidor).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor o revise su conexión a internet.'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de categorias en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de categorias en el servidor'
            });
            break;
      }
    });
  }

  onSubmit() {

  }

  actualizarUbicacion() {
    this.getUbicaccionRepartidor();
    //  this.latitud = 19.532799;
    //  this.longitud = -96.908224;
  //  this.latitud = this.latitudd;
   // this.longitud = this.longitudd;
  //  console.log(this.latitud);
  //  console.log(this.longitud);
  }


  onNoClick(): void {
    this.subscribeTimer.unsubscribe();
    this.dialogRef.close();
  }



  changeLocation(event: any) {
  //  this.sucursalmodel.latitud = event.coords.lat;
  //  this.sucursalmodel.longitud = event.coords.lng;
    // this.destination.latitude = event.coords.lat;
    // this.destination.longitude = event.coords.lng;
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
  //  this.pedidoEntregamodel.latitud_origen = String($event.coords.lat);
  //  this.pedidoEntregamodel.longitud_origen = String($event.coords.lng);
   // this.getAddress(this.latitude, this.longitude);
  }

  clickedMarker(label: string, index: number) {
   // console.log(`clicked the marker: ${label || index}`)
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onValueChange(event) {
    if (event && this.selectedElement && event.target == this.selectedElement) {
        // do whatever you like
        console.log(event.target.value);
    }
  }

}
