export class DianolaboralModel {
    iddiafestivo: number;
    idsucursal: number;
    diafestivo: string;
    descripcion: string;
    activo: boolean;
    idusuario: number;
    operacion: number;
    idtienda: number;
    diafestivocorto: string;
}

export class ParamDianolaboralModel {
    idsucursal: number;
    idtienda: number;
    idrol: number;
}

