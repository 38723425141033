import { Component, OnInit } from '@angular/core';
import { UsuarioLoginModel } from '../../models/adminusuarios/adminusuarios.model';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  usuario: UsuarioLoginModel;
  recordar: boolean;
  rol: number;

  isHidden: boolean;
  constructor( private auth: AuthService,  private router: Router) { }

 // localStorage.getItem('username')
  ngOnInit() {
    this.isHidden = false;
    this.usuario = new UsuarioLoginModel();
    this.usuario.app = '3';
    // if (sessionStorage.getItem('username')) {
    //   this.usuario.username = sessionStorage.getItem('username');
    //   this.recordar = true;
    // } else {
    //   this.recordar = false;
    // }
  }

  onSubmit( form: NgForm) {
    if ( form.invalid ) { return; }
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Validando usuario ...'
    });
    Swal.showLoading();

    this.auth.login( this.usuario )
    .subscribe( resp => {
            Swal.close();
         //   if (this.recordar) {
            this.isHidden = !this.isHidden;
            sessionStorage.setItem('username', this.usuario.username);
            form.reset();
            form.controls['username'].reset();
            this.usuario.username = '';

            // let x = document.getElementById('username');
            // this.x.display = false;
            this.rol = resp[ 'idrol' ];
            switch (this.rol) {
              case 1:
                this.router.navigateByUrl('panel/pedidos');
                break;
              case 2:
                this.router.navigateByUrl('panel/pedidos');
                break;
              case 3:
                this.router.navigateByUrl('panel/pedidos');
                break;
              case 4:
                  this.router.navigateByUrl('panel/pedidos');
                  break;
              case 5:
                    this.router.navigateByUrl('/');

                    break;
              default:
                this.router.navigateByUrl('/');
                break;
            }
            // } else {
            //   localStorage.clear();
            // }

    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
      case 401:
            Swal.fire({
             icon: 'warning',
             text: 'Contraseña incorrecta'
            });
            break;
       case 403:
              Swal.fire({
               icon: 'warning',
               text: 'Usuario no confirmado'
              });
              break;
       case 404:
         Swal.fire({
          icon: 'warning',
          text: 'Usuario no encontrado'
         });
         break;
        case 406:
            Swal.fire({
              icon: 'warning',
              text: 'Usuario dado de baja'
            });
            break;
        case 409:
            Swal.fire({
              icon: 'error',
              text: 'Error en el servidor'
            });
            break;
        default:
        Swal.fire({
          icon: 'error',
          text: 'Error en el servidor ' + err.status
        });
        break;
      }
    });
  }

}
