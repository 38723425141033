import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { PedidoService } from '../../services/pedido.service';

@Component({
  selector: 'app-pagoexitoso',
  templateUrl: './pagoexitoso.component.html',
  styleUrls: ['./pagoexitoso.component.css']
})



export class PagoexitosoComponent implements OnInit {
  cargoid: string;
  constructor(private pedidoService: PedidoService,
              private router: Router) { 
                this.cargoid = sessionStorage.getItem("cargoId");
                
               }


  ngOnInit(): void {   
    
    this.pagoexitoso(this.cargoid)
  }

  pagoexitoso(cargoid:string) {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Actualizando pedido ...'
    });
    Swal.showLoading();
    
    this.pedidoService.pagoExitoso(cargoid).subscribe( (resp: any[]) => {
          Swal.close();
         
         Swal.fire({
          icon: 'success',
          text: 'Pedido exitoso'
        });
        
        
        sessionStorage.removeItem("cargoId");
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
          case 403:
              Swal.fire({
                icon: 'warning',
                text: 'No se registro su pago correctamente, verifique con la sucursal correspondiente'
              });
              break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para la actualización del pedido en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para la actualización del pedido en el servidor'
            });
            break;
        default: 
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status
            });
            break;

      }
    });
  }

}
