import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSettings } from '../models/appsettings';
import { CategoriaModel } from '../models/categoria/categoria.model';

@Injectable({
  providedIn: 'root'
})
export class CategoriaService {

  private header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  .append('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  private headerJSON = new HttpHeaders().set('Content-Type', 'application/json');

  private headerQP = new HttpHeaders().set('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  constructor(private http: HttpClient) { }

  getQueryParam( query: string) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.get(url, { headers: this.headerQP });
  }

  getFormParam( query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.header });
  }

  getFormParamJSON(query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.headerJSON });
  }

  getGridCatCategoria(categoriaModel: CategoriaModel ) {
    return this.getQueryParam('categorias/leercategoriaspanel?idsucursal=' + categoriaModel.idsucursal + '&idrol=' + categoriaModel.idrol);
  }

  abcCatCategoria(data: CategoriaModel) {
    const params = new HttpParams({
      fromObject: {
        idtienda: String(data.idtienda === undefined ? 0 : data.idtienda),
        idcategoria: String(data.idcategoria === undefined ? 0 : data.idcategoria),
        categoria: data.categoria === undefined ? '' : data.categoria,
        estaactivo: String(data.estaactivo === undefined ? 0 : data.estaactivo),
        idusuario: String(data.idusuario === undefined ? 0 : data.idusuario),
        idrol: String(data.idrol === undefined ? 0 : data.idrol),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar, 3 dar de baja)
      }
    });
    return this.getFormParam('categorias/abccategoriapanel', params);
  }


}
