import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
// import Jquery from 'jquery';

import { ProductoService } from '../../services/producto.service';
import { CombosService } from '../../services/combos.service';
import { CatPresentacionesModel } from '../../models/producto/producto.model';

@Component({
  selector: 'app-presentaciones-dialog',
  templateUrl: './presentaciones-dialog.component.html',
  styleUrls: ['./presentaciones-dialog.component.css']
})
export class PresentacionesDialogComponent implements OnInit {
  catpresentacionesmodel: CatPresentacionesModel;

  formulario: FormGroup;
  submitted = false;

  combotiendas: any[];
  combosucursales: any[];
  idsucursal: number;
  idrol: number;

  constructor(public dialogRef: MatDialogRef<PresentacionesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private productoservice: ProductoService,
              private combosservicio: CombosService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      presentacion: ['', Validators.required],
      idtienda: [0],
      idsucursal: [0],
      personas: [''],
      estadisponible: [''],
      entregainmediata: ['']
    }, {
    });

    this.idrol = Number(sessionStorage.idr);

    this.catpresentacionesmodel = new CatPresentacionesModel();

    this.getComboTienda();
    this.getComboSucursal(this.data.row == '' ? 0 : this.data.row.idtienda);
    if (this.data.row.idpresentacion > 0) {
      this.catpresentacionesmodel = this.data.row;
      this.catpresentacionesmodel.personas = this.data.row.personas;
    }   

    this.catpresentacionesmodel.idusuario = Number(sessionStorage.getItem('idu'));
    // if (this.catpresentacionesmodel.estadisponible) {
    //     this.catpresentacionesmodel.estadisponible = true;
    // } else {
    //     this.catpresentacionesmodel.estadisponible = false;
    // }
    // if (this.catpresentacionesmodel.entregainmediata) {
    //   this.catpresentacionesmodel.entregainmediata = true;
    // } else {
    //     this.catpresentacionesmodel.entregainmediata = false;
    // }

  }

 


  getComboSucursal(idtienda: number) {
    this.combosservicio.getComboSucursal(idtienda).subscribe( (resp: any[]) => {
        this.combosucursales = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }

  abcCatPresentacion() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    this.catpresentacionesmodel.operacion = this.catpresentacionesmodel.idpresentacion > 0 ? 2 : 1;
    if (this.idrol > 1 ) {
      this.catpresentacionesmodel.idtienda = 1;
    }
    this.productoservice.abcCatIngredientes(this.catpresentacionesmodel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.catpresentacionesmodel.operacion === 1) {
          this.catpresentacionesmodel.idpresentacion = resp['idrespuesta'] !== -1 || resp['idrespuesta'] !== -2 ? resp['idrespuesta'] : 0 ;
          this.catpresentacionesmodel.operacion = this.catpresentacionesmodel.operacion === 1 ? 2 : 2;
        }
        if (resp) {
          if (resp['idrespuesta'] > 0) {
            Swal.fire({
              icon: 'success',
              text: resp['respuesta'],
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           } else {
            Swal.fire({
              icon: 'error',
              text: resp['respuesta'],
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           }
        }
        }, (err) => {
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor',
                confirmButtonColor: '#391410',
                confirmButtonText: 'ACEPTAR'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado del catálogo de presentaciones',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado del catálogo de presentaciones',
                  confirmButtonColor: '#391410',
                  confirmButtonText: 'ACEPTAR'
                });
                break;
          }
        });
  }

  getComboTienda() {
    this.combosservicio.getComboTiendas().subscribe( (resp: any[]) => {
        this.combotiendas = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor',
            confirmButtonColor: '#391410',
            confirmButtonText: 'ACEPTAR'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor',
          confirmButtonColor: '#391410',
          confirmButtonText: 'ACEPTAR'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
            break;
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  
}
