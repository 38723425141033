import { Component, OnInit, ViewChild } from '@angular/core';
import { SucursalModel, ParamSucursalModel } from './../../models/sucursal/sucursal.model';

import { MatDialog} from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';

import { SucursalService } from '../../services/sucursal.service';
import { SucursalFechaEntregaDomDesacDialogComponent } from '../sucursal-fecha-entrega-dom-desac-dialog/sucursal-fecha-entrega-dom-desac-dialog.component';
import { SucursalLimiteCarritoPedidoDomDialogComponent } from '../sucursal-limite-carrito-pedido-dom-dialog/sucursal-limite-carrito-pedido-dom-dialog.component';
import { SucursalLimiteProductosPedidoDialogComponent } from '../sucursal-limite-productos-pedido-dialog/sucursal-limite-productos-pedido-dialog.component';


@Component({
  selector: 'app-entrega-domicilio',
  templateUrl: './entrega-domicilio.component.html',
  styleUrls: ['./entrega-domicilio.component.css']
})
export class EntregaDomicilioComponent implements OnInit {
  paramsucursalmodel: ParamSucursalModel;
  sucursalmodel: SucursalModel;
  displayedColumns: string[] = [];
  datasource: any;
  tabla: any[];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;

  constructor(private catsucursal: SucursalService,
              private dialog: MatDialog,
              public paginatorInt: MatPaginatorIntl) { }

  ngOnInit(): void {

    this.sucursalmodel = new SucursalModel();
    this.paramsucursalmodel = new ParamSucursalModel();
    this.paramsucursalmodel.idsucursal = Number(sessionStorage.ids);
    this.paramsucursalmodel.idrol = Number(sessionStorage.idr);

    this.getGridSucursal();

    this.paginatorInt.firstPageLabel = 'Primera página';
    this.paginatorInt.lastPageLabel = 'Última página';
    this.paginatorInt.itemsPerPageLabel = 'Registro por página';
    this.paginatorInt.nextPageLabel     = 'Siguiente página';
    this.paginatorInt.previousPageLabel = 'Anterior página';


  }

  reloadgrid() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.getGridSucursal();
    Swal.close();
  }

  getGridSucursal() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumns = ['idsucursal', 'estaactivo', 'entregadomicilio', 'aplicacostoenvio', 'abreviatura', 'sucursal',  'actions'];
    this.catsucursal.getSucursalCatalogoGrid(this.paramsucursalmodel).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.tabla = resp;
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de sucursales en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de sucursales en el servidor'
            });
            break;

      }
    });

  }

  applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.tablaFilter(filterValue.trim().toLowerCase());
      this.dataSource.filter = filterValue.trim().toLowerCase();
  
  }
  
  tablaFilter(cadena: string) {
  
      // llenar la tabla para el reporte
      // const cadena = filterValue.trim().toLowerCase();
      const tablatemp = [];
      for (let i=0; i < this.tabla.length; i++) {
        if (
            this.tabla[i].abreviatura.trim().toLowerCase().indexOf(cadena) > -1 ||
            this.tabla[i].sucursal.trim().toLowerCase().indexOf(cadena) > -1 
           ) {
          tablatemp.push(this.tabla[i]);
        }
      }
      this.tabla = [];
      this.tabla = tablatemp;
      console.log(tablatemp);
  
    }


  openDialogFechaEntregaDesactivar(rows: any) {
    if ( rows !== undefined ) {
      const dialogRef = this.dialog.open( SucursalFechaEntregaDomDesacDialogComponent , {
        data: {titulo: 'Desactivar fechas de entrega domicilio',
          row: rows,
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridSucursal();
      });
    }

  }

  openDialogLimiteCarritoPedidoDom(rows: any) {
    if ( rows !== undefined ) {
      const dialogRef = this.dialog.open( SucursalLimiteCarritoPedidoDomDialogComponent , {
        data: {titulo: 'Limite de productos del carrito por pedido',
          row: rows,
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridSucursal();
      });
    }

  }

  openDialogLimiteProdPedido(rows: any) {
    if ( rows == undefined ) {
      const dialogRef = this.dialog.open( SucursalLimiteProductosPedidoDialogComponent , {
        data: {titulo: 'Limite de productos y pedidos por dia',
          row: rows,
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridSucursal();
      });
    }

  }


  

}
