import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import Swal from 'sweetalert2';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private router: Router,
              private auth: AuthService) { }

  ngOnInit(): void {
  }

  rutasmodulos(component: string) {
    switch (component) {
      case 'catsucursal':
        this.acceso(1, 1, component);
        break;
      case 'catproducto':
        this.acceso(2, 3, component);
        break;
      case 'catbanner':
        this.acceso(2, 4, component);
        break;
      case 'catextras':
        this.acceso(3, 15, component);
        break;
      case 'pedidos':
        this.acceso(4, 28, component);
        break;
      default:
        Swal.fire({
          icon: 'warning',
          text: 'Módulo no desarrollado'
        });
        break;
    }
  }
  acceso(idmodulo: number, idsubmodulo: number, component) {
    // console.log(component);
    this.router.navigate( [component] );
    /* this.auth.acceso(Number(sessionStorage.getItem('id')), idmodulo, idsubmodulo).subscribe( (resp: any[]) => {
      if (resp) {
        if (resp[0].idrespuesta === 0) {
           Swal.fire({
            type: 'warning',
            text: 'No tiene acceso al módulo'
           });
        } else {
          this.router.navigate( [component] );
        }
      }
   }, (err) => {
    switch (err.status) {
     case 0:
         Swal.fire({
           type: 'warning',
           text: 'No hay conexión con el servidor'
         });
         break;
      case 409:
        Swal.fire({
         type: 'error',
         text: 'Error ' + err.status + ' de la atención de notificaciones en el servidor'
        });
        break;
       case 500:
           Swal.fire({
             type: 'error',
             text: 'Error ' + err.status + ' de la atención de notificaciones en el servidor'
           });
           break;
     }
   });
   } */
  }

}
