import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PedidoService } from '../../services/pedido.service';
import Swal from 'sweetalert2';
import { FacturacionModel } from '../../models/facturacion/facturacion.model';
import { FormControl, NgForm } from '@angular/forms';

@Component({
  selector: 'app-datosfacturacion-dialog',
  templateUrl: './datosfacturacion-dialog.component.html',
  styleUrls: ['./datosfacturacion-dialog.component.css']
})
export class DatosfacturacionDialogComponent implements OnInit {
  fecha_entrega_nueva: string;
  hora_entrega_nueva: string;
  facturacionModel: FacturacionModel;
  usocfdicombo: any [];
  regfiscalreceptor: any[];
  
  constructor(public dialogRef: MatDialogRef<DatosfacturacionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pedidoService: PedidoService) { }

  ngOnInit(): void {   
    this.facturacionModel = new FacturacionModel();
    this.getUsoCFDI();
    this.getRegFiscalReceptor();
    this.getDatosFacturacion();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getUsoCFDI(){
     
    this.pedidoService.getUsoCFDI().subscribe( (resp: any[]) => {         
          this.usocfdicombo = resp;          
    }, (err) => {      
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' en el servidor al traer los datos del uso del CFDI'
         }); 
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el servidor al traer los datos del uso del CFDI'
            });
            break;

      }
    });
  }

  getRegFiscalReceptor(){
     
    this.pedidoService.getRegFiscalReceptor().subscribe( (resp: any[]) => {         
          this.regfiscalreceptor = resp;          
    }, (err) => {      
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' en el servidor al traer los datos del uso del CFDI'
         }); 
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el servidor al traer los datos del uso del CFDI'
            });
            break;

      }
    });
  }


  getDatosFacturacion(){
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando datos de fcaturación ...'
    });
    Swal.showLoading();    
    this.pedidoService.getDatosFacturacionPanel(this.data.row.idusuario_tramita, this.data.row.esinvitado, this.data.row.idpedido).subscribe( (resp: FacturacionModel) => {
          Swal.close();
          this.facturacionModel = resp[0];          
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' en el servidor al traer los datos de facturación'
         }); 
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el servidor al traer los datos de facturación'
            });
            break;

      }
    });
  }

  abcDatosFacturacion(form:  NgForm) {

    if(form.valid && this.facturacionModel.idusocfdi != '0'){ 
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn ',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true,
        confirmButtonColor: '#391410',
        cancelButtonColor: '#d01d17',
        confirmButtonText: 'Aceptar'
      } );
  
      swalWithBootstrapButtons.fire({
        title: '¿Desea cambiar los datos de facturacion de pedido ' + this.data.row.no_orden + ' ?',
        text: 'Confirme por favor',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
  
      Swal.fire({
        allowOutsideClick: false,
        icon: 'info',
        text: 'Actualizando datos ...'
      });
      Swal.showLoading();
      this.facturacionModel.operacion = 2;
      this.pedidoService.abcDatosFacturacionPanel(this.facturacionModel, this.data.row.idpedido).subscribe( (resp: any[]) => {
        Swal.close();      
        
          Swal.fire({
            icon: 'success',
            text: 'Datos de facturación actualizados'
          });
        
        }, (err) => {
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el servidor al guardar los datos de facturación'
             });
             break;
             default:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el servidor al guardar los datos de facturación'
                });
                break;
                
          }
        });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La operación no fue realizada!',
          'warning'
        );
      }
    });  
  } else {
    Object.values( form.controls ).forEach( control => {
      control.markAsTouched();
    });
    Swal.fire({
      icon: 'warning',
      text: 'Faltan campos o campos invalidos'
    });
  }

  }

}
