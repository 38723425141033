import { Component, OnInit, Inject, ElementRef, NgZone } from '@angular/core';
import { SucursalModel, ParamSucursalModel } from './../../models/sucursal/sucursal.model';
import { SucursalService } from '../../services/sucursal.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { AgmCoreModule, AgmMap, MapsAPILoader, MouseEvent } from '@agm/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CombosService } from '../../services/combos.service';

@Component({
  selector: 'app-enviomonitor-dialog',
  templateUrl: './enviomonitor-dialog.component.html',
  styleUrls: ['./enviomonitor-dialog.component.css']
})
export class EnviomonitorDialogComponent implements OnInit {

  formulario: FormGroup;
  submitted = false;
  paramsucursalmodel: ParamSucursalModel;
  sucursalmodel: SucursalModel;
  mapType: string;
  latitud: number;
  longitud: number;
  address: string;
  private geoCoder;
  combotiendas: any[];


  constructor(public dialogRef: MatDialogRef<EnviomonitorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private catservice: SucursalService,
              private mapa: AgmCoreModule,
              private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              private combosservicio: CombosService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
        this.sucursalmodel = new SucursalModel();
        this.paramsucursalmodel = new ParamSucursalModel();
        // this.getComboTienda();
        this.mapType = 'satellite';

        this.sucursalmodel.idsucursal = Number(sessionStorage.ids);
        this.sucursalmodel.idusuario = Number(sessionStorage.idu);
        this.sucursalmodel.latitud = '19.532799';
        this.sucursalmodel.longitud = '-96.908224';
        if (this.data.row.idsucursal > 0) {
          this.sucursalmodel = this.data.row;
        }

        this.latitud =  Number(this.sucursalmodel.latitud);
        this.longitud = Number(this.sucursalmodel.longitud);
  }

  onSubmit() {
    this.submitted = true;
      // detener si el formulario es invalido
    // if (this.formulario.invalid) {
    //       return;
    // }
   // this.abcCatSucursal();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeLocation(event: any) {
  //  this.sucursalmodel.latitud = event.coords.lat;
  //  this.sucursalmodel.longitud = event.coords.lng;
    // this.destination.latitude = event.coords.lat;
    // this.destination.longitude = event.coords.lng;
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.sucursalmodel.latitud = String($event.coords.lat);
    this.sucursalmodel.longitud = String($event.coords.lng);
   // this.getAddress(this.latitude, this.longitude);
  }

}
