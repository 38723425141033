import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PedidoDetalleModel, PedidoModel } from '../../models/pedido/pedido.model';
import Swal from 'sweetalert2';
import { PedidoService } from '../../services/pedido.service';
import { MatTableDataSource } from '@angular/material/table';
import { getLocaleDateFormat } from '@angular/common';

import { SucursalFavoritaModel, SucursalModel } from '../../models/sucursal/sucursal.model';
import { SucursalService } from '../../services/sucursal.service';

@Component({
  selector: 'app-pedido-ticket-dialog',
  templateUrl: './pedido-ticket-dialog.component.html',
  styleUrls: ['./pedido-ticket-dialog.component.css']
})
export class PedidoTicketDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PedidoTicketDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private servicepedido: PedidoService,
              private servicesucursal: SucursalService,
              private pedidoservice: PedidoService) { }

  pedidoModel: PedidoModel;
  sucursalFavorita: SucursalFavoritaModel;
  sucursalModel: SucursalModel;
  displayedColumns: string[] = [];
  displayedColumnsE: string[] = [];
  datasource: any;
  datasource2: any;
  dataSource: MatTableDataSource<any>;
  dataSource2: MatTableDataSource<any>;
  nombreSucursal: string;
  direccionSucursal: string;
  telefonoSucursal: string;
  detallepedido: any[];
  fechaImpreso: any;

  ngOnInit(): void {

  this.pedidoModel = new PedidoModel();
  this.sucursalFavorita = new SucursalFavoritaModel();
  this.sucursalModel = new SucursalModel();

  this.pedidoModel = this.data.row;

  this.sucursalModel.idsucursal = this.pedidoModel.idsucursal;
  this.getSucursalFav();

  this.getFechaImpresion();

  this.getGridProductos();
  
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getFechaImpresion() {
    const f = new Date();
    this.fechaImpreso = f.getDate() + '/' + ((f.getMonth() + 1) <= 9 ? '0'+ (f.getMonth() + 1) : (f.getMonth() + 1) )+ '/' + f.getFullYear() + ' ' + f.getHours() + ':' + (f.getMinutes() <= 9 ? '0'+f.getMinutes() : f.getMinutes()) + ' ' + ((f.getHours() > 12) ? 'PM' : 'AM') ;
  }

  getGridProductos() {
    this.displayedColumns = ['cantidad', 'peso', 'producto','precio','importe'];
    this.servicepedido.getDetallePedido(Number(this.pedidoModel.idpedido)).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.detallepedido = resp;
    }, (err) => {
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de productos en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de productos en el servidor'
            });
            break;
      }
    });
  }

  getGridProductosExtras() {
    this.displayedColumnsE = ['leyenda'];
    this.servicepedido.getDetallePedido( Number(this.pedidoModel.idpedido)).subscribe( (resp: any[]) => {
    this.dataSource2 = new MatTableDataSource<any>(resp);
         // this.arreglo = Array.from(resp);
         // this.dataSource = this.arreglo;
         // this.dataSource.paginator = this.paginator;
          // console.log(this.arreglo);
    }, (err) => {
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de productos en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de productos en el servidor'
            });
            break;
      }
    });
  }

  getSucursalFav() {
    this.servicesucursal.getSucursalFavorita( this.sucursalModel).subscribe( (resp: any) => {
          this.sucursalFavorita = resp;

          this.nombreSucursal = this.sucursalFavorita[0].sucursal;
          this.direccionSucursal = this.sucursalFavorita[0].direccion;
          this.telefonoSucursal = this.sucursalFavorita[0].telefono;
          // console.log(this.sucursalFavorita[0].sucursal);
          // console.log(this.sucursalFavorita[0].direccion);
          // console.log(this.sucursalFavorita[0].telefono);
         // this.arreglo = Array.from(resp);
         // this.dataSource = this.arreglo;
         // this.dataSource.paginator = this.paginator;
          // console.log(this.arreglo);
    }, (err) => {
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de productos en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de productos en el servidor'
            });
            break;

      }
    });
  }

  cambiarEstatusPedido(){
       if (this.data.row.idestatus_pedido == 7) {
        
        this.pedidoservice.cambiarEstatusPedido(this.data.row, this.data.idestatus_pedido).subscribe( (resp: any[]) => {
         
          
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el cambio de estatus'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el cambio de estatus'
                  });
                  break;
            }
          });
        }
      }
  }
