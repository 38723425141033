import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsuarioService } from '../../services/usuario.service';
import { UsuarioParamModel } from '../../models/adminusuarios/adminusuarios.model';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import {MatDialogRef, MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { runInThisContext } from 'vm';

@Component({
  selector: 'app-usuarioconfirmacion',
  templateUrl: './usuarioconfirmacion.component.html',
  styleUrls: ['./usuarioconfirmacion.component.css']
})
export class UsuarioconfirmacionComponent implements OnInit {

  idusuario: number;
  username: string;
  mensaje: string;
  mensaje2: string;

  dialogRef: MatDialogRef<Usuarioconfirmaciondialog>;

  constructor(private servicioUsuario: UsuarioService,
              private _route: ActivatedRoute,
              public dialog: MatDialog
              ) { }

  isDone = false;
  isDone2 = false;
  ngOnInit(): void {
    this.idusuario = Number(this._route.snapshot.paramMap.get('userId'));
    this.getUsuarioRegistrado();
    this.confirmacionRegistro();
  }

  getUsuarioRegistrado() {
    this.servicioUsuario.getPerfilUsuario(this.idusuario).subscribe( (resp: any[]) => {
      if (resp) {
        this.username = resp['username'];

      //  Swal.fire({
      //    icon: 'success',
      //    text: 'Usuario confirmado'
      //  });
      }
   }, (err) => {
    switch (err.status) {
      case 0:
           Swal.fire({
             icon: 'warning',
             text: 'No hay conexión con el servidor o verifique su conexión de internet.'
           });
           break;
      case 404:
      // Swal.fire({
      //   icon: 'warning',
      //   text: 'El usuario ya fue confirmado anteriormente'
      // });
          break;
     }
   });
  }

  confirmacionRegistro() {
    // Swal.fire({
    //   allowOutsideClick: false,
    //   icon: 'info',
    //   text: 'Verificación en proceso ...'
    // });
    // Swal.showLoading();
    //this.openDialogSpinner('Verificación en proceso');
    this.servicioUsuario.ConfimarRegistroUsuario(this.idusuario).subscribe( (resp: any[]) => {
      // this.isDone = true;
      // this.isDone2 = false;
      // if (resp) {
        // Swal.close();
    //this.closeDialog();
    this.mensaje='I love chocolate te da la bienvenida.';
    this.mensaje2='Tú cuenta ha sido verificada.'

      // Swal.fire({
      //    icon: 'success',
      //    text: 'Gracias por confirmar tu usuario.'
      //  });
      
   }, (err) => {
    //this.closeDialog();
    switch (err.status) {
     case 0:
         Swal.fire({
          allowOutsideClick: false,
           icon: 'warning',
           text: 'No hay conexión con el servidor'
         });
         Swal.close();
          //this.openDialog('No hay conexión con el servidor o verifique su conexión de internet.');
          break;
     case 404:
          // this.isDone = false;
          // this.isDone2 = true;
         // Swal.close();
         // this.closeDialog();
          //this.openDialog('Este usuario ya fue confirmado anteriormente.');
         // this.closeDialog();

         
            this.mensaje= 'El usuario '+ this.username +' ya fue confirmado anteriormente.';
            this.mensaje2= '';
          // Swal.fire({
          //   title: 'El usuario ya fue confirmado anteriormente',
          //   showClass: {
          //     popup: 'animate__animated animate__fadeInDown'
          //   },
          //   hideClass: {
          //     popup: 'animate__animated animate__fadeOutUp'
          //   }
          // });
          break;
     }
   });
  }

  getUrl() {
    return 'url(\'https://en.ferntech.net/ilovechocolate/logos/fondoemail.jpg\')';
  }


  openDialogSpinner(msj: string) {
    this.dialogRef = this.dialog.open(Usuarioconfirmaciondialog, {
      disableClose: false,
      data: {titulo: 'Atencion',
          row: {mensaje: msj, titulo: 'Respuesta', visible: false},
      }
    }
    );
    this.dialogRef.beforeClosed().subscribe((result: string) => {
      console.log('RIght before close,', result);
    });
    this.dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

  openDialog(msj: string) {
    this.dialogRef = this.dialog.open(Usuarioconfirmaciondialog, {
      disableClose: false,
      data: {titulo: 'Atencion',
          row: {mensaje: msj, titulo: 'Respuesta', visible: true},
      }
    }
    );
    this.dialogRef.beforeClosed().subscribe((result: string) => {
      console.log('RIght before close,', result);
    });
    this.dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

  // openDialogErrorServidor() {
  //   this.dialog.open(Usuarioconfirmaciondialog, {
  //     data: {titulo: 'Atencion',
  //         row: {mensaje: 'No hay conexión con el servidor', titulo: 'Respuesta', visible: true},
  //     }
  //   });
  // }

  // openDialog() {
  //   this.dialog.open(Usuarioconfirmaciondialog, {
  //     disableClose: false,
  //     data: {titulo: 'Atencion',
  //         row: {mensaje: 'Gracias por confirmar tu usuario.', titulo: 'Respuesta', visible: true },
  //     }
  //   });
  // }

  closeDialog() {
   this.dialogRef.close(true);
  }

}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'Usuarioconfirmaciondialog',
  templateUrl: 'usuarioconfirmaciondialog.html',
  styleUrls: ['./usuarioconfirmacion.component.css']
})

// tslint:disable-next-line: component-class-suffix
export class Usuarioconfirmaciondialog implements OnInit {
  visible: boolean;
  imagenlogo: string;

  constructor(private servicioUsuario: UsuarioService,
              private _route: ActivatedRoute,
              public dialogRef: MatDialogRef<Usuarioconfirmaciondialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                dialogRef = dialogRef;
              }

  mensaje: string;
  titulo: string;

  ngOnInit(): void {
    this.mensaje = this.data.row.mensaje;
    this.titulo = this.data.row.titulo;
    this.visible = this.data.row.visible;
    this.imagenlogo = './assets/images/logo.gif';
  }

  onNoClick(): void {
    this.dialogRef.close(true);
  }

}
