import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PedidoService } from '../../services/pedido.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pedidos-cambiofecha-dialog',
  templateUrl: './pedidos-cambiofecha-dialog.component.html',
  styleUrls: ['./pedidos-cambiofecha-dialog.component.css']
})
export class PedidosCambiofechaDialogComponent implements OnInit {
  fecha_entrega_nueva: string;
  hora_entrega_nueva: string;
  constructor(public dialogRef: MatDialogRef<PedidosCambiofechaDialogComponent>,
             @Inject(MAT_DIALOG_DATA) public data: any,
             private pedidoService: PedidoService) { }

  ngOnInit(): void {
    this.fecha_entrega_nueva = this.data.row.fechaentrega;
    this.hora_entrega_nueva = this.data.row.horaentrega;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  modificarFechaPedidoPanel() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Desea cambiar la fecha de entrega del pedido ' + this.data.row.no_orden + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Actualizando fecha de entrega ...'
    });
    Swal.showLoading();
    
    this.pedidoService.modificarFechaPedidoPanel(this.data.row.idpedido,this.fecha_entrega_nueva+' '+this.hora_entrega_nueva).subscribe( (resp: any[]) => {
      Swal.close();      
      
        Swal.fire({
          icon: 'success',
          text: 'Fecha de entrega actualizada'
        });
      
      }, (err) => {
       switch (err.status) {
        case 0:
            Swal.fire({
              icon: 'warning',
              text: 'No hay conexión con el servidor'
            });
            break;
         case 409:
           Swal.fire({
            icon: 'error',
            text: 'Error ' + err.status + ' en el guardado del catálogo de extras'
           });
           break;
          case 500:
              Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del catálogo de extras'
              });
              break;
        }
      });
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
      swalWithBootstrapButtons.fire(
        'Cancelar',
        'La operación no fue realizada!',
        'warning'
      );
    }
  });  
  }

}
