import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import md5 from 'crypto-js/md5';
import sha1 from 'crypto-js/sha1';
import { AppSettings } from '../models/appsettings';
import { UsuarioLoginModel } from '../models/adminusuarios/adminusuarios.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private url = AppSettings.API_ENDPOINT;
  private headerQP = new HttpHeaders().set('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);
  auth: number;

  getQueryParam( query: string) {
    const url =   AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.get(url, { headers: this.headerQP });
    }

  constructor( private http: HttpClient) { }

  longout() {
    sessionStorage.clear();
  }

  login( usuario: UsuarioLoginModel) {

    const params = new HttpParams({
      fromObject: {
        grant_type: 'password',
        username: usuario.username,
        password: sha1(usuario.password),
        app: usuario.app
      }
    });
    const header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    .append('Authorization', 'Basic ' + 'dXNlcnNlcnZpY2lvczpmZjM0ZTQ4MDY3MzliNDIzZjcwZmY5MzYwMGZjYjE1MGMyMTBkNzkw=');
    return this.http.post (`${ this.url }login`, params, {headers: header}).pipe(
      map( resp => {
        sessionStorage.setItem('username', resp[ 'username' ]);
        sessionStorage.setItem('name', resp[ 'nombre' ]);
        sessionStorage.setItem('idu', resp[ 'idusuario' ]);
        sessionStorage.setItem('idr', resp[ 'idrol' ]);
        sessionStorage.setItem('ids', resp[ 'idsucursal' ]);
        sessionStorage.setItem('eml', resp[ 'email' ]);
        sessionStorage.setItem('est', resp[ 'estatus' ]);
        sessionStorage.setItem('op', resp[ 'operacion' ]);
        sessionStorage.setItem('image', resp['url_foto_perfil']);
        return resp;
      })
    );
}

estaAutenticado(): boolean {
   return Number(sessionStorage.getItem('idu')) > 0;
}

acceso( idusuario: number, idmodulo: number, idsubmodulo: number) {
  return this.getQueryParam('Useradmin/acceso?idusuario=' + idusuario + '&idmodulo=' + idmodulo + '&idsubmodulo=' + idsubmodulo);
}

}
