import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { ProductoService } from '../../services/producto.service';
import { CombosService } from '../../services/combos.service';
import {  NgForm } from '@angular/forms';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { ProductoIngredienteModel } from '../../models/producto/producto.model';

@Component({
  selector: 'app-productosingredientes-dialog',
  templateUrl: './productosingredientes-dialog.component.html',
  styleUrls: ['./productosingredientes-dialog.component.css']
})
export class ProductosingredientesDialogComponent implements OnInit {

  submitted = false;
  Forma: NgForm;
  comboPan: any[];
  comboRelleno: any[];
  comboCobertura: any[];
  combotiendas: any[];
  combosucursales: any[];
  idrol: number;
  idtienda: number;
  idsucursal: number;
  visualizar: number;
  ingredientemodel: ProductoIngredienteModel;
  displayedColumns: string[] = [];
  dataSource: any;
  idtipoingrediente: number;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  constructor(public dialogRef: MatDialogRef<ProductosingredientesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private productoservice: ProductoService,
              private comboservice: CombosService,
              public paginatorInt: MatPaginatorIntl) { }

  ngOnInit(): void {
    this.ingredientemodel = new ProductoIngredienteModel();
    this.ingredientemodel.idpanes_sucursales = 0;
    this.ingredientemodel.idrellenos_sucursales = 0;
    this.ingredientemodel.idcobertura_sucursales = 0;
    this.getComboTienda();
    this.getComboSucursal(this.data.row.idtienda);
    this.idtienda = this.data.row.idtienda;
    this.visualizar = 0;
    this.idrol = Number(sessionStorage.getItem('idr'));
    this.idsucursal = Number(sessionStorage.getItem('ids'));
    this.getGridProductosIngrediente();
  }

  
  getComboTienda() {
    this.comboservice.getComboTiendas().subscribe( (resp: any[]) => {
        this.combotiendas = resp;
        this.visualizar=1;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor',
            confirmButtonColor: '#391410',
            confirmButtonText: 'ACEPTAR'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor',
          confirmButtonColor: '#391410',
          confirmButtonText: 'ACEPTAR'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
            break;
      }
    });
  }


  getComboSucursal(idtienda: number) {
    this.comboservice.getComboSucursal(idtienda == 0 ? 1 : idtienda).subscribe( (resp: any[]) => {
        this.combosucursales = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }
   
  getCombosIngredientes(idsucursal:number){
   this.getComboPan(idsucursal);
   this.getComboRelleno(idsucursal);
   this.getComboCobertura(idsucursal);   
  }

  getComboPan(idsucursal:number) {
   
    this.comboservice.getComboPanesSucursal(idsucursal).subscribe( (resp: any[]) => {
        this.comboPan = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor o revise su conexión a internet.'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error  en combo categoría ' + err.status + ' en el servidor.'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error  en combo categoría ' + err.status + ' en el servidor.'
            });
            break;
      }
    });
  }

  getComboRelleno(idsucursal:number) {
   //  this.idtipoingrediente = 2;
    this.comboservice.getComboRellenosSucursal(idsucursal).subscribe( (resp: any[]) => {
        this.comboRelleno = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor o revise su conexión a internet.'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error  en combo categoría ' + err.status + ' en el servidor.'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error  en combo categoría ' + err.status + ' en el servidor.'
            });
            break;
      }
    });
  }

  getComboCobertura(idsucursal:number) {
  //  this.idtipoingrediente = 3;
    this.comboservice.getComboCoberturasSucursal(idsucursal).subscribe( (resp: any[]) => {
        this.comboCobertura = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor o revise su conexión a internet.'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error  en combo categoría ' + err.status + ' en el servidor.'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error  en combo categoría ' + err.status + ' en el servidor.'
            });
            break;
      }
    });
  }

  getGridProductosIngrediente() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
      this.displayedColumns = ['sucursal','pan', 'relleno', 'cobertura', 'actions'];
    this.productoservice.getGridProductosIngrediente(this.data.row.idproducto).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor o revise su conexión a internet.'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de categorias en el servidor'
         });
         break;
      }
    });
  }

  abcProductoIngredientes() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    this.ingredientemodel.operacion = this.ingredientemodel.idingredientes > 0 ? 2 : 1;
    this.ingredientemodel.idproducto = this.data.row.idproducto;
    this.ingredientemodel.idusuario = Number(sessionStorage.getItem('idu'));
    this.productoservice.abcProductosIngrediente(this.ingredientemodel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.ingredientemodel.operacion === 1) { // nuevo registro
          this.ingredientemodel.idingredientes = resp['idrespuesta'] !== -1 || resp['idrespuesta'] !== -2 ? resp['idrespuesta'] : 0 ;
        }
        if (resp) {
          if (resp['idrespuesta'] > 0) {
            Swal.fire({
              icon: 'success',
              text: resp['respuesta']
            });
            
           } else {
            Swal.fire({
              icon: 'error',
              text: resp['respuesta']
            });
           }  
           this.nuevo();         
         this.getGridProductosIngrediente();
        }
        }, (err) => {
         Swal.close();
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor o revise su conexión a internet.'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado de la categoria.'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado de la categoria.'
                });
                break;
          }
        });
  }

 

  actualizar(row) {
    this.getCombosIngredientes(row.idsucursal);
    setTimeout (() => {
      this.ingredientemodel = row;
      this.idsucursal = row.idsucursal;
   }, 1000);
  }

  nuevo() {
    this.ingredientemodel = new ProductoIngredienteModel();
    this.ingredientemodel.idpanes_sucursales = 0;
    this.ingredientemodel.idrellenos_sucursales = 0;
    this.ingredientemodel.idcobertura_sucursales = 0;
    this.idsucursal = 0;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  reloadgrid() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.getGridProductosIngrediente();
    Swal.close();
  }


}
