import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { PanCatalogoModel } from '../../models/ingrediente/ingrediente.model';
import { IngredienteService } from '../../services/ingrediente.service';

@Component({
  selector: 'app-ingrediente-pan-dialog',
  templateUrl: './ingrediente-pan-dialog.component.html',
  styleUrls: ['./ingrediente-pan-dialog.component.css']
})
export class IngredientePanDialogComponent implements OnInit {

  formulario: FormGroup;
  submitted = false;
  ingredientemodel: PanCatalogoModel;

  constructor(public dialogRef: MatDialogRef<IngredientePanDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder,
              private ingredienteService: IngredienteService
              ) { }

  ngOnInit(): void {

              this.formulario = this.formBuilder.group({
              pan: ['', Validators.required]
              }, {
              });

             // this.ingredientemodel.idpan = 0;
              this.ingredientemodel = new PanCatalogoModel();
              if (this.data.row.idpan > 0) {
                this.ingredientemodel = this.data.row;
              }

              console.log(this.ingredientemodel);

  }

  // variable para acceder a los campos del formulario
  get f() { return this.formulario.controls; }

  get coberturaNoValido() {
    return this.formulario.get('pan').invalid;
  }

  abcCatPanes() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    this.ingredientemodel.operacion = this.ingredientemodel.idpan > 0 ? 2 : 1;
    this.ingredientemodel.idusuario = Number(sessionStorage.getItem('idu'));
    console.log(this.ingredientemodel);
    this.ingredienteService.abcCatPanes(this.ingredientemodel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.ingredientemodel.operacion === 1) {
          this.ingredientemodel.idpan = resp['idrespuesta'] !== -1 || resp['idrespuesta'] !== -2 ? resp['idrespuesta'] : 0 ;
          this.ingredientemodel.operacion = this.ingredientemodel.operacion === 1 ? 2 : 2;
        }
        if (resp) {
          if (resp['idrespuesta'] > 0) {
            Swal.fire({
              icon: 'success',
              text: resp['respuesta'],
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           } else {
            Swal.fire({
              icon: 'error',
              text: resp['respuesta'],
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           }
        }
        }, (err) => {
         Swal.close();
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor o revise su conexión a internet.',
                confirmButtonColor: '#391410',
                confirmButtonText: 'ACEPTAR'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado del catálogo de panes.',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado del catálogo de panes.',
                  confirmButtonColor: '#391410',
                  confirmButtonText: 'ACEPTAR'
                });
                break;
          }
        });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {

    this.submitted = true;

    // se detiene si el formulario es invalido
    if (this.formulario.invalid) {
        return;
    }
    this.abcCatPanes();

    // valores visibles del formmulario
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
    // valores del modelo que se pasa al abc
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.extrasmodel, null, 4));

  }

}
