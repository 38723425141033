import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-avisodelivery',
  templateUrl: './avisodelivery.component.html',
  styleUrls: ['./avisodelivery.component.css']
})
export class AvisodeliveryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
