import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSettings } from '../models/appsettings';
import { ExtrasModel } from '../models/extra/extras.model';

@Injectable({
  providedIn: 'root'
})
export class ExtraService {

  private header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  .append('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  private headerJSON = new HttpHeaders().set('Content-Type', 'application/json');

  private headerQP = new HttpHeaders().set('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  constructor(private http: HttpClient) { }

  getQueryParam( query: string) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.get(url, { headers: this.headerQP });
  }

  getFormParam( query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.header });
  }

  getFormParamJSON(query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.headerJSON });
  }

  getCatExtrasGrid(idsucursal: number, idrol: number) {
    return this.getQueryParam('extras/leerextraspanel?idsucursal=' + idsucursal + '&idrol=' + idrol);
  }

  getCatExtrasSucursal(idsucursal: number) {
    return this.getQueryParam('extras/leerformextras?idsucursal=' + idsucursal);
  }

  abcCatExtras(data: ExtrasModel) {
    const params = new HttpParams({
      fromObject: {
        idextra: String(data.idextra === undefined ? 0 : data.idextra),
        idsucursal: String(data.idsucursal === undefined ? 0 : data.idsucursal),
        extra: data.extra === undefined ? '' : data.extra,
        precio: String(data.precio === undefined ? 0 : data.precio),
        telefono: data.telefono === undefined ? '' : data.telefono,
        requiereDist: String(data.requiereDist === undefined ? 0 : data.requiereDist),
        idusuario: String(data.idusuario === undefined ? 0 : data.idusuario),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar, 3 dar de baja)
      }
    });
    return this.getFormParam('extras/abcextraspanel', params);
  }


}
