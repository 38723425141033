export class PedidoModel {
    idpedido: number;
    idopenpay_cargo: string;
    idcliente_openpay: string;
    // tslint:disable-next-line: variable-name
    idusuario_tramita: number;
    idsucursal: number;
    idrepartidor: number;
    // tslint:disable-next-line: variable-name
    no_orden: string;
    // tslint:disable-next-line: variable-name
    dopenpay_cargo: number;
    // tslint:disable-next-line: variable-name
    idforma_pago: string;
    tienda: string;
    sucursal: string;
    // tslint:disable-next-line: variable-name
    nombre_repartidor: string;
    esinvitado: boolean;
    subtotal: string;
    descuento: string;
    iva: string;
    total: string;
    idusuario: number;
    idrol: number;
    operacion: number;
    precio: string;
    // tslint:disable-next-line: variable-name
    idestatus_pedido: number;
    // tslint:disable-next-line: variable-name
    estatus_pedido: string;
    fecha_entrega: string;
    fecha_pedido: string;
    direccion: string;
    telefono: string;
    envio: number;
    nombrecompleto: string;
    telefono1: string;
    telefono2: string;
    email: string;
    forma_pago: string;
}

export class RepartidorPedidoModel {
    idusuario: number;
    idrol: number;
    idrepartidor: number;
    repartidor: string;
    operacion: number;
    movil: string;
    // tslint:disable-next-line: variable-name
    ins_fecha: string;
    // tslint:disable-next-line: variable-name
    upd_fecha: string;
    // tslint:disable-next-line: variable-name
    ap_paterno: string;
    idsucursal: number;
    nombre: string;
    // tslint:disable-next-line: variable-name
    ap_materno: string;
    password: string;
    estatus: number;
    // tslint:disable-next-line: variable-name
    url_foto_perfil: string;
    telefono: string;
    email: string;
    username: string;
    forma_pago: string;

}

export class PedidoDetalleModel {
    idpedido: number;
}

export class PedidoEscanerCodigoModel {
    no_orden: string;
    idpedido: number;
}

export class PedidoEntregaModel {
    iddireccion: number;
    idestatus_pedido: number;
    idpedido: number;
    idsucursal: number;
    idusuario_tramita: number;
    idrepartidor: number;
    sucursal: string;
    direccion: string;
    no_orden: string;
    estatus_pedido: string;
    esinvitado: boolean;
    latitud_origen: number;
    longitud_origen: number;
    latitud_destino: number;
    longitud_destino: number;
    fecha_entrega: string;
    nombre_recibe: string;
    forma_pago: string;
}

export class PedidoRepartidorModel {
    iddireccion: number;
    idestatus_pedido: number;
    idpedido: number;
    idsucursal: number;
    idusuario_tramita: number;
    idrepartidor: number;
    direccion: string;
    no_orden: string;
    estatus_pedido: string;
    esinvitado: boolean;
    latitud: number;
    longitud: number;
    fecha_entrega: string;
    nombre_recibe: string;
    forma_pago: string;
}

export class GridModel{
    f_inicial: string;
    f_final: string;
}