import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { PedidoModel } from '../../models/pedido/pedido.model';
import { GridModel } from '../../models/pedido/pedido.model';
import { FormControl, Form } from '@angular/forms';

import { MatDialog} from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';

import { PedidoService } from '../../services/pedido.service';
import { PedidosAsigRepartidorComponent } from '../pedidos-asig-repartidor/pedidos-asig-repartidor.component';
import { PedidoDetalleComponent } from '../pedido-detalle/pedido-detalle.component';
import { PedidoBusquedaValidaComponent } from '../pedido-busqueda-valida/pedido-busqueda-valida.component';

import { timer } from 'rxjs';
import { PedidoTicketDialogComponent } from '../pedido-ticket-dialog/pedido-ticket-dialog.component';
import { PedidoEscanearCodigoComponent } from '../pedido-escanear-codigo/pedido-escanear-codigo.component';
import { stringify } from 'querystring';
import { PedidoEscanearCodigoDetalleComponent } from '../pedido-escanear-codigo-detalle/pedido-escanear-codigo-detalle.component';

import * as Notify from 'notifyjs';
import { PedidoEntregaMonitorDialogComponent } from '../pedido-entrega-monitor-dialog/pedido-entrega-monitor-dialog.component';
import { PedidosImprimirDialogComponent } from '../pedidos-imprimir-dialog/pedidos-imprimir-dialog.component';
import { formatDate } from '@angular/common';
import { PedidosCambiofechaDialogComponent } from '../pedidos-cambiofecha-dialog/pedidos-cambiofecha-dialog.component';
import { PedidoFechareportepastelesDialogComponent } from '../pedido-fechareportepasteles-dialog/pedido-fechareportepasteles-dialog.component';
import { DatosfacturacionDialogComponent } from '../datosfacturacion-dialog/datosfacturacion-dialog.component';
import { PedidoPendientePagoDialogComponent } from '../pedido-pendiente-pago-dialog/pedido-pendiente-pago-dialog.component';
import { PedidoAsignarWebcamComponent } from '../pedido-asignar-webcam/pedido-asignar-webcam.component';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css']
})

export class PedidosComponent implements OnInit {
  pedidosmodel: PedidoModel;
  gridmodel: GridModel;
  asignado: number;
  cosecutivo: number;
  enproceso: number;
  entregado: number;
  displayedColumns: string[] = [];
  datasource: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource: any;
   reloj = timer(1000, 60000);

  pedidosCargados: number;
  pedidosAnterioresCargados: number;

  subscribe: any;
  tabla: any[];
  fechaeentrega: any;
  idrol: number;

  estadoFilter = new FormControl('');
  fechaEntregaFilter = new FormControl('');
  numeroFilter = new FormControl('');
  repartidorFilter = new FormControl('');
  entregaEnFilter = new FormControl('');
  filterValues = {
    estado: '',
    fechaentrega: '',
    numero: '',
    repartidor: '',
    entregaen: ''
  };

  constructor(private dialog: MatDialog,
              public paginatorInt: MatPaginatorIntl,
              private pedidoservice: PedidoService,
              public datepipe: DatePipe) {
                const reloj = timer(1000, 60000);
                const subscribe = reloj.subscribe(val => {
                    this.calcularpedidos();

                });

               }

  ngOnInit(): void {
            this.pedidosmodel = new PedidoModel();
            this.gridmodel = new GridModel();
            this.pedidosmodel.idusuario = Number(sessionStorage.idu);
            this.pedidosmodel.idrol = Number(sessionStorage.idr);
            this.idrol = Number(sessionStorage.getItem('idr'));

            this.cosecutivo = 0;
            this.pedidosCargados = 0;
            this.pedidosAnterioresCargados = 0;
            this.asignado = 0;

            this.getGridPedidos();

            this.paginatorInt.firstPageLabel = 'Primera página';
            this.paginatorInt.lastPageLabel = 'Última página';
            this.paginatorInt.itemsPerPageLabel = 'Registro por página';
            this.paginatorInt.nextPageLabel     = 'Siguiente página';
            this.paginatorInt.previousPageLabel = 'Anterior página';

            this.estadoFilter.valueChanges
            .subscribe(
              estado => {
                this.filterValues.estado = estado;
                this.dataSource.filter = JSON.stringify(this.filterValues);
              }
            );
            this.fechaEntregaFilter.valueChanges
            .subscribe(
              fechaentrega => {
                this.filterValues.fechaentrega = fechaentrega;
                this.dataSource.filter = JSON.stringify(this.filterValues);
              }
            );
            this.numeroFilter.valueChanges
            .subscribe(
              numero => {
                this.filterValues.numero = numero;
                this.dataSource.filter = JSON.stringify(this.filterValues);
              }
            );
            this.repartidorFilter.valueChanges
            .subscribe(
              repartidor => {
                this.filterValues.repartidor = repartidor;
                this.dataSource.filter = JSON.stringify(this.filterValues);
              }
            );
            this.entregaEnFilter.valueChanges
            .subscribe(
              entregaen => {
                this.filterValues.entregaen = entregaen;
                this.dataSource.filter = JSON.stringify(this.filterValues);
              }
            );

  }

  

  createFilter(): (data: any, filter: string) => boolean {
    const filterFunction = function(data, filter): boolean {
      const searchTerms = JSON.parse(filter);
      return data.estatus_pedido.toString().toLowerCase().indexOf(searchTerms.estado) !== -1
        && data.fecha_entrega.toString().toLowerCase().indexOf(searchTerms.fechaentrega) !== -1
        && data.no_orden.toString().toLowerCase().indexOf(searchTerms.numero) !== -1
        && data.nombre_repartidor.toString().toLowerCase().indexOf(searchTerms.repartidor) !== -1
        && data.tipo_entrega.toString().toLowerCase().indexOf(searchTerms.entregaen) !== -1;
    };
    return filterFunction;
  }

  mostrarNotificaciones(titulo: string, mensaje: string, cantidad: number) {

    const  myNotification = new Notify(titulo, {
      body: 'HAY ' + '' + mensaje,
      notifyShow: onNotifyShow,
      icon: './../../assets/images/favicon.ico',
      image: './../../assets/images/favicon.gif',
      silent: false,
   //   vibrate: false,
      timestamp: 20000
    });

    function onNotifyShow() {
      //console.log('notificación mostrada!');
    }

    if (!Notify.needsPermission) {
      myNotification.show();
    } else if (Notify.isSupported()) {
        Notify.requestPermission(onPermissionGranted, onPermissionDenied);
    }

    function onPermissionGranted() {
      //console.log('Permiso concedido!!');
      myNotification.show();
    }

    function onPermissionDenied() {
      console.warn('Permiso denegado!!');
    }

    myNotification.close();

  }

  mostrarNotificacionesEntregados(titulo: string, mensaje: string, cantidad: number) {

    const  myNotificationEntregados = new Notify(titulo, {
      body: 'HAY ' + '' + mensaje,
     // body: 'HAY ' + String(cantidad) + '' + mensaje,
      notifyShow: onNotifyShow,
      icon: './../../assets/images/favicon.ico',
      image: './../../assets/images/favicon.gif',
      silent: false,
    //  vibrate: true,
      timestamp: 20000
    });

    function onNotifyShow() {
      //console.log('Notificación mostrada!');

    }

    if (!Notify.needsPermission) {
      myNotificationEntregados.show();
    } else if (Notify.isSupported()) {
        Notify.requestPermission(onPermissionGranted, onPermissionDenied);
    }

    function onPermissionGranted() {
      //console.log('Permiso concedido!!');
      myNotificationEntregados.show();
    }

    function onPermissionDenied() {
      console.warn('Permiso denegado!!');
    }

    myNotificationEntregados.close();

  }

  // tslint:disable-next-line: use-lifecycle-interface
  // ngOnDestroy() {
  //   setTimeout(() => { this.subscribe.unsubscribe(); });
  // }

  openDialogEscanearCodigoRespuesta(rows: any): void {
    if ( rows !== undefined ) {
      const dialogRef = this.dialog.open(PedidoEscanearCodigoDetalleComponent, {

        data: {titulo: 'Detalle del pedido',
          row: '63cb0f002a1b97a1b8725624c0610570',
        },
        disableClose: false,
        height: String(screen.height * 0.80),
        width: String(screen.width * 0.20),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       //  this.getGridPedidos();
      });
    }
  }


  accesoAsignarRepartidor(row) {
    if (row.idestatus_pedido == 8 || row.idestatus_pedido == 6 || row.idestatus_pedido == 3){
      this.asignado = 0;
      this.openDialogAsignarRepartidor(row);
      if (this.asignado === 1) {
       this.getGridPedidos();
      }
    } else {
      Swal.fire({
        icon: 'warning',
        text: 'El pastel no esta terminado'
       });
    }
  }

  accesoDetalle(row) {
     this.openDialogProducto(row);
  }

  accesoBusquedaGrid() {
    this.openDialogBusquedaValida();
 }

  openDialogBusquedaValida(): void {
  
    const dialogRef = this.dialog.open( PedidoBusquedaValidaComponent , {

      data: {titulo: 'Grid pedidos',
        row: '',
      },
      disableClose: true,
      height: String(screen.height * 0.80),
      width: String(screen.width * 0.20),
      panelClass: 'full-screen-modal'
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.getGridProductos();
    });
  }

  getColorEstatusRow(estatus: number) {
    let colorletra: string;
    switch (estatus) {
      case 2: { //En Proceso
        colorletra = '#896D24';
        break;
      }
      case 3: { // Pendiente de entrega
        colorletra = '#FC7905';
        break;
      }
      case 4: { // Entregado
        colorletra = '#391410';
        break;
      }
      case 5: { // Cancelado
        colorletra = '#FF0000';
        break;
      }
      case 6: { // En camino
        colorletra = '#00FF00';
        break;
      }
      case 7: { // No atendido
        colorletra = '#d01d17';
        break;
      }
      case 8: { // Terminado
        colorletra = '#0000FF';
        break;
      }
    }

    return colorletra;
  }


  getGridPedidos() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumns = ['idpedido', 'estatus_pedido', 'idopenpay_cargo', 'fecha_pedido', 'fecha_entrega','fecha_entrego', 'no_orden', 'nombre_repartidor', 'tipoentrega','subtotal','envio','iva','total', 'actions'];    
    this.gridmodel.f_inicial = this.gridmodel.f_inicial == undefined || this.gridmodel == undefined ? '' : this.gridmodel.f_inicial;
    this.gridmodel.f_final = this.gridmodel.f_final == undefined || this.gridmodel == undefined ? '' : this.gridmodel.f_final;
    this.pedidoservice.getPedidoGrid(this.pedidosmodel.idusuario, this.pedidosmodel.idrol, this.gridmodel).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.tabla = resp;
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de extras en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de extras en el servidor'
            });
            break;

      }
    });
  }

  openDialogEscanearCodigo(): void {
    
      const dialogRef = this.dialog.open(PedidoEscanearCodigoComponent, {

        data: {titulo: 'Escanear QR para terminar la entrega'
        },
        disableClose: false,
        height: String(screen.height * 0.80),
        width: String(screen.width * 0.20),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
         this.getGridPedidos();
      });
    
  }

  openDialogSeleccionarCamara(): void {
    
    const dialogRef = this.dialog.open(PedidoAsignarWebcamComponent, {

      data: {titulo: 'Buscar la webcam a utilizar.'
      },
      disableClose: false,
      height: String(screen.height * 0.80),
      width: String(screen.width * 0.20),
      panelClass: 'full-screen-modal'
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.getGridPedidos();
    });
  
}

  openDialogEnvioMonitor(rows: any): void {
    if ( rows !== undefined ) {
     // this.ngOnDestroy();
      const dialogRef = this.dialog.open(PedidoEntregaMonitorDialogComponent, {
        data: {titulo: 'entrega del pedido: ' + rows.no_orden,
          row: rows,
        },
        disableClose: true,
        height: String(screen.height * 0.50),
        width: String(screen.width * 0.50),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
         this.getGridPedidos();
      });
    }
  }



  getGridPedidosTimer() {
    this.displayedColumns = ['idpedido', 'estatus_pedido', 'idopenpay_cargo', 'no_orden', 'subtotal', 'descuento', 'iva', 'total', 'nombre_repartidor', 'fecha_entrega', 'tipo_entrega', 'nombre_recibe', 'envio', 'actions'];
    this.gridmodel.f_inicial = this.gridmodel.f_inicial == undefined || this.gridmodel == undefined ? '' : this.gridmodel.f_inicial;
    this.gridmodel.f_final = this.gridmodel.f_final == undefined || this.gridmodel == undefined ? '' : this.gridmodel.f_final;
    this.pedidoservice.getPedidoGrid(this.pedidosmodel.idusuario, this.pedidosmodel.idrol, this.gridmodel).subscribe( (resp: any[]) => {

          
          this.pedidosAnterioresCargados = this.pedidosCargados;

          // carga pedidos
          this.dataSource = new MatTableDataSource<any>(resp);
          this.dataSource.paginator = this.paginator;

          // pedidos nuevos
          this.pedidosCargados = resp.length;

          if ((this.pedidosCargados > 0 && this.pedidosAnterioresCargados > 0) && (this.pedidosCargados > this.pedidosAnterioresCargados)) {
            

            this.mostrarNotificaciones('MIS PEDIDOS DE I LOVE CHOCOLATE', ' PEDIDOS NUEVOS', this.pedidosCargados);            

          } else {

            this.enproceso = 0;
            this.entregado = 0;
            const i = 0;
            const total = resp.length;
            for (let i = 0; i < total; i++) {
                if (resp[i].idestatus_pedido === 2 ) {
                    this.enproceso++;
                }
                if (resp[i].idestatus_pedido === 4 ) {
                  this.entregado++;
              }

            }
            // pedidos en proceso
            if (this.enproceso > 0) {
              this.mostrarNotificaciones('MIS PEDIDOS DE I LOVE CHOCOLATE', ' PEDIDOS EN PROCESO', this.enproceso);
            }
          }

    }, (err) => {
      switch (err.status) {
      case 0:
          // Swal.fire({
          //   icon: 'warning',
          //   text: 'No hay conexión con el servidor'
          // });
          break;
       case 409:
        //  Swal.fire({
        //   icon: 'error',
        //   text: 'Error ' + err.status + ' para el grid de extras en el servidor'
        //  });
         break;
        case 500:
            // Swal.fire({
            //   icon: 'error',
            //   text: 'Error ' + err.status + ' para el grid de extras en el servidor'
            // });
            break;

      }
    });
  }

  calcularpedidos() {
    
    this.gridmodel.f_inicial = this.gridmodel.f_inicial == undefined || this.gridmodel == undefined ? '' : this.gridmodel.f_inicial;
    this.gridmodel.f_final = this.gridmodel.f_final == undefined || this.gridmodel == undefined ? '' : this.gridmodel.f_final;
    this.pedidoservice.getPedidoGrid(this.pedidosmodel.idusuario, this.pedidosmodel.idrol,this.gridmodel).subscribe( (resp: any[]) => {

      //console.log('carga grid timer');
      // pedidos anteriores
      this.pedidosAnterioresCargados = this.pedidosCargados;
      this.pedidosCargados = resp.length;



      if ((this.pedidosCargados > 0 && this.pedidosAnterioresCargados > 0) && (this.pedidosCargados > this.pedidosAnterioresCargados)) {
        // si hay nuevos pedidos recargar el grid
        this.mostrarNotificaciones('¡ATENCIÓN HAY NUEVOS PEDIDOS!', 'HAY PEDIDOS NUEVOS', this.pedidosCargados);
        this.getGridPedidos();
      } else {
        //console.log('No hay nuevos pedidos');
        this.enproceso = 0;
        this.entregado = 0;
        const i = 0;
        const total = resp.length;
        for (let i = 0; i < total; i++) {
            if (resp[i].idestatus_pedido === 2 ) {
                this.enproceso++;
            }
            if (resp[i].idestatus_pedido === 4 ) {
              this.entregado++;
          }

        }
        // pedidos en proceso
        if (this.enproceso > 0) {
          this.mostrarNotificaciones('MIS PEDIDOS DE I LOVE CHOCOLATE', ' PEDIDOS EN PROCESO', this.enproceso);
        }

        // pedidos entregados
        // if (this.entregado > 0) {
        //    this.mostrarNotificacionesEntregados('MIS ENTREGOS DE GRILL BROTHERS', ' PEDIDOS ENTREGADOS', this.entregado);
        // }
      }

    }, (err) => {
      switch (err.status) {
        case 0:
          // Swal.fire({
          //   icon: 'warning',
          //   text: 'No hay conexión con el servidor'
          // });
          break;
       case 409:
        //  Swal.fire({
        //   icon: 'error',
        //   text: 'Error ' + err.status + ' para el grid de extras en el servidor'
        //  });
         break;
        case 500:
            // Swal.fire({
            //   icon: 'error',
            //   text: 'Error ' + err.status + ' para el grid de extras en el servidor'
            // });
            break;

      }
    });

  }


  openDialogImprimir(rows): void {
    // if ( rows === undefined ) {
      const dialogRef = this.dialog.open( PedidosImprimirDialogComponent , {
        data: {titulo: 'Vista previa',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.90),
      });
      dialogRef.afterClosed().subscribe(result => {
       // this.getGridSucursal();
      });
    // }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tablaFilter(filterValue.trim().toLowerCase());
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  tablaFilter(cadena: string) {
    const tablatemp = [];
    for (let i=0; i < this.tabla.length; i++) {
        if (this.tabla[i].no_orden.trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].idpedido.toString().trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].subtotal.toString().trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].descuento.toString().trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].iva.toString().trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].total.toString().trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].nombre_repartidor.trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].fecha_entrega.toString().trim().toLowerCase().indexOf(cadena) > -1
         ) {
        tablatemp.push(this.tabla[i]);
      }
    }
    this.tabla = [];
    this.tabla = tablatemp;
    //console.log(tablatemp);
  }

  openDialogAsignarRepartidor(rows): void {
    if ( rows === undefined ) {
      const dialogRef = this.dialog.open( PedidosAsigRepartidorComponent , {

        data: {titulo: 'Repartidor asignado',
          row: '',
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridPedidos();
      });
    } else {
      const dialogRef = this.dialog.open( PedidosAsigRepartidorComponent , {
        data: {titulo: 'CAMBIAR A PENDIENTE DE PAGO',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.80),
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getGridPedidos();
      });
    }
  }

  reloadgrid() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.getGridPedidos();
    Swal.close();
  }

  openDialogTicket(rows: any, idestatus: number): void {
      const dialogRef = this.dialog.open( PedidoTicketDialogComponent , {
        data: {titulo: 'Ticket del pedido',
        row: rows,
        idestatus_pedido: idestatus
      },
      disableClose: true,
      height: String(screen.height * 0.80),
      width: String(screen.width * 0.20),
      });
      dialogRef.afterClosed().subscribe(result => {
       // this.getGridPedidos();
      });
    // }
  }

  openDialogFechaReportePasteles(): void {
    const dialogRef = this.dialog.open( PedidoFechareportepastelesDialogComponent , {
      data: {titulo: 'Fecha del reporte'
    },
    disableClose: true,
    height: String(screen.height * 0.90),
    width: String(screen.width * 0.90),
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  
  }

  opendialogcambiarDatosFacturacion(row): void {
    const dialogRef = this.dialog.open( DatosfacturacionDialogComponent , {
      data: {titulo: 'Actualización de los datos de facturación',
      row: row
    },
    disableClose: true,
    height: String(screen.height * 0.90),
    width: String(screen.width * 0.90),
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  
  }


  openDialogProducto(rows): void {
    if ( rows === undefined ) {
      const dialogRef = this.dialog.open( PedidoDetalleComponent , {

        data: {titulo: 'Agregar el producto',
          row: '',
        },
        disableClose: true,
        height: String(screen.height * 0.80),
        width: String(screen.width * 0.20),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
        // this.getGridProductos();
      });
    } else {
      const dialogRef = this.dialog.open( PedidoDetalleComponent , {
        data: {titulo: 'Actualizar el producto',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.80),
      width: String(screen.width * 0.20),
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  cambiarEstatusPedido(row,idestatus_pedido){
    Swal.fire({
      title: '¿Esta seguro de cambiar el estatus del pedido?',
      text: "Al cambiar el estatus ya no podrá regresarlo",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.pedidoservice.cambiarEstatusPedido(row, idestatus_pedido).subscribe( (resp: any[]) => {
          Swal.close();    
          Swal.fire({
            icon: 'success',
            text: 'Operación realizada.'
          });
    
          this.getGridPedidos();
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el cambio de estatus'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el cambio de estatus'
                  });
                  break;
            }
          });
      }
    })

    

  }

  UpdpedidoEntregado(row) {

    const numorden = row.no_orden;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Entregar el pedido ' + row.no_orden + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.pedidoservice.putPedidoEntragado(row).subscribe( (resp: any[]) => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            text: 'Operación realizada.'
          });

          this.getGridPedidos();
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del pedido'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del pedido'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'El pedido ' + numorden + ' ha sido entregada.',
            'success'
          );

      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La operación no fue realizada!',
          'warning'
        );
      }
    });

  }

  opencambiarFechaEntrega(rows): void {
    const dialogRef = this.dialog.open( PedidosCambiofechaDialogComponent , {
      data: {titulo: 'Cambio de fecha de entrega',
      row: rows
    },
    disableClose: true,
    height: String(screen.height * 0.90),
    width: String(screen.width * 0.90),
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getGridPedidos();
    });
  
}

  putNodisponible(row) {
    const numorden = row.no_orden;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Marcar el pedido ' + row.no_orden + ' como cancelado?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.pedidoservice.putPedidoNoDisponible(row).subscribe( (resp: any[]) => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            text: 'Operación realizada correctamente.'
          });

          this.getGridPedidos();
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el cancelado del pedido'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el cancelado del pedido'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'El pedido ' + numorden + ' ha sido cancelado.',
            'success'
          );

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La operación no fue realizada!',
          'warning'
        );
      }
    });

  }

  limpiarGrid() {
    this.dataSource = [];
    this.dataSource.paginator = [];
    this.gridmodel.f_final = '';
    this.gridmodel.f_inicial = '';
  }

  openDialogPendientePago(): void {
    const dialogRef = this.dialog.open( PedidoPendientePagoDialogComponent , {
      data: {titulo: 'Reporte de pedidos de pendiente pago'
    },
    disableClose: true,
    height: String(screen.height * 0.90),
    width: String(screen.width * 0.90),
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  
  }


  
}
