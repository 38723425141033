import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Dimensions,  ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ImageUploadComponent  } from 'angular2-image-upload/lib/image-upload/image-upload.component';

// servicios y modelos
import { ProductoModel, ProductoParamModel } from './../../models/producto/producto.model';
import { ProductoService } from '../../services/producto.service';
import { CombosService } from '../../services/combos.service';
import { ComboCategoriaModel, ComboPresentacionModel } from '../../models/producto/producto.model';

import { imageToBase64 } from 'image-to-base64';
import { FormGroup, Form, NgForm, FormControl, Validators, FormBuilder } from '@angular/forms';



@Component({
  selector: 'app-producto-dialog',
  templateUrl: './producto-dialog.component.html',
  styleUrls: ['./producto-dialog.component.css']
})
export class ProductoDialogComponent implements OnInit {
  pin: string | ArrayBuffer;

  Forma: NgForm;
  combotiendas: any[];
  comboobjetoimpuesto: any[];

  constructor(public dialogRef: MatDialogRef<ProductoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private productoservice: ProductoService,
              private comboservice: CombosService
              ) { }
  // imageCropper
  imageChangedEvent: any = '';
  imageChangedEventMin: any = '';

  croppedImage: any = '';
  croppedImageMin: any = '';

  // producto
  paramproductolmodel: ProductoParamModel;
  productomodel: ProductoModel;

  combocategorias: any[];
  combosucursales: any[];
  combopresentaciones: any[];

  fileContent: string = '';

  verImagenes: boolean;

  idcategoria: number;
  idpresentacion: number;

  idsucursal: number;
  idrol: number;

  extrasVisibles: boolean;


  ngOnInit(): void {
    this.productomodel = new ProductoModel();
    this.paramproductolmodel = new ProductoParamModel();

    this.verImagenes = false;
    this.idrol = Number(sessionStorage.getItem('idr'));
    this.idsucursal = Number(sessionStorage.getItem('ids'));
    this.productomodel.idproducto = 0;

   
    if (this.data.row.idproducto > 0) {
      this.productomodel = this.data.row;
      this.verImagenes = true;
      this.extrasVisibles = this.productomodel.tieneextras;
    } else {
      this.productomodel.idsucursal = this.idsucursal;
      this.extrasVisibles = false;
    }
    this.productomodel.entregainmediata = false;
    this.getComboTiendas();
    this.getComboObjetoImpuesto();
    
    this.productomodel.imagenb64 = '';
    this.productomodel.imagen_minb64 = '';

  }

  submit() {
    
    this.abcCatProducto();
    
  }

  abcCatProducto() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    this.productomodel.operacion = this.productomodel.idproducto > 0 ? 2 : 1;
    this.productomodel.idusuario = Number(sessionStorage.getItem('idu'));

    if (this.productomodel.operacion === 2) { // modificar registro
      this.productomodel.url_imagen = this.data.row.url_imagen;
      this.productomodel.url_imagen_miniatura = this.data.row.url_imagen_miniatura;
    }

    if (this.idrol > 1 ) {
      this.productomodel.idtienda = 1;
    }

    

    if (this.productomodel.estadisponible) {
      this.productomodel.estadisponible = true;
    } else {
        this.productomodel.estadisponible = false;
    }

    if (this.productomodel.enhome) {
      this.productomodel.enhome = true;
    } else {
        this.productomodel.enhome = false;
    }

    if (this.productomodel.aplicaiva) {
      this.productomodel.aplicaiva = true;
    } else {
        this.productomodel.aplicaiva = false;
    }

    if (this.productomodel.usaleyenda) {
      this.productomodel.usaleyenda = true;
    } else {
        this.productomodel.usaleyenda = false;
    }

    if (this.productomodel.tienecereza) {
      this.productomodel.tienecereza = true;
    } else {
        this.productomodel.tienecereza = false;
    }

    if (this.productomodel.tieneextras) {
      this.productomodel.tieneextras = true;
    } else {
        this.productomodel.tieneextras = false;
    }

    if (this.productomodel.tieneotrosextras) {
      this.productomodel.tieneotrosextras = true;
    } else {
        this.productomodel.tieneotrosextras = false;
    }

    if (this.productomodel.entregainmediata) {
      this.productomodel.entregainmediata = true;
    } else {
        this.productomodel.entregainmediata = false;
    }

    if (this.productomodel.ieps) {
      this.productomodel.ieps = true;
    } else {
        this.productomodel.ieps = false;
    }

    this.productoservice.abcProducto(this.productomodel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.productomodel.operacion === 1) { // nuevo registro
          this.productomodel.idproducto = resp['idrespuesta'] !== -1 || resp['idrespuesta'] !== -2 ? resp['idrespuesta'] : 0 ;

        }
        if (resp) {
          if (resp) {
            Swal.fire({
              icon: 'success',
              text: resp['respuesta'],
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           } else {
            Swal.fire({
              icon: 'error',
              text: resp['respuesta'],
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
           }
        }
        }, (err) => {
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor',
                confirmButtonColor: '#391410',
                confirmButtonText: 'ACEPTAR'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado del catálogo de productos',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado del catálogo de productos',
                  confirmButtonColor: '#391410',
                  confirmButtonText: 'ACEPTAR'
                });
                break;
          }
        });
  }

  getComboSucursal() {
    this.comboservice.getComboPanelSucursal(this.idsucursal, this.idrol).subscribe( (resp: any[]) => {
        this.combosucursales = resp;
        
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor',
            confirmButtonColor: '#391410',
            confirmButtonText: 'ACEPTAR'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor',
          confirmButtonColor: '#391410',
          confirmButtonText: 'ACEPTAR'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
            break;
      }
    });
  }

  getComboTiendas() {
    this.comboservice.getComboTiendas().subscribe( (resp: any[]) => {
        this.combotiendas = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor',
            confirmButtonColor: '#391410',
            confirmButtonText: 'ACEPTAR'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en combo tiendas ' + err.status + ' en el servidor',
          confirmButtonColor: '#391410',
          confirmButtonText: 'ACEPTAR'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en combo tiendas ' + err.status + ' en el servidor',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
            break;
      }
    });
  }


  getComboObjetoImpuesto() {
    this.comboservice.getComboObjetoImpuesto().subscribe( (resp: any[]) => {
        this.comboobjetoimpuesto = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor',
            confirmButtonColor: '#391410',
            confirmButtonText: 'ACEPTAR'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en combo objeto impuesto ' + err.status + ' en el servidor',
          confirmButtonColor: '#391410',
          confirmButtonText: 'ACEPTAR'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en combo objeto impuesto ' + err.status + ' en el servidor',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
            break;
      }
    });
  }

  onCheckboxChange(e) {

    if (e.target.checked) {
      this.extrasVisibles = true;
    } else {
      this.extrasVisibles = false;
      this.productomodel.tieneotrosextras = false;
      this.productomodel.tienecereza = false;
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  fileChangeEvent(event1: any): void {
    this.imageChangedEvent = event1;
    // this.imageChangedEvent.cropperStaticWidth	 = 500;
    // this.imageChangedEvent.cropperStaticHeight = 280;
   // this.productomodel.imagenb64 = imageToBase64(imageChangedEvent);
  }

  fileChangeEventMin(event2: any): void {
    this.imageChangedEventMin = event2;
  }

  imageCropped(event1: ImageCroppedEvent) {
      this.croppedImage = event1.base64;
      this.productomodel.imagenb64 = this.croppedImage;
      // this.croppedImage.cropperStaticWidth = 500;
      // this.croppedImage.cropperStaticHeight = 280;
     // this.croppedImage = event.base64;
    //  this.productomodel.imagenb64 = this.croppedImage;
  }

  imageCroppedMin(event2: ImageCroppedEvent) {
    // this.croppedImageMin = event.base64;
    // this.productomodel.imagen_minb64 = this.croppedImageMin;
    this.croppedImageMin = event2.base64;
    this.productomodel.imagen_minb64 = this.croppedImageMin;
   // event = null;
  //  this.productomodel.imagen_minb64 = this.croppedImageMin;
  }

  imageLoaded() {
      // show cropper
      // tslint:disable-next-line: deprecation
    //  event = null;
  }

  imageLoadedMIN() {
    // tslint:disable-next-line: deprecation
  //  event = null;
    // show cropper
  }

  cropperReady() {
      // cropper ready
      // this.productomodel.imagenb64 = this.croppedImage;
      
  }

  cropperReadyMIN() {
    // cropper ready
    // this.productomodel.imagen_minb64 = this.croppedImageMin;
    
  }

  loadImageFailed() {
      // show message
  }

  loadImageFailedMIN() {
      // show message
  }

  onUploadFinish(event: any) {

    
    const imagenb64 = event.src;
    this.productomodel.imagenb64 = String(imagenb64);
    

  }

  onUploadFinishMin(event: any) {

    
    const imagenb64MIN = event.src;
    this.productomodel.imagen_minb64 = String(imagenb64MIN);
    

  }

 

  uploadHotSpot($event) {
    const file: File = $event.target.files[0];
    const reader: FileReader = new FileReader();

    reader.onload = () => {
      this.pin = reader.result;
      
    };

    if (file) {
      reader.readAsDataURL(file); // reads the data as a URL
    }
}

}
