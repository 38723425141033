import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MustMatch } from '../../_helpers/must-Match.validator';
import Swal from 'sweetalert2';
import { Dimensions,  ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { NgForm } from '@angular/forms';


import { UsuarioGridModel } from '../../models/adminusuarios/adminusuarios.model';

import { UsuarioService } from '../../services/usuario.service';

import { CombosService } from '../../services/combos.service';

@Component({
  selector: 'app-usuarios-dialog',
  templateUrl: './usuarios-dialog.component.html',
  styleUrls: ['./usuarios-dialog.component.css']
})
export class UsuariosDialogComponent implements OnInit {

  // imageCropper
  imageChangedEvent: any = '';
  croppedImage: any = '';

  verImagenes: boolean;


  registerForm: FormGroup;
  submitted = false;

  usuarioGridModel: UsuarioGridModel;

  comboroles: any[];
  combosucursales: any[];

  idrolUsuario: number;
  idSucursalUsuario: number;
  idrol: number;
  combotiendas: any[];

  constructor(private formBuilder: FormBuilder,
              private usuarioService: UsuarioService,
              private comboService: CombosService,
              public dialogRef: MatDialogRef<UsuariosDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
      this.registerForm = this.formBuilder.group({
        perfil: [''],
        sucursal: [''],
        nombre: ['', Validators.required],
        ap_paterno: ['', Validators.required],
        ap_materno: [''],
        // tslint:disable-next-line: quotemark
        email: ['', [Validators.required, Validators.pattern("^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")]],
        username: [''],
        movil: ['', Validators.required],
        telefono: ['']
       // password: ['', [Validators.required, Validators.minLength(4)]],
        // confirmPassword: ['', Validators.required]
        // acceptTerms: [false, Validators.requiredTrue]
      }, {
        // validator: MustMatch('password', 'confirmPassword')
    });

      this.idrolUsuario = Number(sessionStorage.getItem('idr'));
      this.getComboRol();
      this.idSucursalUsuario = Number(sessionStorage.getItem('ids'));
      this.usuarioGridModel = new UsuarioGridModel();

      this.usuarioGridModel.idusuario = 0;
      this.usuarioGridModel.password = '';
      this.usuarioGridModel.url_foto_perfil = '';
      this.usuarioGridModel.estatus = 2;
      this.verImagenes = false;
      if (this.data.row.idusuario > 0) {
        this.usuarioGridModel = this.data.row;
        if (this.usuarioGridModel.url_foto_perfil !== '' ) {
          this.verImagenes = true;
        } else {
          this.verImagenes = false;
        }
      }

      this.usuarioGridModel.fotobase64 = '';
      if (this.idrolUsuario >= 2 ) {        
        this.getComboSucursalPanel();
      } else {
        this.getComboTienda();
        this.getComboSucursal(this.usuarioGridModel.idtienda == undefined ? 0 : this.usuarioGridModel.idtienda);
      }

      

  }
  getComboSucursal(idtienda: number) {
    this.comboService.getComboSucursal(idtienda).subscribe( (resp: any[]) => {
        this.combosucursales = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }
  getComboTienda() {
    this.comboService.getComboTiendas().subscribe( (resp: any[]) => {
        this.combotiendas = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor o revise su conexion a internet.',
            confirmButtonColor: '#391410',
            confirmButtonText: 'ACEPTAR'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor.',
          confirmButtonColor: '#391410',
          confirmButtonText: 'ACEPTAR'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor.',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
            break;
      }
    });
  }

  getComboRol() {
    this.comboService.getComboRol(this.idrolUsuario).subscribe( (resp: any) => {
        this.comboroles = resp;
        
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en roles ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en roles ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }

  getComboSucursalPanel() {
    this.comboService.getComboPanelSucursal(this.idSucursalUsuario, this.idrolUsuario).subscribe( (resp: any) => {
        this.combosucursales = resp;
      
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }


  abcCatUsuario(form: NgForm) {
    if ( form.valid  && this.usuarioGridModel.idrol >0 ) {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    this.usuarioGridModel.operacion = this.usuarioGridModel.idusuario > 0 ? 2 : 1;
    this.usuarioGridModel.idsucursal = this.idSucursalUsuario == 0 || this.idSucursalUsuario == 6 ? this.usuarioGridModel.idsucursal : this.idSucursalUsuario;

    if (this.usuarioGridModel.operacion === 2) { // modificar registro
      this.usuarioGridModel.url_foto_perfil = this.data.row.url_foto_perfil;
    }
    if (this.usuarioGridModel.operacion === 1) { // nuevo registro
      this.usuarioGridModel.username = this.usuarioGridModel.email;
    }

    if (this.idrol > 3 ) {
      this.usuarioGridModel.idsucursal = this.idSucursalUsuario;
    }

   
    this.usuarioService.abcPanelUsuario(this.usuarioGridModel).subscribe( (resp: any[]) => {
        Swal.close();
       // resp[0].idrespuesta
       // resp[0].respuesta
        if (this.usuarioGridModel.operacion === 1) { // nuevo registro
          this.usuarioGridModel.idusuario = resp['idrespuesta'] !== -1 || resp['idrespuesta'] !== -2 ? resp['idrespuesta'] : 0 ;
       //   this.productomodel.operacion = this.productomodel.operacion === 1 ? 2 : 2;
        }
        if (resp) {
        //  console.log(this.productomodel);
          if (resp['idrespuesta'] > 0) {
            Swal.fire({
              icon: 'success',
              text: resp['respuesta']
            });
            this.onNoClick();
           } else {
            Swal.fire({
              icon: 'error',
              text: resp['respuesta']
            });
           }
        }
        }, (err) => {
         Swal.close();
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor o verifique su conexión a internet.'
              });
              break;
              case 406:
                Swal.fire({
                 icon: 'error',
                 text: 'Usuario ya existe'
                });
                break; 
              case 404:
                Swal.fire({
                 icon: 'error',
                 text: 'Correo electrónico ya se registró con anterioridad'
                });
                break;   
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado del usuario.'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado del usuario.'
                });
                break;
          }
        });        
    } else {
      Object.values( form.controls ).forEach( control => {
        control.markAsTouched();
      });

      Swal.fire({
        icon: 'error',
        text: 'Faltan campos o campos invalidos'
       });
      
    }
  }

  

  onReset() {
      this.submitted = false;
      this.registerForm.reset();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  // para la fotografia

  fileChangeEvent(event1: any): void {
    this.imageChangedEvent = event1;
  }

  imageCropped(event1: ImageCroppedEvent) {
    this.croppedImage = event1.base64;
    this.usuarioGridModel.fotobase64 = this.croppedImage;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
}
  //  fin fotografia

}
