import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { AppSettings } from '../models/appsettings';
import { ProductoModel, ProductoParamModel, ProductosPresentacionModel, ProductosSucursalesModel, CatPresentacionesModel, ProductosCategoriaModel, ProductoIngredienteModel } from '../models/producto/producto.model';
import { ExtrasModel } from '../models/extra/extras.model';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {

  private header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  .append('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  private headerQP = new HttpHeaders().set('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  constructor(private http: HttpClient) { }

  getQueryParam( query: string) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.get(url, { headers: this.headerQP });
  }

  getFormParam( query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.header });
  }

  abcProducto(data: ProductoModel) {
    const params = new HttpParams({
      fromObject: {
        idproducto: String(data.idproducto === undefined ? 0 : data.idproducto),
        idtienda: String( data.idtienda === undefined ? 0 : data.idtienda),
        idsucursal: String( data.idsucursal === undefined ? 0 : data.idsucursal),
        nombre: data.nombre === undefined ? '' : data.nombre,
        orden: String(data.orden === undefined ? 0 : data.orden),
        descripcion: data.descripcion === undefined ? '' : data.descripcion,
        imagenb64: String( data.imagenb64 === undefined ? '' : data.imagenb64),
        url_imagen: data.url_imagen === undefined ? '' : data.url_imagen,
        imagen_minb64: String( data.imagen_minb64 === undefined ? '' : data.imagen_minb64),
        url_imagen_miniatura: data.url_imagen_miniatura === undefined ? '' : data.url_imagen_miniatura,
        estadisponible: String( data.estadisponible === undefined ? 0 : data.estadisponible), // (true disponible, false no disponible)
        entregainmediata: String( data.entregainmediata === undefined ? 0 : data.entregainmediata), // (true entrega inmediata, false sobre pedido)
        aplicaiva: String( data.aplicaiva === undefined ? 0 : data.aplicaiva), // (true aplica iva, false no aplica iva)
        ieps: String(data.ieps === undefined ? 0 : data.ieps),
        tieneextras: String( data.tieneextras === undefined ? 0 : data.tieneextras), // (true permite extras, false no permite extras)
        usaleyenda: String( data.usaleyenda === undefined ? 0 : data.usaleyenda), // (true usua leyenda, false no usa leyenda)
        enhome: String( data.enhome === undefined ? 0 : data.enhome), // (true aparece en el home, false no aparece en el home)
        tienecereza: String( data.tienecereza === undefined ? 0 : data.tienecereza), // (true tiene cereza, false no tiene cereza)
        tieneotrosextras: String( data.tieneotrosextras === undefined ? 0 : data.tieneotrosextras), // (true tiene otros extras, false tiene otros extras)
        idusuario: String( data.idusuario === undefined ? 0 : data.idusuario),
        operacion: String( data.operacion === undefined ? 0 : data.operacion), //  ( 1 insertar, 2 Actualizar, 3 dar de baja)
        objetoimpuesto: data.objetoimpuesto === undefined ? '' : data.objetoimpuesto
      }
    });
    return this.getFormParam('productos/agregarproducto', params);
  }

  getProductoDetalle(idproducto: number) {
    return this.getQueryParam('productos/detalleproducto?idproducto=' + idproducto);
  }

  getProductoGrid(idsucursal: number) {
    return this.getQueryParam('productos/productoscategoria?idsucursal=' + idsucursal);
  }

  getCategorias(idsucursal: number) {
    return this.getQueryParam('sucursal/categorias?idsucursal=' + idsucursal);
  }

  getProductosCategoria(idsucursal: number, idcategoria: number) {
    return this.getQueryParam('productos/productoscategoriaweb?idsucursal=' + idsucursal + '&idcategoria=' + idcategoria);
  }

  getCatProductoGrid(param: ProductoParamModel) {
    return this.getQueryParam('productos/leerproductospanel?idsucursal=' + param.idsucursal + '&idproducto=' + param.idproducto);
  }

  getComboCategoria(idtienda: number) {
    return this.getQueryParam('combos/categorias?idtienda=' + idtienda);
  }

  getComboPresentacion(idsucursal: number) {
    return this.getQueryParam('combos/presentaciones?idsucursal=' + idsucursal);
  }

  abcProductosPresentacion(data: ProductosPresentacionModel) {
    const params = new HttpParams({
      fromObject: {
        idproductos_presentacion: String(data.idproductos_presentacion === undefined ? 0 : data.idproductos_presentacion),
        idproducto: String(data.idproducto === undefined ? 0 : data.idproducto),
        idpresentacion: String( data.idpresentacion === undefined ? 0 : data.idpresentacion),
        idcategoria: String( data.idcategoria === undefined ? 0 : data.idcategoria),
        precio_normal: String(data.precio_normal === undefined ? 0 : data.precio_normal),
        precio_promocion: String(data.precio_promocion === undefined ? 0 : data.precio_promocion),
        estadisponible: String( data.estadisponible === undefined ? 0 : data.estadisponible), // (true disponible, false no disponible)
        entregainmediata: String( data.entregainmediata === undefined ? 0 : data.entregainmediata), // (true con entrega inmediata, false sin entrega inmediata)
        solosucursal: String( data.solosucursal === undefined ? 0 : data.solosucursal), // (true con entrega en sucursal, false sin entrega sucursal)
        formatradicional: String( data.formatradicional === undefined ? 0 : data.formatradicional),
        formacorazon: String( data. formacorazon === undefined ? 0 : data.formacorazon),
        formarectangular: String (data.formarectangular === undefined ? 0 : data.formarectangular),
        idusuario: String( data.idusuario === undefined ? 0 : data.idusuario),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar, 3 dar de baja)
      }
    });
    return this.getFormParam('productos/agregarproductospresenpanel', params);
  }

  getProductosPresentacion(idproducto: number, idsucursal: number) {
    return this.getQueryParam('productos/leerproductospresensacionpanel?idproducto=' + idproducto + '&idsucursal=' + idsucursal);
  }

  abcProductosSucursales(data: ProductosSucursalesModel) {
    const params = new HttpParams({
      fromObject: {
        idproductos_sucursales: String(data.idproductos_sucursales === undefined ? 0 : data.idproductos_sucursales),
        idsucursal: String( data.idsucursal === undefined ? 0 : data.idsucursal),
        idproducto: String(data.idproducto === undefined ? 0 : data.idproducto),
        estaactivo: String( data.estaactivo === undefined ? 0 : data.estaactivo),
        idusuario: String( data.idusuario === undefined ? 0 : data.idusuario),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar, 3 dar de baja)
       // idtienda: String( data.idtienda === undefined ? 0 : data.idtienda),
      }
    });
    return this.getFormParam('productos/agregarproductossucursales', params);
  }

  getProductosSucursal(idtienda: number, idproducto: number) {
    return this.getQueryParam('productos/leerproductossucursalpanel?idtienda=' + idtienda + '&idproducto=' + idproducto);
  }

  // servicios presentaciones

  // getGridCatIngredientes(idusuario: number, idrol: number) {
  //   return this.getQueryParam('productos/leerpresentacionespanel?idusuario=' + idusuario + '&idrol=' + idrol);
  // }

  getGridPresentaciones(idusuario: number, idrol: number) {
    return this.getQueryParam('presentaciones/leerpresentacionespanel?idusuario=' + idusuario + '&idrol=' + idrol);
  }

  getGridCatIngredientes(idusuario: number, idrol: number) {
    return this.getQueryParam('presentaciones/leerpresentacionespanel?idusuario=' + idusuario + '&idrol=' + idrol);
  }

  abcCatIngredientes(data: CatPresentacionesModel) {
    const params = new HttpParams({
      fromObject: {
        idpresentacion: String(data.idpresentacion === undefined ? 0 : data.idpresentacion),
        idsucursal: String( data.idsucursal === undefined ? 0 : data.idsucursal),
        presentacion: data.presentacion === undefined ? '' : data.presentacion,
        estadisponible: String(data.estadisponible === undefined ? 0 : data.estadisponible),
        entregainmediata: String( data.entregainmediata === undefined ? 0 : data.entregainmediata),
        personas: data.personas === undefined ? '' : data.personas,
        idusuario: String( data.idusuario === undefined ? 0 : data.idusuario),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar)
      }
    });
    return this.getFormParam('presentaciones/agregarcatalogopresentacion', params);
  }

  // servicios presentaciones productos

  getComboIngredientes(idtipoingrediente: number) {
    return this.getQueryParam('productos/?idtipoingrediente=' + idtipoingrediente);
  }
  

    // servicios asignar la categoria al producto

    abcProductosCategoria(data: ProductosCategoriaModel) {
      const params = new HttpParams({
        fromObject: {
          idproductos_categoria: String(data.idproductos_categoria === undefined ? 0 : data.idproductos_categoria),
          idproducto: String( data.idproducto === undefined ? 0 : data.idproducto),
          idcategoria: String( data.idcategoria === undefined ? 0 : data.idcategoria),
          estadisponible: String(data.estadisponible === undefined ? 0 : data.estadisponible),
          idusuario: String( data.idusuario === undefined ? 0 : data.idusuario),
          operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar)
        }
      });
      return this.getFormParam('productos/agregarproductoscategoriapanel', params);
    }

    getGridProductosCategoria(idproducto: number) {
      return this.getQueryParam('productos/leerproductoscategoriaspanel?idproducto=' + idproducto);
    }

    // servicios asignar ingredientes al producto

    abcProductosIngrediente(data: ProductoIngredienteModel) {
      const params = new HttpParams({
        fromObject: {
          idingredientes: String(data.idingredientes === undefined ? 0 : data.idingredientes),
          idproducto: String( data.idproducto === undefined ? 0 : data.idproducto),
          idpanes_sucursales: String( data.idpanes_sucursales === undefined ? 0 : data.idpanes_sucursales),
          idrellenos_sucursales: String( data.idrellenos_sucursales === undefined ? 0 : data.idrellenos_sucursales),
          idcobertura_sucursales: String(data.idcobertura_sucursales === undefined ? 0 : data.idcobertura_sucursales),
          idusuario: String( data.idusuario === undefined ? 0 : data.idusuario),
          operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar)
        }
      });
      return this.getFormParam('productos/abcingredienteproducto', params);
    }

    getGridProductosIngrediente(idproducto: number) {
      return this.getQueryParam('productos/leergridingredienteproducto?idproducto=' + idproducto);
    }

    getComboPan(idtipoingrediente: number) {
      return this.getQueryParam('productos/?idtipoingrediente=' + idtipoingrediente);
    }
    getComboRelleno(idtipoingrediente: number) {
      return this.getQueryParam('productos/?idtipoingrediente=' + idtipoingrediente);
    }
    getComboCobertura(idtipoingrediente: number) {
      return this.getQueryParam('productos/?idtipoingrediente=' + idtipoingrediente);
    }



}
