import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import { UsuarioService } from '../../services/usuario.service';
import {MatDialogRef, MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-usuariorecuperacontra',
  templateUrl: './usuariorecuperacontra.component.html',
  styleUrls: ['./usuariorecuperacontra.component.css']
})
export class UsuariorecuperacontraComponent implements OnInit {

  pass1: string;
  pass2: string;
  idusuario: number;
  formulario: FormGroup;
  submitted = false;

  dialogRef: MatDialogRef<Usuariorecuperacontradialog>;

  constructor(private servicioUsuario: UsuarioService,
              private _route: ActivatedRoute,
              private formBuilder: FormBuilder,
              public dialog: MatDialog) { }

  ngOnInit(): void {
   // this.password = '';
   // this.rpassword = '';
    this.idusuario = Number(this._route.snapshot.paramMap.get('userId'));

    this.formulario = this.formBuilder.group({
      pass1: ['', Validators.required],
      pass2: ['', Validators.required],
    }, {
      validators: this.servicioUsuario.validaPasswordIguales('pass1', 'pass2')
      // los parametros de esta funcion recibe como parametros los validator pass1 y pass2
    });

  }

  // variable para acceder a los campos del formulario
  get f() { return this.formulario.controls; }

  // función que regresa el resultado del Validator
  get passwordNoValido() {
    return this.formulario.get('pass1').invalid;
  }

  get rpasswordNoValido() {

    // return this.formulario.get('pass2').invalid;
     const pass1 = this.formulario.get('pass1').value;
     const pass2 = this.formulario.get('pass2').value;
     return ( pass1 === pass2 ) ? false : true;
  }


  onSubmit() {

    this.submitted = true;
    // se detiene si el formulario es invalido no ejecuta el servicio
    if (this.formulario.invalid) {
        return;
    }
  //  this.password = this.formulario.get('password').value;
  //  this.rpassword = this.formulario.get('rpassword').value;
    this.cambiarPassword();

  }


  // form: NgForm
  cambiarPassword() {
     // if ( form.invalid ) { return; }
    //  Swal.fire({
    //   allowOutsideClick: false,
    //   icon: 'info',
    //   text: 'Solicitud en proceso ...'
    //   });
    //  Swal.showLoading();

     this.openDialogSpinner('Solicitud en proceso');
    // console.log('entro');
     this.servicioUsuario.cambiarPassword(this.idusuario, this.pass2).subscribe( (resp: any) => {
     this.closeDialog();
     this.openDialog('Cambio realizado, ahora puedes acceder con la nueva contraseña');
      //  console.log(this.idusuario);
    //  console.log(this.password);
      // if (resp) {
    //  Swal.close();
     // this.openDialog();
      // Swal.fire({
      //     title: 'Cambio de contraseña realizado correctamente',
      //     icon: 'success',
      //     text: '¡Ahora puedes acceder a tu cuenta con la nueva contraseña!',
      //     confirmButtonText: 'Aceptar',
      //     confirmButtonColor: '#391410'
      //   });
    //  }
     }, (err) => {
     // Swal.close();
      this.closeDialog();
      switch (err.status) {
        case 0:
            // this.openDialogErrorServidor('No hay conexión con el servidor');
              // Swal.fire({
              //   icon: 'warning',
              //   text: 'No hay conexión con el servidor'
              // });
              this.openDialog('No hay conexión con el servidor o verifique su conexión a internet.');
              break;
            case 403:
              // this.openDialogError('No se puede cambiar por que el usuario no ha sido confirmado');
              //  Swal.fire({
              //    icon: 'warning',
              //    text: 'No se puede cambiar por que el usuario no ha sido confirmado'
              //  });
               this.openDialog('No se puede cambiar por que el usuario no ha sido confirmado.');
               break;
             case 406:
                // this.openDialogError('No se puede cambiar por que el usuario ha sido dado de baja');
                //  Swal.fire({
                //    icon: 'warning',
                //    text: 'No se puede cambiar por que el usuario ha sido dado de baja'
                //  });
                 this.openDialog('No se puede cambiar por que el usuario ha sido dado de baja.');
                 break;
         case 409:
                // Swal.fire({
                // icon: 'error',
                // text: 'Error ' + err.status + ' de la solicitud de contraseña en el servidor'
                // });
                this.openDialog('Error ' + err.status + ' de la solicitud de contraseña en el servidor');
                break;
          case 500:

              // Swal.fire({
              //   icon: 'error',
              //   text: 'Error ' + err.status + ' de la solicitud de contraseña en el servidor'
              // });
              this.openDialog('Error ' + err.status + ' de la solicitud de contraseña en el servidor');
              break;
        }
      });
  }

    openDialogSpinner(msj: string) {
      this.dialogRef = this.dialog.open(Usuariorecuperacontradialog, {
        disableClose: false,
        data: {titulo: 'Atencion',
            row: {mensaje: msj, titulo: 'Respuesta', visible: false},
        }
      }
      );
      this.dialogRef.beforeClosed().subscribe((result: string) => {
        console.log('Antes de cerrar el dialog', result);
      });
      this.dialogRef.afterClosed().subscribe(result => {
        console.log('Despues de cerrar el dialog', result);
      });
    }

    openDialog(msj: string) {
      this.dialogRef = this.dialog.open(Usuariorecuperacontradialog, {
        disableClose: false,
        data: {titulo: 'Atencion',
            row: {mensaje: msj, titulo: 'Respuesta', visible: true},
        }
      }
      );
      this.dialogRef.beforeClosed().subscribe((result: string) => {
        console.log('RIght before close,', result);
      });
      this.dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
      });
    }

    closeDialog() {
      this.dialogRef.close(true);
    }

}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'Usuariorecuperacontradialog',
  templateUrl: 'usuariorecuperacontradialog.html',
  styleUrls: ['./usuariorecuperacontra.component.css']
})

// tslint:disable-next-line: component-class-suffix
export class Usuariorecuperacontradialog implements OnInit {
  visible: boolean;
  imagenlogo: string;
  formulario: FormGroup;

  constructor(private servicioUsuario: UsuarioService,
              private _route: ActivatedRoute,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<Usuariorecuperacontradialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  mensaje: string;
  titulo: string;

  ngOnInit(): void {
    this.mensaje = this.data.row.mensaje;
    this.titulo = this.data.row.titulo;
    this.visible = this.data.row.visible;
    this.imagenlogo = './assets/images/logo.gif';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
