import { Component, OnInit, SecurityContext } from '@angular/core';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-legales',
  templateUrl: './legales.component.html',
  styleUrls: ['./legales.component.css']
})
export class LegalesComponent implements OnInit {

  // srcpdf = 'https://en.ferntech.net/controlGestion/proyecto1/FOLIO20-2018/ORI2018-20.pdf';
  // srcpdf = '../../assets/AVISO-LEGAL.pdf';

  srcpdf: any;

  pdfSource = 'https://en.ferntech.net/ilovechocolate/aviso/AvisodePrivacidad.pdf';
  safeUrl: SafeUrl;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {

    this.srcpdf = this.sanitizer.bypassSecurityTrustResourceUrl('https://en.ferntech.net/ilovechocolate/aviso/AvisodePrivacidad.pdf');
    //  this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://en.ferntech.net/ilovechocolate/aviso/AvisodePrivacidad.pdf');
   //   this.safeUrl = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfSource));
    // this.srcpdf = '../../assets/legales.pdf';

  }

}
