import { Component, OnInit, ViewChild } from '@angular/core';
import { DianolaboralModel, ParamDianolaboralModel } from './../../models/dianolaboral/dianolaboral.model'

import { MatDialog} from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';

import { DianolaboralService } from '../../services/dianolaboral.service';
import { DianolaboralDialogComponent } from '../dianolaboral-dialog/dianolaboral-dialog.component';

@Component({
  selector: 'app-dianolaboral',
  templateUrl: './dianolaboral.component.html',
  styleUrls: ['./dianolaboral.component.css']
})
export class DianolaboralComponent implements OnInit {
  dianolaboralmodel: DianolaboralModel;
  paramdianolaboralmodel: ParamDianolaboralModel;
  displayedColumns: string[] = [];
  datasource: any;
  tabla: any[];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;

  constructor(private dianolaboralservice: DianolaboralService,
              private dialog: MatDialog,
              public paginatorInt: MatPaginatorIntl) { }

  ngOnInit(): void {
    this.dianolaboralmodel = new DianolaboralModel();
    this.paramdianolaboralmodel = new ParamDianolaboralModel();
    this.paramdianolaboralmodel.idsucursal = Number(sessionStorage.ids);
    this.paramdianolaboralmodel.idrol = Number(sessionStorage.idr);
    this.getGridDiasnolaboral();
    this.paginatorInt.firstPageLabel = 'Primera página';
    this.paginatorInt.lastPageLabel = 'Última página';
    this.paginatorInt.itemsPerPageLabel = 'Registro por página';
    this.paginatorInt.nextPageLabel     = 'Siguiente página';
    this.paginatorInt.previousPageLabel = 'Anterior página';
  }

  reloadgrid() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.getGridDiasnolaboral();
    Swal.close();
  }

  acceso(  idmodulo: number, idsubmodulo: number, row) {
    this.openDialogDiasnolaboral(row);
  }

  getGridDiasnolaboral() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumns = ['iddiafestivo', 'activo', 'diafestivocorto', 'descripcion', 'actions'];
    this.dianolaboralservice.getDianolaboralCatalogoGrid(this.paramdianolaboralmodel).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.tabla = resp;
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de dias no laborales en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de dias no laborales en el servidor'
            });
            break;

      }
    });
  }

  openDialogDiasnolaboral(rows): void {
    if ( rows === undefined ) {
      const dialogRef = this.dialog.open( DianolaboralDialogComponent , {

        data: {titulo: 'Agregar la fecha no laboral',
          row: '',
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridDiasnolaboral();
      });
    } else {
      const dialogRef = this.dialog.open( DianolaboralDialogComponent , {
        data: {titulo: 'Actualizar Fecha no laboral',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.80),
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getGridDiasnolaboral();
      });
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tablaFilter(filterValue.trim().toLowerCase());
    this.dataSource.filter = filterValue.trim().toLowerCase();
  
  }

  tablaFilter(cadena: string) {

    // llenar la tabla para el reporte
    // const cadena = filterValue.trim().toLowerCase();
    const tablatemp = [];
    for (let i=0; i < this.tabla.length; i++) {
      if (this.tabla[i].diafestivo.trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].descripcion.trim().toLowerCase().indexOf(cadena) > -1 
         ) {
        tablatemp.push(this.tabla[i]);
      }
    }
    this.tabla = [];
    this.tabla = tablatemp;
    console.log(tablatemp);

  }

  UpdBajaDiasnolaboral(UsuarioRow: any) {
    this.bajaDiasnolaboral(UsuarioRow);
  }

  bajaDiasnolaboral(row: any) {

    const username = row.descripcion;

    // operacion
    // 1. insertar
    // 2. actualizar
    // 3. dar de baja
    // 4. restaurar usuario

    row.operacion = 3;
    row.idusuario = Number(sessionStorage.idu);
    // console.log(row);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Dar de baja ' + username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.dianolaboralservice.abcDianolaboral(row).subscribe( (resp: any[]) => {
          Swal.close();

          // Swal.fire({
          //   icon: 'success',
          //   text: 'El extra ' + username + ' ha sido dado de baja.'
          // });

          // si regresa respuesta el servicio
          if (resp) {

            if (resp[0].idrespuesta > 0) {
              Swal.fire({
                icon: 'success',
                text: resp[0].respuesta
              });
              this.getGridDiasnolaboral();
              } else {
              Swal.fire({
                icon: 'error',
                text: resp[0].respuesta
              });
              }
          }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del panel de dias no laborales'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del panel de dias no laborales'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'El dia ' + username + ' ha sido dada de baja.',
            'success'
          );

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La baja no fue realizada!',
          'warning'
        );
      }
    });

  }

  updRestaurarDiasnolaboral(row: any) {

    this.restaurarDiasnolaboral(row);

  }

  restaurarDiasnolaboral(row: any) {

    const username = row.descripcion;

    // operacion
    // 1. insertar
    // 2. actualizar
    // 3. dar de baja
    // 4. restaurar usuario

    row.operacion = 4;
    row.idusuario = Number(sessionStorage.idu);
    // console.log(row);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Restaurar ' + username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.dianolaboralservice.abcDianolaboral(row).subscribe( (resp: any[]) => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            text: 'El dia ' + username + ' ha sido restaurado.'
          });

          this.getGridDiasnolaboral();

          // si regresa respuesta el servicio
          // if (resp) {

          //   if (resp['idrespuesta'] > 0) {
          //     Swal.fire({
          //       icon: 'success',
          //       text: resp['respuesta']
          //     });
          //     this.getGridExtras();
          //     } else {
          //     Swal.fire({
          //       icon: 'error',
          //       text: resp['respuesta']
          //     });
          //     }
          // }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del panel de dias no laborales'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del panel de dias no laborales'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'El dia ' + username + ' ha sido restaurado.',
            'success'
          );

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La restauración no fue realizada!',
          'warning'
        );
      }
    });

  }

}
