// modulos del sistema //
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
// import { imageToBase64 } from 'image-to-base64';

// angular material

import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatRippleModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatCardModule } from '@angular/material/card';
import { ImageUploadModule  } from 'angular2-image-upload';
import { NgxPrintModule } from 'ngx-print';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
//import { NgxPrinterModule } from 'ngx-printer/lib/ngx-printer.module.d';

// api de google map
import { AgmCoreModule } from '@agm/core';

// rutas de la app

import { AppRoutingModule } from './app-routing.module';
import { APP_ROUTING } from './app.routes';


// modulos de usuario //

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ProductodetalleComponent } from './components/productodetalle/productodetalle.component';
import { PanelAdminComponent } from './components/panel-admin/panel-admin.component';
import { CategoriasComponent } from './components/categorias/categorias.component';
import { CategoriasDialogComponent } from './components/categorias-dialog/categorias-dialog.component';
import { SucursalComponent } from './components/sucursal/sucursal.component';
import { SucursalDialogComponent } from './components/sucursal-dialog/sucursal-dialog.component';
import { ProductoComponent } from './components/producto/producto.component';
import { ProductoDialogComponent } from './components/producto-dialog/producto-dialog.component';
import { BannerComponent } from './components/banner/banner.component';
import { BannerDialogComponent } from './components/banner-dialog/banner-dialog.component';
import { ExtrasComponent } from './components/extras/extras.component';
import { ExtrasDialogComponent } from './components/extras-dialog/extras-dialog.component';
import { LoginComponent } from './components/login/login.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { NavbarPanelComponent } from './components/navbar/navbarpanel.component';
import { PedidosComponent } from './components/pedidos/pedidos.component';
import { importType } from '@angular/compiler/src/output/output_ast';
import { PedidosAsigRepartidorComponent } from './components/pedidos-asig-repartidor/pedidos-asig-repartidor.component';
import { LoginPanelComponent } from './components/login-panel/login-panel.component';
import { PedidoDetalleComponent } from './components/pedido-detalle/pedido-detalle.component';
import { EstatuspedidoPipe } from './pipes/estatuspedido.pipe';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { UsuariosDialogComponent } from './components/usuarios-dialog/usuarios-dialog.component';
import { TiendaComponent } from './components/tienda/tienda.component';
import { TiendaDialogComponent } from './components/tienda-dialog/tienda-dialog.component';
import { VentasComponent, DD_MM_YYYY_Format } from './components/ventas/ventas.component';
import { UsuariorecuperacontraComponent } from './components/usuariorecuperacontra/usuariorecuperacontra.component';
import { UsuarioSoliRecuperaContraComponent } from './components/usuario-soli-recupera-contra/usuario-soli-recupera-contra.component';
import { UsuarioconfirmacionComponent, Usuarioconfirmaciondialog } from './components/usuarioconfirmacion/usuarioconfirmacion.component';
import { TerminosComponent } from './components/terminos/terminos.component';
import { LegalesComponent } from './components/legales/legales.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PedidoTicketDialogComponent } from './components/pedido-ticket-dialog/pedido-ticket-dialog.component';
import { PedidoEscanearCodigoComponent } from './components/pedido-escanear-codigo/pedido-escanear-codigo.component';
import { PedidoEscanearCodigoDetalleComponent } from './components/pedido-escanear-codigo-detalle/pedido-escanear-codigo-detalle.component';
import { ProductospresentacionDialogComponent } from './components/productospresentacion-dialog/productospresentacion-dialog.component';
import { ProductossucursalDialogComponent } from './components/productossucursal-dialog/productossucursal-dialog.component';
import { ProductosingredientesDialogComponent } from './components/productosingredientes-dialog/productosingredientes-dialog.component';
import { PresentacionesDialogComponent } from './components/presentaciones-dialog/presentaciones-dialog.component';
import { PresentacionesComponent } from './components/presentaciones/presentaciones.component';
import { ProductoscategoriasDialogComponent } from './components/productoscategorias-dialog/productoscategorias-dialog.component';
import { EnviocostoComponent } from './components/enviocosto/enviocosto.component';
import { EnviocostoDialogComponent } from './components/enviocosto-dialog/enviocosto-dialog.component';
import { EnviomonitorDialogComponent } from './components/enviomonitor-dialog/enviomonitor-dialog.component';
import { PedidoEntregaMonitorDialogComponent } from './components/pedido-entrega-monitor-dialog/pedido-entrega-monitor-dialog.component';
import { IngredientesComponent } from './components/ingredientes/ingredientes.component';
import { IngredientePanDialogComponent } from './components/ingrediente-pan-dialog/ingrediente-pan-dialog.component';
import { IngredienteCubiertaDialogComponent } from './components/ingrediente-cubierta-dialog/ingrediente-cubierta-dialog.component';
import { IngredienteRellenoDialogComponent } from './components/ingrediente-relleno-dialog/ingrediente-relleno-dialog.component';
import { IngredientePanSucursalDialogComponent } from './components/ingrediente-pan-sucursal-dialog/ingrediente-pan-sucursal-dialog.component';
import { IngredienteRellenoSucursalDialogComponent } from './components/ingrediente-relleno-sucursal-dialog/ingrediente-relleno-sucursal-dialog.component';
import { IngredienteCubiertaSucursalDialogComponent } from './components/ingrediente-cubierta-sucursal-dialog/ingrediente-cubierta-sucursal-dialog.component';
import { IngredientePanPresentacionDialogComponent } from './components/ingrediente-pan-presentacion-dialog/ingrediente-pan-presentacion-dialog.component';
import { SucursalImprimirDialogComponent } from './components/sucursal-imprimir-dialog/sucursal-imprimir-dialog.component';
import { BannerImprimirDialogComponent } from './components/banner-imprimir-dialog/banner-imprimir-dialog.component';
import { TiendaImprimirDialogComponent } from './components/tienda-imprimir-dialog/tienda-imprimir-dialog.component';
import { CategoriasImprimirDialogComponent } from './components/categorias-imprimir-dialog/categorias-imprimir-dialog.component';
import { PresentacionesImprimirDialogComponent } from './components/presentaciones-imprimir-dialog/presentaciones-imprimir-dialog.component';
import { ProductoImprimirDialogComponent } from './components/producto-imprimir-dialog/producto-imprimir-dialog.component';
import { ExtrasImprimirDialogComponent } from './components/extras-imprimir-dialog/extras-imprimir-dialog.component';
import { PedidosImprimirDialogComponent } from './components/pedidos-imprimir-dialog/pedidos-imprimir-dialog.component';
import { VentasprodImprimirDialogComponent } from './components/ventasprod-imprimir-dialog/ventasprod-imprimir-dialog.component';
import { EnviocostoImprimirDialogComponent } from './components/enviocosto-imprimir-dialog/enviocosto-imprimir-dialog.component';
import { AvisoprivacidadComponent } from './components/avisoprivacidad/avisoprivacidad.component';
import { TerminoscondicionesComponent } from './components/terminoscondiciones/terminoscondiciones.component';
import { TerminosdeliveryComponent } from './components/terminosdelivery/terminosdelivery.component';
import { AvisodeliveryComponent } from './components/avisodelivery/avisodelivery.component';
import { PedidosCambiofechaDialogComponent } from './components/pedidos-cambiofecha-dialog/pedidos-cambiofecha-dialog.component';
import { PedidoReportepastelesDialogComponent } from './components/pedido-reportepasteles-dialog/pedido-reportepasteles-dialog.component';
import { PedidoFechareportepastelesDialogComponent } from './components/pedido-fechareportepasteles-dialog/pedido-fechareportepasteles-dialog.component';
import { DatosfacturacionDialogComponent } from './components/datosfacturacion-dialog/datosfacturacion-dialog.component';
import { PagoexitosoComponent } from './components/pagoexitoso/pagoexitoso.component';
import { PagorespuestaComponent } from './components/pagorespuesta/pagorespuesta.component';
import { PagorechazadoComponent } from './components/pagorechazado/pagorechazado.component';
import { PedidoBusquedaValidaComponent } from './components/pedido-busqueda-valida/pedido-busqueda-valida.component';
import { DianolaboralComponent } from './components/dianolaboral/dianolaboral.component';
import { DianolaboralDialogComponent } from './components/dianolaboral-dialog/dianolaboral-dialog.component';
import { PedidoPendientePagoDialogComponent } from './components/pedido-pendiente-pago-dialog/pedido-pendiente-pago-dialog.component';
import { EstatusPedidoDetalleComponent } from './components/estatus-pedido-detalle/estatus-pedido-detalle.component';
import { ResumenVentasComponent } from './components/resumen-ventas/resumen-ventas.component';
import { ResumenVentasReporteComponent } from './components/resumen-ventas-reporte/resumen-ventas-reporte.component';
import { SucursalFechaEntregaDomDesacDialogComponent } from './components/sucursal-fecha-entrega-dom-desac-dialog/sucursal-fecha-entrega-dom-desac-dialog.component';
import { SucursalFechaEntregaDomDesacAbcDialogComponent } from './components/sucursal-fecha-entrega-dom-desac-abc-dialog/sucursal-fecha-entrega-dom-desac-abc-dialog.component';
import { EntregaDomicilioComponent } from './components/entrega-domicilio/entrega-domicilio.component';
import { SucursalLimiteCarritoPedidoDomDialogComponent } from './components/sucursal-limite-carrito-pedido-dom-dialog/sucursal-limite-carrito-pedido-dom-dialog.component';
import { SucursalLimiteCarritoPedidoDomAbcDialogComponent } from './components/sucursal-limite-carrito-pedido-dom-abc-dialog/sucursal-limite-carrito-pedido-dom-abc-dialog.component';
import { SucursalLimiteProductosPedidoDialogComponent } from './components/sucursal-limite-productos-pedido-dialog/sucursal-limite-productos-pedido-dialog.component';
import { SucursalLimiteProductosPedidoAbcDialogComponent } from './components/sucursal-limite-productos-pedido-abc-dialog/sucursal-limite-productos-pedido-abc-dialog.component';
import { PedidoAsignarWebcamComponent } from './components/pedido-asignar-webcam/pedido-asignar-webcam.component';


registerLocaleData(localeEsMx, 'es-Mx');
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProductodetalleComponent,
    PanelAdminComponent,
    CategoriasComponent,
    CategoriasDialogComponent,
    SucursalComponent,
    SucursalDialogComponent,
    ProductoComponent,
    ProductoDialogComponent,
    BannerComponent,
    BannerDialogComponent,
    ExtrasComponent,
    ExtrasDialogComponent,
    LoginComponent,
    NavbarComponent,
    NavbarPanelComponent,
    PedidosComponent,
    PedidosAsigRepartidorComponent,
    LoginPanelComponent,
    PedidoDetalleComponent,
    EstatuspedidoPipe,
    UsuariosComponent,
    UsuariosDialogComponent,
    TiendaComponent,
    TiendaDialogComponent,
    VentasComponent,
    UsuariorecuperacontraComponent,
    UsuarioSoliRecuperaContraComponent,
    UsuarioconfirmacionComponent,
    TerminosComponent,
    LegalesComponent,
    PedidoTicketDialogComponent,
    PedidoEscanearCodigoComponent,
    PedidoEscanearCodigoDetalleComponent,
    ProductospresentacionDialogComponent,
    ProductossucursalDialogComponent,
    ProductosingredientesDialogComponent,
    PresentacionesDialogComponent,
    PresentacionesComponent,
    ProductoscategoriasDialogComponent,
    EnviocostoComponent,
    EnviocostoDialogComponent,
    Usuarioconfirmaciondialog,
    EnviomonitorDialogComponent,
    PedidoEntregaMonitorDialogComponent,
    IngredientesComponent,
    IngredientePanDialogComponent,
    IngredienteCubiertaDialogComponent,
    IngredienteRellenoDialogComponent,
    IngredientePanSucursalDialogComponent,
    IngredienteRellenoSucursalDialogComponent,
    IngredienteCubiertaSucursalDialogComponent,
    IngredientePanPresentacionDialogComponent,
    SucursalImprimirDialogComponent,
    BannerImprimirDialogComponent,
    TiendaImprimirDialogComponent,
    CategoriasImprimirDialogComponent,
    PresentacionesImprimirDialogComponent,
    ProductoImprimirDialogComponent,
    ExtrasImprimirDialogComponent,
    PedidosImprimirDialogComponent,
    VentasprodImprimirDialogComponent,
    EnviocostoImprimirDialogComponent,
    AvisoprivacidadComponent,
    TerminoscondicionesComponent,
    TerminosdeliveryComponent,
    AvisodeliveryComponent,
    PedidosCambiofechaDialogComponent,
    PedidoReportepastelesDialogComponent,
    PedidoFechareportepastelesDialogComponent,
    DatosfacturacionDialogComponent,
    PagoexitosoComponent,
    PagorespuestaComponent,
    PagorechazadoComponent,
    PedidoBusquedaValidaComponent,
    DianolaboralComponent,
    DianolaboralDialogComponent,
    PedidoPendientePagoDialogComponent,
    EstatusPedidoDetalleComponent,
    ResumenVentasComponent,
    ResumenVentasReporteComponent,
    SucursalFechaEntregaDomDesacDialogComponent,
    SucursalFechaEntregaDomDesacAbcDialogComponent,
    EntregaDomicilioComponent,
    SucursalLimiteCarritoPedidoDomDialogComponent,
    SucursalLimiteCarritoPedidoDomAbcDialogComponent,
    SucursalLimiteProductosPedidoDialogComponent,
    SucursalLimiteProductosPedidoAbcDialogComponent,
    PedidoAsignarWebcamComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    APP_ROUTING,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTooltipModule,
    MatRadioModule,
    MatExpansionModule,
    MatIconModule,
    MatDatepickerModule,
    MatListModule,
    MatNativeDateModule,
    MatDividerModule,
    MatRippleModule,
    MatChipsModule,
    MatButtonModule,
    MatSnackBarModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBl7LnQ4iMXCOJgq9HAUp4FH8Njy98WXog&libraries=geometry,places' //Cliente
      //apiKey: 'AIzaSyAFeZIrUDUDjH6A3lmQKQ-00T7BACOeTp4&libraries=geometry,places' // CBI
    }),
    ImageCropperModule,
    MatCardModule,
    ImageUploadModule.forRoot(),
    ReactiveFormsModule,
    PdfViewerModule,
    NgxPrintModule,
    ZXingScannerModule
   // NgxPrinterModule.forRoot({printOpenWindow: true})

  ],
  providers: [{provide: APP_BASE_HREF, useValue: '/'},  { provide: LOCALE_ID, useValue: 'es-Mx' }, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
