export class VentasParamModel {
    idproducto: number;
    idsucursal: number;
    idtienda: number;
    idusuario: number;
    idrol: number;
    fecha_inicial: string;
    fecha_final: string;
}

export class VentasSucursalModel {
    sucursal: string;
    total_venta: number;
    numero_ventas: number;
}

export class VentasTotalesModel {
    tienda: string;
    total_venta: number;
    numero_ventas: number;
}

export class VentasIndicador3Model {
    valor1: number;
    valor2: number;
    valor3: number;
}
