import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { PedidoService } from '../../services/pedido.service';

@Component({
  selector: 'app-pedido-asignar-webcam',
  templateUrl: './pedido-asignar-webcam.component.html',
  styleUrls: ['./pedido-asignar-webcam.component.css']
})
export class PedidoAsignarWebcamComponent implements OnInit {

  availableDevices: MediaDeviceInfo[];
  deviceCurrent: MediaDeviceInfo;
  deviceSelected: string;
  hasDevices: boolean;
  hasPermission: boolean;

  idsucursal: number;
  idusuario: number;
  nombre: string;

  constructor(public dialogRef: MatDialogRef<PedidoAsignarWebcamComponent>,
             @Inject(MAT_DIALOG_DATA) public data: any,
             private pedidoService: PedidoService) {

              }

  ngOnInit(): void {
    this.idsucursal = Number(sessionStorage.ids);
    this.idusuario = Number(sessionStorage.idu);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onDeviceSelectChange(selected: string) {
    const selectedStr = selected || '';
    if (this.deviceSelected === selectedStr) { return; }
    this.deviceSelected = selectedStr;
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.deviceCurrent = device || undefined;
  }

  onDeviceChange(device: MediaDeviceInfo) {
    const selectedStr = device?.deviceId || '';
    if (this.deviceSelected === selectedStr) { return; }
    this.deviceSelected = selectedStr;
    this.deviceCurrent = device || undefined;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  UpdateWebCamAsignado() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando la WebCam seleccionada...'
    });
    Swal.showLoading();
    this.nombre = this.deviceSelected;
    this.pedidoService.updWebCamAsignada(this.idsucursal,this.idusuario,this.nombre).subscribe( (resp: any[]) => {     
        Swal.close();
        if (resp['idrespuesta'] === 0) {
          Swal.fire({
            allowOutsideClick: false,
            icon: 'info',
            text: resp['respuesta'],
            confirmButtonText: 'Aceptar'
          });
        } else {
          Swal.fire({
            allowOutsideClick: false,
            icon: 'warning',
            text: resp['respuesta'],
            confirmButtonText: 'Aceptar'
          });
        }
      }, (err) => {
       Swal.close(); 
       switch (err.status) {
        case 0:
            Swal.fire({
              icon: 'warning',
              text: 'No hay conexión con el servidor',
              confirmButtonText: 'Aceptar'
            });
            break;
         case 409:
           Swal.fire({
            icon: 'error',
            text: 'Error ' + err.status + ' en el guardado del catálogo de extras',
            confirmButtonText: 'Aceptar'
           });
           break;
          case 500:
              Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del catálogo de extras',
                confirmButtonText: 'Aceptar'
              });
              break;
        }
      });
  }

  // getComboRepartidores() {
  //   /// cual es el rol de los repartidores
  //   this.repartidosModel.idsucursal = Number(this.pedidoModel.idsucursal); 
  //  // this.repartidosModel.idsucursal = Number(sessionStorage.ids);
  //   this.repartidosModel.idrol = Number(sessionStorage.idr);
  //   console.log(this.repartidosModel);
  //   this.getRepartidoresCombo();
  // }


  // getRepartidoresCombo() {
  //   this.pedidoService.getRepartidoresSucursal(this.repartidosModel.idsucursal).subscribe( (resp: any[]) => {
  //       this.comborepartidores = resp;
  //   }, (err) => {
  //    switch (err.status) {
  //     case 0:
  //         Swal.fire({
  //           icon: 'warning',
  //           text: 'No hay conexión con el servidor',
  //           confirmButtonText: 'Aceptar'
  //         });
  //         break;
  //      case 409:
  //        Swal.fire({
  //         icon: 'error',
  //         text: 'Error  en dependencias ' + err.status + ' en el servidor',
  //         confirmButtonText: 'Aceptar'
  //        });
  //        break;
  //       case 500:
  //           Swal.fire({
  //             icon: 'error',
  //             text: 'Error  en dependencias ' + err.status + ' en el servidor',
  //             confirmButtonText: 'Aceptar'
  //           });
  //           break;
  //     }
  //   });
  // }

}
