import { Component, OnInit, ViewChild } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import Swal from 'sweetalert2';

import { UsuarioGridModel } from '../../models/adminusuarios/adminusuarios.model';
import { UsuarioService } from '../../services/usuario.service';
import { UsuariosDialogComponent } from '../usuarios-dialog/usuarios-dialog.component';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  usuarioGridModel: UsuarioGridModel;

  constructor(private dialog: MatDialog,
              public paginatorInt: MatPaginatorIntl,
              private usuariosService: UsuarioService) { }

  imagenPerfilvacia: string;
  displayedColumns: string[] = [];
  datasource: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
             dataSource: MatTableDataSource<any>;

  ngOnInit(): void {

    this.imagenPerfilvacia = './assets/iconouser/user2.png';


    this.usuarioGridModel = new UsuarioGridModel();
    this.usuarioGridModel.idusuario = Number(sessionStorage.getItem('idu'));
    this.usuarioGridModel.idrol = Number(sessionStorage.getItem('idr'));
    this.usuarioGridModel.idsucursal = Number(sessionStorage.getItem('ids'));
    
    this.getGridUsuarioPanel();

    this.paginatorInt.firstPageLabel = 'Primera página';
    this.paginatorInt.lastPageLabel = 'Última página';
    this.paginatorInt.itemsPerPageLabel = 'Registro por página';
    this.paginatorInt.nextPageLabel     = 'Siguiente página';
    this.paginatorInt.previousPageLabel = 'Anterior página';

  }

  reloadgrid() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.getGridUsuarioPanel();
    Swal.close();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  acceso(  idmodulo: number, idsubmodulo: number, row) {
    this.openDialogUsuario(row);
  }

  getGridUsuarioPanel() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumns = ['idusuario', 'usuario', 'nombre', 'email', 'movil', 'telefono', 'tienda', 'sucursal', 'url_foto_perfil', 'actions'];
    this.usuariosService.getGridUsuariosPanel(this.usuarioGridModel.idsucursal, this.usuarioGridModel.idrol).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor',
            confirmButtonColor: '#391410',
            confirmButtonText: 'ACEPTAR'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de productos en el servidor',
          confirmButtonColor: '#391410',
          confirmButtonText: 'ACEPTAR'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de productos en el servidor',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
            break;

      }
    });
  }

  openDialogUsuario(rows): void {
    if ( rows === undefined ) {
      const dialogRef = this.dialog.open( UsuariosDialogComponent , {

        data: {titulo: 'Agregar el usuario',
          row: '',
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridUsuarioPanel();
      });
    } else {
      const dialogRef = this.dialog.open( UsuariosDialogComponent , {
        data: {titulo: 'Actualizar el usuario',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.80),
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getGridUsuarioPanel();
      });
    }
  }

  UpdBajaUsuario(UsuarioRow: any) {
    this.bajaUsuario(UsuarioRow);
  }

  bajaUsuario(row: any) {

    const username = row.username;

    // operacion
    // 1. insertar
    // 2. actualizar
    // 3. dar de baja
    // 4. restaurar usuario

    row.operacion = 3;
    // console.log(row);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Dar de baja el usuario ' + row.username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.usuariosService.abcPanelUsuario(row).subscribe( (resp: any[]) => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            text: 'El usuario ' + username + ' ha sido dado de baja.'
          });

          // si regresa respuesta el servicio
          if (resp) {

            if (resp['idrespuesta'] > 0) {
              Swal.fire({
                icon: 'success',
                text: resp['respuesta'],
                confirmButtonColor: '#391410',
                confirmButtonText: 'ACEPTAR'
              });
              } else {
              Swal.fire({
                icon: 'error',
                text: resp['respuesta'],
                confirmButtonColor: '#391410',
                confirmButtonText: 'ACEPTAR'
              });
              }
          }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor',
                  confirmButtonColor: '#391410',
                  confirmButtonText: 'ACEPTAR'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del panel de usuarios',
                confirmButtonColor: '#391410',
                confirmButtonText: 'ACEPTAR'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del panel de usuarios',
                    confirmButtonColor: '#391410',
                    confirmButtonText: 'ACEPTAR'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'El usuario ' + username + ' ha sido dado de baja.',
            'success'
          );

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La baja no fue realizada!',
          'warning'
        );
      }
    });

  }

  updRestaurarUsuario(row: any) {

    this.restaurarUsuario(row);

  }

  restaurarUsuario(row: any) {

    const username = row.username;

    // operacion
    // 1. insertar
    // 2. actualizar
    // 3. dar de baja
    // 4. restaurar usuario

    row.operacion = 4;
    // console.log(row);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Restaurar el usuario ' + row.username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.usuariosService.abcPanelUsuario(row).subscribe( (resp: any[]) => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            text: 'El usuario ' + username + ' ha sido restaurado.'
          });

          // si regresa respuesta el servicio
          if (resp) {

            if (resp['idrespuesta'] > 0) {
              Swal.fire({
                icon: 'success',
                text: resp['respuesta'],
                confirmButtonColor: '#391410',
                confirmButtonText: 'ACEPTAR'
              });
              } else {
              Swal.fire({
                icon: 'error',
                text: resp['respuesta'],
                confirmButtonColor: '#391410',
                confirmButtonText: 'ACEPTAR'
              });
              }
          }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor',
                  confirmButtonColor: '#391410',
                  confirmButtonText: 'ACEPTAR'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del panel de usuarios',
                confirmButtonColor: '#391410',
                confirmButtonText: 'ACEPTAR'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del panel de usuarios',
                    confirmButtonColor: '#391410',
                    confirmButtonText: 'ACEPTAR'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'El usuario ' + username + ' ha sido restaurado.',
            'success'
          );

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La restauración del usuario no fue realizada!',
          'warning'
        );
      }
    });

  }

}
