import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PedidoDetalleModel, PedidoModel } from '../../models/pedido/pedido.model';
import Swal from 'sweetalert2';
import { PedidoService } from '../../services/pedido.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-resumen-ventas-reporte',
  templateUrl: './resumen-ventas-reporte.component.html',
  styleUrls: ['./resumen-ventas-reporte.component.css']
})
export class ResumenVentasReporteComponent implements OnInit {
  displayedColumnstitulo: string[] = [];
  dataSourcetitulo: MatTableDataSource<any>;
  displayedColumnsdetalle: string[] = [];
  dataSourcedetalle: MatTableDataSource<any>;
  sucursal: string;
  reporte: any[];
  pedidosmodel: PedidoModel;
  idrol: number;
  totalpedidos: number;
  totalventas: number;
  

  constructor(public dialogRef: MatDialogRef<ResumenVentasReporteComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private servicepedido: PedidoService) { }

  ngOnInit(): void {
    this.pedidosmodel = new PedidoModel();
    this.pedidosmodel.idusuario = Number(sessionStorage.idu);
    this.pedidosmodel.idrol = Number(sessionStorage.idr);
    this.idrol = Number(sessionStorage.getItem('idr'));
    this.getReporteResumenVentas();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getReporteResumenVentas() {
    this.displayedColumnstitulo = ['sucursal','totalpedidos','totalventas'];
    // this.displayedColumnsdetalle = ['presentacion', 'cantidad', 'producto','pedido','importe','hora_entrega','molde'];
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando la información...'
    });
    Swal.showLoading();
    this.servicepedido.getReporteResumenVentas(this.pedidosmodel.idusuario, this.idrol, this.data.fecha_inicial, this.data.fecha_final, this.data.idsucursal).subscribe( (resp: any[]) => {
          
          this.dataSourcetitulo = new MatTableDataSource<any>(resp);          
          // this.sucursal = resp[0].sucursal;
          this.reporte = resp;
          this.totalpedidos = 0;
          this.totalventas = 0;
          for (let index = 0; index < resp.length; index++) {
            this.totalpedidos = this.totalpedidos + resp[index].totalpedidos;
            this.totalventas = this.totalventas + resp[index].totalventas;
          }
          Swal.close();

    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el reporte de productos en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el reporte de productos en el servidor'
            });
            break;
      }
    });
  }

}
