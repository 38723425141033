import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  private url = 'https://fcm.googleapis.com'

  constructor( private http: HttpClient) { }
  
  enviarPush(token: string, nopedido: string, titulomsj: string, cuerpomsj: string) {
    // const headerDict = {
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/json',
    //   'Access-Control-Allow-Headers': 'Content-Type',
    // };


  //  const textoBody = 'Número de orden: ' + nopedido;

    const headerDict = {
      'Content-Type': 'application/json',
      'Authorization': 'key=AAAAW0dpwtQ:APA91bFtOwc5JiQxfaDtyaC9zVnbw-CtKI5oOZiNBXjIHtSM47tO4eW1u9zvPqA7T8Ih5Fa5GBzTEIABmfvYyAOtmTH_VxWHzBeKdDFJVN6SR-h2qCTG2CQFw3AKB0KHaCvPtO53J4nz',
    };
    
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
   // let headers = new Headers().set('Content-Type', 'application/json')
       const data = JSON.stringify({
        "to":new String(token),
        "notification":{
            "title": new String(titulomsj),
            "body": new String(cuerpomsj),
            "sound": "default",
            "channel_id": "FCMService"
        },
        "data": {
          "Nopedido": new String(nopedido),
          "image_url": "ninguna"
        }
    });
   
  //  const data = JSON.stringify({
  //                             "to":"dRPBVyBfTySjxjJWdpPABI:APA91bGd6T5AdFgYx7s0Jp56eP8zwuzaXst84w7p_IdKbxWaqMkkVI6c6sBuyQHqKNMMdKDa2mAzLXGYO1Tb3cV6Pr_8NmgFn5Jwc3G109IRa2q508jDMIMft7HZjKMFr8qFwGF_0nHq",
  //                             "notification":{
  //                                 "title": "Nuevo pedido Apple Ice",
  //                                 "body": "Apple Ice",
  //                                 "sound": "default",
  //                                 "channel_id": "FCMService"
  //                             },
  //                             "data": {
  //                               "Nopedido": "9999",
  //                               "image_url": "ninguna"
  //                             }
  //                         });
    return this.http.post(`${ this.url }/fcm/send`, data, requestOptions);
  }
}
