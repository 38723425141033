import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../models/appsettings';
import { FormGroup } from '@angular/forms';
import { DianolaboralModel, ParamDianolaboralModel } from '../models/dianolaboral/dianolaboral.model';


@Injectable({
  providedIn: 'root'
})
export class DianolaboralService {

  private header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  .append('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  private headerQP = new HttpHeaders().set('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  constructor(private http: HttpClient) { }

  getQueryParam(query: string) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.get(url, { headers: this.headerQP });
  }

  getFormParam(query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.header });
  }


  getDianolaboralCatalogoGrid(data: ParamDianolaboralModel) {
    return this.getQueryParam('fechaentrega/leerdianolaboralpanel?idsucursal=' + data.idsucursal + '&idrol=' + data.idrol);
  }

  abcDianolaboral(data: DianolaboralModel) {
    const params = new HttpParams({
      fromObject: {
        iddiafestivo: String( data.iddiafestivo === undefined ? 0 : data.iddiafestivo),
        idsucursal: String(data.idsucursal === undefined ? 0 : data.idsucursal),
        diafestivo: data.diafestivo === undefined ? '' : data.diafestivo,
        descripcion: data.descripcion === undefined ? '' : data.descripcion,
        activo: String( data.activo === undefined ? '' : data.activo),
        idusuario: String( data.idusuario === undefined ? 0 : data.idusuario),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar, 3 dar de baja 4 reactivar)
       }
    });
    return this.getFormParam('fechaentrega/abcdianolaboralpanel', params);
  }


}
