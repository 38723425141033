import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { FechasEntregaDomDesactivadosModel } from './../../models/sucursal/sucursal.model';
import { SucursalService } from '../../services/sucursal.service';
import { ProductoService } from '../../services/producto.service';
import { CombosService } from '../../services/combos.service';
import {  NgForm } from '@angular/forms';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { SucursalLimiteCarritoPedidoDomAbcDialogComponent } from '../sucursal-limite-carrito-pedido-dom-abc-dialog/sucursal-limite-carrito-pedido-dom-abc-dialog.component';

@Component({
  selector: 'app-sucursal-limite-carrito-pedido-dom-dialog',
  templateUrl: './sucursal-limite-carrito-pedido-dom-dialog.component.html',
  styleUrls: ['./sucursal-limite-carrito-pedido-dom-dialog.component.css']
})
export class SucursalLimiteCarritoPedidoDomDialogComponent implements OnInit {

  Forma: NgForm;
  combocategorias: any[];
  combopresentaciones: any[];
  fechaentregamodel: FechasEntregaDomDesactivadosModel;
  displayedColumns: string[] = [];
  dataSource: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  idsucursal: number;
  combosucursales: any[];
  combotiendas: any[];
  activaGuardar: boolean;
  visualizar: number;

  constructor(public dialogRef: MatDialogRef<SucursalLimiteCarritoPedidoDomAbcDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              private sucursalservice: SucursalService,
              private comboservice: CombosService,
              public paginatorInt: MatPaginatorIntl) { }

  ngOnInit(): void {
            this.visualizar = 0;
            this.activaGuardar = true;
            this.fechaentregamodel = new FechasEntregaDomDesactivadosModel ();
            this.fechaentregamodel.id = 0;
            this.fechaentregamodel.usuario_creada = Number(sessionStorage.getItem('idu'));
            this.fechaentregamodel.idsucursal = Number(sessionStorage.getItem('ids'));
            this.idsucursal = this.data.row.idsucursal;
            this.getGridLimiteCarritoPedidoDom();

  }

  getGridLimiteCarritoPedidoDom() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
      Swal.showLoading();
      this.displayedColumns = ['activo','sucursal','cantidad','actions']; 
      this.sucursalservice.getLimiteProdCarritoDomGrid(this.idsucursal).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de cantidad en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de cantidad en el servidor'
            });
            break;
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  reloadgrid() {
    this.getGridLimiteCarritoPedidoDom();
  }

  acceso(  idmodulo: number, idsubmodulo: number, row) {
    this.openDialogLimiteCarritoPedidoDom(row);
  }

  openDialogLimiteCarritoPedidoDom(rows): void {
    if ( rows === undefined ) {
      const dialogRef = this.dialog.open( SucursalLimiteCarritoPedidoDomAbcDialogComponent , {
        data: {titulo: 'Agregar registro',
          row: '',
          idsucursal: this.idsucursal,
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridLimiteCarritoPedidoDom();
      });
    } else {
      const dialogRef = this.dialog.open( SucursalLimiteCarritoPedidoDomAbcDialogComponent , {
        data: {titulo: 'Actualizar registro',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.80),
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getGridLimiteCarritoPedidoDom();
      });
    }
  }

  // activar o desactivar fecha 

  updDesactivarLimiteCarritoPedidoDom(UsuarioRow: any) {
    this.desactivarLimiteCarritoPedidoDom(UsuarioRow);
  }

  desactivarLimiteCarritoPedidoDom(row: any) {

    const username = row.sucursal;

    // operacion
    // 1. insertar
    // 2. actualizar
    // 3. desactivar fecha
    // 4. restaurar fecha

    row.operacion = 4;
    row.usuario_creada = Number(sessionStorage.idu);
    // console.log(row);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Desactivar la cantidad del registro ' + username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.sucursalservice.abcLimiteProdCarritoDom(row).subscribe( (resp: any[]) => {
          Swal.close();

          // Swal.fire({
          //   icon: 'success',
          //   text: 'El extra ' + username + ' ha sido dado de baja.'
          // });

          // si regresa respuesta el servicio
          if (resp) {

            if (resp[0].idrespuesta > 0) {
              Swal.fire({
                icon: 'success',
                text: resp[0].respuesta
              });
              this.getGridLimiteCarritoPedidoDom();
              } else {
              Swal.fire({
                icon: 'error',
                text: resp[0].respuesta
              });
              }
          }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'El Registro ' + username + ' ha sido desactivado.',
            'success'
          );

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La desactivacion del registro no fue realizada!',
          'warning'
        );
      }
    });

  }


  updActivarLimiteCarritoPedidoDom(UsuarioRow: any) {
    this.activarLimiteCarritoPedidoDom(UsuarioRow);
  }

  activarLimiteCarritoPedidoDom(row: any) {

    const username = row.sucursal;

    // operacion
    // 1. insertar
    // 2. actualizar
    // 3. desactivar fecha
    // 4. restaurar fecha

    row.operacion = 3;
    row.usuario_creada = Number(sessionStorage.idu);
    // console.log(row);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Activar la cantidad del registro de ' + username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.sucursalservice.abcLimiteProdCarritoDom(row).subscribe( (resp: any[]) => {
          Swal.close();

          // Swal.fire({
          //   icon: 'success',
          //   text: 'El extra ' + username + ' ha sido dado de baja.'
          // });

          // si regresa respuesta el servicio
          if (resp) {

            if (resp[0].idrespuesta > 0) {
              Swal.fire({
                icon: 'success',
                text: resp[0].respuesta
              });
              this.getGridLimiteCarritoPedidoDom();
              } else {
              Swal.fire({
                icon: 'error',
                text: resp[0].respuesta
              });
              }
          }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del registro'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del registro'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion',
            'El registro de ' + username + ' ha sido activado.',
            'success'
          );

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La activacion no fue realizada.',
          'warning'
        );
      }
    });

  }

}
