import { Component, OnInit, Inject } from '@angular/core';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsuarioService } from '../../services/usuario.service';
import { Router } from '@angular/router';
import {MatDialogRef, MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-usuario-soli-recupera-contra',
  templateUrl: './usuario-soli-recupera-contra.component.html',
  styleUrls: ['./usuario-soli-recupera-contra.component.css']
})
export class UsuarioSoliRecuperaContraComponent implements OnInit {
  email: string;
  formulario: FormGroup;
  submitted = false;

  dialogRef: MatDialogRef<Usuariosolirecuperacontradialog>;

  constructor(private servicioUsuario: UsuarioService,
              private router: Router,
              private formBuilder: FormBuilder,
              public dialog: MatDialog) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$')]]
    }, {
    });

  }

  // variable para acceder a los campos del formulario
  get f() { return this.formulario.controls; }

  // función que regresa el resultado del Validator
  get emailNoValido() {
    return this.formulario.get('email').invalid;
  }

  onSubmit() {

    this.submitted = true;
    // se detiene si el formulario es invalido no ejecuta el servicio
    if (this.formulario.invalid) {
        return;
    }
    this.solicitarPassword();

  }

  solicitarPassword() {
    // Swal.fire({
    //   allowOutsideClick: false,
    //   icon: 'info',
    //   text: 'Enviando la solicitud ...'
    // });
    // Swal.showLoading();

    this.openDialogSpinner('Enviando la solicitud, espere un momento por favor...');
    this.servicioUsuario.solicitarPassword(this.email).subscribe( (resp: any) => {
    this.closeDialog();
    this.openDialog('Petición realizada satisfactoriamente, se ha enviado un correo de confirmación reviselo para continuar.');

      // Swal.close();
   // this.openDialog();
    // if (resp) {
     // console.log(resp);
      // if ( resp > 0) {
    // Swal.fire({
    //           icon: 'success',
    //           text: 'Petición realizada satisfactoriamente se ha enviado un correo de confirmación, reviselo para continuar el proceso.'
    //         });
      // }
      // switch (resp) {
      //   case 1:
      //     Swal.fire({
      //       icon: 'success',
      //       text: 'Correo de recuperación enviado'
      //     });
      //     break;
      //   case 2:
      //     Swal.fire({
      //         icon: 'warning',
      //         text: 'Usuario con estatus de baja'
      //     });
      //     break;
      //     case 3:
      //         Swal.fire({
      //             icon: 'warning',
      //             text: 'Usuario sin confirmación de correo'
      //         });
      //         break;
      // }
    // }
 }, (err) => {
  // Swal.close();
   this.closeDialog();
   switch (err.status) {
    case 0:
      // this.openDialogErrorServidor('No hay conexión con el servidor');
      // Swal.fire({
      //   icon: 'warning',
      //   text: 'No hay conexión con el servidor'
      // });
      this.openDialog('No hay conexión con el servidor o verifique su conexión a internet.');
      break;
    case 404:
      // this.openDialogError('No existe el correo electrónico, reviselo e intente nuevamente');
      //  Swal.fire({
      //    icon: 'warning',
      //    text: 'No existe el correo electrónico, reviselo e intente nuevamente'
      //  });
       this.openDialog('No existe el correo electrónico, reviselo e intente nuevamente.');
       break;
   case 409:
      // this.openDialogErrorServidor('Error ' + err.status + ' de la solicitud de contraseña en el servidor');
    //  Swal.fire({
    //   icon: 'error',
    //   text: 'Error ' + err.status + ' de la solicitud de contraseña en el servidor'
    //  });
     this.openDialog('Error ' + err.status + ' de la solicitud de contraseña en el servidor');
     break;
    case 500:
        // this.openDialogErrorServidor('Error ' + err.status + ' de la solicitud de contraseña en el servidor');
        // Swal.fire({
        //   icon: 'error',
        //   text: 'Error ' + err.status + ' de la solicitud de contraseña en el servidor'
        // });
        this.openDialog('Error ' + err.status + ' de la solicitud de contraseña en el servidor');
        break;
   }
   });
}

onNoClick(): void {
  this.router.navigateByUrl('login');
}

  openDialogSpinner(msj: string) {
    this.dialogRef = this.dialog.open(Usuariosolirecuperacontradialog, {
      disableClose: true,
      data: {titulo: 'Atencion',
          row: {mensaje: msj, titulo: 'Respuesta', visible: false},
      }
    }
    );
    this.dialogRef.beforeClosed().subscribe((result: string) => {
      console.log('Antes de cerrar el dialog,', result);
    });
    this.dialogRef.afterClosed().subscribe(result => {
      console.log('Despues de cerrar el dialog', result);
    });
  }

  openDialog(msj: string) {
    this.dialogRef = this.dialog.open(Usuariosolirecuperacontradialog, {
      disableClose: false,
      data: {titulo: 'Atencion',
          row: {mensaje: msj, titulo: 'Respuesta', visible: true},
      }
    }
    );
    this.dialogRef.beforeClosed().subscribe((result: string) => {
      console.log('Antes de cerrar el dialog,', result);
    });
    this.dialogRef.afterClosed().subscribe(result => {
      console.log('Despues de cerrar el dialog', result);
    });
  }

  closeDialog() {
    this.dialogRef.close(true);
  }


}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'Usuariosolirecuperacontradialog',
  templateUrl: 'usuario-soli-recupera-contradialog.html',
  styleUrls: ['./usuario-soli-recupera-contra.component.css']
})

// tslint:disable-next-line: component-class-suffix
export class Usuariosolirecuperacontradialog implements OnInit {
  visible: boolean;
  imagenlogo: string;


  constructor(private servicioUsuario: UsuarioService,
              private _route: ActivatedRoute,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<Usuariosolirecuperacontradialog>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  mensaje: string;
  titulo: string;

  ngOnInit(): void {
    this.mensaje = this.data.row.mensaje;
    this.titulo = this.data.row.titulo;
    this.visible = this.data.row.visible;
    this.imagenlogo = './assets/images/logo.gif';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
