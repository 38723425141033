import { Component, OnInit, ViewChild } from '@angular/core';
import { TiendaModel } from '../../models/tienda/tienda.model';

import { MatDialog} from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';

import { TiendaService } from '../../services/tienda.service';
import { TiendaDialogComponent } from '../tienda-dialog/tienda-dialog.component';
import { promise } from 'protractor';
import { TiendaImprimirDialogComponent } from '../tienda-imprimir-dialog/tienda-imprimir-dialog.component';


@Component({
  selector: 'app-tienda',
  templateUrl: './tienda.component.html',
  styleUrls: ['./tienda.component.css']
})
export class TiendaComponent implements OnInit {
    tiendamodel: TiendaModel;
    displayedColumns: string[] = [];
    datasource: any;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    dataSource: MatTableDataSource<any>;
    tabla: any[];
    refrescargrid: boolean;

  constructor(private tiendaservice: TiendaService,
              private dialog: MatDialog,
              public paginatorInt: MatPaginatorIntl) { }

  ngOnInit(): void {
              this.tiendamodel = new TiendaModel();
              this.tiendamodel.idtienda = 0;
              this.refrescargrid = false;

              // if (validaacceso(Number(sessionStorage.idr))) {
              //   console.log('acceso correcto');
              // } else {

              //   Swal.fire({
              //     position: 'center',
              //     icon: 'warning',
              //     title: '¡Acceso no permitido!',
              //     showConfirmButton: true,
              //     timer: 7500
              //   });

              //   // console.log('acceso no permitido');
              //   return;
              // }

              // function validaacceso(rol: number) {
              //   let acceso = false;
              //   switch (rol) {
              //     case 1:
              //       acceso = true; // Super Admin
              //       break;
              //     case 2:
              //       acceso = true; // Administrador (Tienda)
              //       break;
              //     case 3:
              //       acceso = false; // Administrador (Sucursal)
              //       break;
              //     case 4:
              //       acceso = false; // Empleado
              //       break;
              //     case 5:
              //       acceso = false; // Cliente
              //       break;
              //     case 6:
              //       acceso = false; // Repartidor
              //       break;
              //     // default:
              //     //   this.router.navigateByUrl('/');
              //     //   break;
              //   }
              //   return acceso;
              // }



              // this.tiendamodel.idsucursal = Number(sessionStorage.ids);
              // this.tiendamodel.idrol = Number(sessionStorage.idr);
              this.getGridTiendas();

              this.paginatorInt.firstPageLabel = 'Primera página';
              this.paginatorInt.lastPageLabel = 'Última página';
              this.paginatorInt.itemsPerPageLabel = 'Registro por página';
              this.paginatorInt.nextPageLabel     = 'Siguiente página';
              this.paginatorInt.previousPageLabel = 'Anterior página';
  }

  acceso(row: any) {

    this.openDialogTienda(row);
  }

  getGridTiendas() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumns = ['idtienda', 'esactivo', 'nombre_comercial', 'email', 'telefono', 'movil', 'actions'];
    this.tiendaservice.getGridCatTienda().subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.tabla = resp;
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de tiendas en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de tiendas en el servidor'
            });
            break;

      }
    });
  }

  openDialogTienda(rows: any): void {
    if ( rows === undefined ) {
      const dialogRef = this.dialog.open( TiendaDialogComponent , {

        data: {titulo: 'Agregar la tienda',
          row: '',
        },
        disableClose: true,
        height: String(screen.height * 0.90),
        width: String(screen.width * 0.80),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       this.getGridTiendas();
      });
    } else {
      const dialogRef = this.dialog.open( TiendaDialogComponent , {
        data: {titulo: 'Actualizar la tienda',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.80),
      });
      dialogRef.afterClosed().subscribe(result => {
        this.getGridTiendas();
      });
    }
  }

  openDialogImprimir(rows): void {
    // if ( rows === undefined ) {
      const dialogRef = this.dialog.open( TiendaImprimirDialogComponent , {
        data: {titulo: 'Vista previa',
        row: rows
      },
      disableClose: true,
      height: String(screen.height * 0.90),
      width: String(screen.width * 0.90),
      });
      dialogRef.afterClosed().subscribe(result => {
       // this.getGridSucursal();
      });
    // }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tablaFilter(filterValue.trim().toLowerCase());
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  tablaFilter(cadena: string) {

    // llenar la tabla para el reporte
    // const cadena = filterValue.trim().toLowerCase();
    const tablatemp = [];
    for (let i=0; i < this.tabla.length; i++) {
      if (this.tabla[i].nombre_comercial.trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].email.trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].telefono.trim().toLowerCase().indexOf(cadena) > -1 ||
          this.tabla[i].movil.trim().toLowerCase().indexOf(cadena) > -1
         ) {
        tablatemp.push(this.tabla[i]);
      }
    }
    this.tabla = [];
    this.tabla = tablatemp;
    console.log(tablatemp);
  }

  UpdBajaTienda(UsuarioRow: any) {
    this.tiendamodel = UsuarioRow;
    this.bajaTienda(this.tiendamodel);
  }

  bajaTienda(row: any) {
    row.idusuario = Number(sessionStorage.idu);
    const username = row.nombre_comercial;

    // operacion
    // 1. insertar
    // 2. actualizar
    // 3. dar de baja
    // 4. restaurar usuario
    row.operacion = 3;
    // console.log(row);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Dar de baja ' + username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.tiendaservice.abcCatTienda(row).subscribe( (resp: any[]) => {
          Swal.close();

          // Swal.fire({
          //   icon: 'success',
          //   text: 'El extra ' + username + ' ha sido dado de baja.'
          // });

          // si regresa respuesta el servicio
          if (resp) {

            if (resp[0].idrespuesta > 0) {
              Swal.fire({
                icon: 'success',
                text: resp[0].respuesta
              });
              } else {
              Swal.fire({
                icon: 'error',
                text: resp[0].respuesta
              });
              }
          }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del panel de tiendas'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del panel de tiendas'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'La tienda ' + username + ' ha sido dada de baja.',
            'success'
          );
          this.getGridTiendas();

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La baja no fue realizada!',
          'warning'
        );
      }
    });

  }

  updRestaurarTienda(row: any) {
    this.tiendamodel = row;
    this.restaurarTienda(row);
  }

  restaurarTienda(row: any) {
    row.idusuario = Number(sessionStorage.idu);
    const username = row.nombre_comercial;
    // operacion
    // 1. insertar
    // 2. actualizar
    // 3. dar de baja
    // 4. restaurar usuario
    row.operacion = 4;
    // console.log(row);

    this.refrescargrid = false;

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn ',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: true,
      confirmButtonColor: '#391410',
      cancelButtonColor: '#d01d17',
      confirmButtonText: 'Aceptar'
    } );

    swalWithBootstrapButtons.fire({
      title: '¿Restaurar ' + username + '?',
      text: 'Confirme por favor',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

          this.tiendaservice.abcCatTienda(row).subscribe( (resp: any[]) => {
          Swal.close();

          Swal.fire({
            icon: 'success',
            text: 'La tienda ' + username + ' ha sido restaurada.'
          });

          // si regresa respuesta el servicio
          if (resp) {

            if (resp[0].idrespuesta > 0) {
              Swal.fire({
                icon: 'success',
                text: resp[0].respuesta
              });
              } else {
              Swal.fire({
                icon: 'error',
                text: resp[0].respuesta
              });
              }
            this.refrescargrid = true;

          }
          }, (err) => {
            switch (err.status) {
            case 0:
                Swal.fire({
                  icon: 'warning',
                  text: 'No hay conexión con el servidor'
                });
                break;
              case 409:
                Swal.fire({
                icon: 'error',
                text: 'Error ' + err.status + ' en el guardado del panel de tiendas'
                });
                break;
              case 500:
                  Swal.fire({
                    icon: 'error',
                    text: 'Error ' + err.status + ' en el guardado del panel de tiendas'
                  });
                  break;
            }
          });

          swalWithBootstrapButtons.fire(
            'Atencion!',
            'La tienda ' + username + ' ha sido restaurada.',
            'success'
          );
          this.getGridTiendas();

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelar',
          'La restauración no fue realizada!',
          'warning'
        );
      }
    });

  }

  reloadgrid() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.getGridTiendas();
    Swal.close();
  }


}
