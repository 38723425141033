import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { PedidoEscanerCodigoModel, PedidoDetalleModel, PedidoModel } from '../../models/pedido/pedido.model';
import { PedidoService } from '../../services/pedido.service';


// the scanner!
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { never } from 'rxjs';
import { MatDialog,  MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { PedidoEscanearCodigoDetalleComponent } from '../pedido-escanear-codigo-detalle/pedido-escanear-codigo-detalle.component';
import { PedidoTicketDialogComponent } from '../pedido-ticket-dialog/pedido-ticket-dialog.component';


@Component({
  selector: 'app-pedido-escanear-codigo',
  templateUrl: './pedido-escanear-codigo.component.html',
  styleUrls: ['./pedido-escanear-codigo.component.css']
})
export class PedidoEscanearCodigoComponent implements OnInit {

// FormatoCodigos = [  BarcodeFormat.AZTEC, BarcodeFormat.CODABAR, BarcodeFormat.CODE_128,  BarcodeFormat.DATA_MATRIX, BarcodeFormat.EAN_13, BarcodeFormat.EAN_8, BarcodeFormat.ITF, BarcodeFormat.MAXICODE, BarcodeFormat.PDF_417, BarcodeFormat.QR_CODE, BarcodeFormat.RSS_14, BarcodeFormat.RSS_EXPANDED, BarcodeFormat.UPC_A, BarcodeFormat.UPC_E, BarcodeFormat.UPC_EAN_EXTENSION /*, ...*/ ];
   FormatoCodigos = [  BarcodeFormat.QR_CODE, ];
   @ViewChild('scanner', { static: false })
  // 
  scanner: ZXingScannerComponent;

  CadenaResultado: string;

  escanerCodigoModel: PedidoEscanerCodigoModel;
  pedidoModel: PedidoModel;

  resultado: any[];

  idpedido: number;

  constructor(public dialogRef: MatDialogRef<PedidoEscanearCodigoComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private servicePedido: PedidoService) { }

  ngOnInit(): void {
    this.pedidoModel = new PedidoModel();
    this.escanerCodigoModel = new PedidoEscanerCodigoModel();
    this.escanerCodigoModel.no_orden = '';   
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  scanSuccessHandler(event: any) {

    if (event === undefined) {
      this.escanerCodigoModel.no_orden = 'Código desconocido.';
      
    } else {   
      this.escanerCodigoModel.no_orden = event;         
      this.getCodigoCadena();
    }

  }

  scanCompleteHandler(event: any) {    
  }

  getCodigoCadena() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Leyendo el código QR...'
    });
    Swal.showLoading();
    setTimeout (() => {
      this.servicePedido.getEscanearCodigoPedido(this.escanerCodigoModel).subscribe( (resp: any[]) => {
       
        Swal.close();
        this.resultado = resp;
        Swal.fire(
          '¡Número encontrado aceptado y entregado!!',
          this.resultado[0].no_orden,
          'success'
        );
           
  }, (err) => {
    Swal.close();
    switch (err.status) {
     case 0:
        Swal.fire({
          icon: 'warning',
          text: 'No hay conexión con el servidor o revise su conexión a internet.'
        });
        break;
     case 403:
        Swal.fire({
          icon: 'warning',
          text: 'El pedido ha sido cancelado.'
        });
        break;
     case 404:
        Swal.fire({
          icon: 'warning',
          text: 'No es un pedido válido.'
        });
        break;
     case 406:
        Swal.fire({
          icon: 'warning',
          text: 'El pedido no esta terminado'
        });
        break;
     case 409:
        Swal.fire({
        icon: 'error',
        text: 'Conflicto en el servicio web.'
        });
        break;
     case 412:
        Swal.fire({
          icon: 'warning',
          text: 'El pedido ya fue entregado.'
        });
        this.escanerCodigoModel.no_orden = 'Pedido entregado';
        break;
     case 500:
        Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' en el servidor'
        });
        break;
    }
  });
   }, 2000);
   
  }

  openDialogEscanearCodigoRespuesta(rows: any): void {
    if ( rows !== undefined ) {
      const dialogRef = this.dialog.open(PedidoEscanearCodigoDetalleComponent, {

        data: {titulo: 'Detalle del pedido',
          row: rows,
        },
        disableClose: false,
        height: String(screen.height * 0.80),
        width: String(screen.width * 0.20),
        panelClass: 'full-screen-modal'
      });
      dialogRef.afterClosed().subscribe(result => {
       //  this.getGridPedidos();
      });
    }
  }


}
