export class CategoriaModel {
    idtienda: number;
    idsucursal: number;
    idcategoria: number;
    categoria: string;
    estaactivo: boolean;
    ins_fecha: string;
    upd_fecha: string;
    idusuario: number;
    operacion: number;
    idrol: number;
}