import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { ProductosPresentacionModel } from './../../models/producto/producto.model';
import { ProductoService } from '../../services/producto.service';
import { CombosService } from '../../services/combos.service';
import {  NgForm } from '@angular/forms';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';

@Component({
  selector: 'app-productospresentacion-dialog',
  templateUrl: './productospresentacion-dialog.component.html',
  styleUrls: ['./productospresentacion-dialog.component.css']
})
export class ProductospresentacionDialogComponent implements OnInit {

  Forma: NgForm;
  combocategorias: any[];
  combopresentaciones: any[];
  presentacionmodel: ProductosPresentacionModel;
  displayedColumns: string[] = [];
  dataSource: any;
  combotiendas: any[];
  combosucursales: any[];
  idrol: number;
  visualizar: number;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public dialogRef: MatDialogRef<ProductospresentacionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private productoservice: ProductoService,
              private comboservice: CombosService,
              public paginatorInt: MatPaginatorIntl) { }

  ngOnInit(): void {
    this.getComboTienda();
    this.getComboSucursal(this.data.row.idtienda);
    this.getComboPresentacion(Number(sessionStorage.getItem('ids')));
    this.visualizar = 0;
    this.idrol = Number(sessionStorage.getItem('idr'));
    this.presentacionmodel = new ProductosPresentacionModel ();
    this.presentacionmodel.idpresentacion = 0;
    this.presentacionmodel.idcategoria = 0;
    this.presentacionmodel.precio_promocion = '0';
    this.presentacionmodel.idtienda = this.data.row.idtienda;
    this.presentacionmodel.idsucursal = Number(sessionStorage.getItem('ids'));
    this.getProductosPresentacion();
  }



  getComboCategoria() {
    this.productoservice.getComboCategoria(this.data.row.idtienda).subscribe( (resp: any[]) => {
        this.combocategorias = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error  en combo categoría ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error  en combo categoría ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }

  getComboTienda() {
    this.comboservice.getComboTiendas().subscribe( (resp: any[]) => {
        this.combotiendas = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor',
            confirmButtonColor: '#391410',
            confirmButtonText: 'ACEPTAR'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor',
          confirmButtonColor: '#391410',
          confirmButtonText: 'ACEPTAR'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor',
              confirmButtonColor: '#391410',
              confirmButtonText: 'ACEPTAR'
            });
            break;
      }
    });
  }


  getComboSucursal(idtienda: number) {
    this.comboservice.getComboSucursal(idtienda == 0 ? 1 : idtienda).subscribe( (resp: any[]) => {
        this.combosucursales = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }

  getComboPresentacion(idsucursal) {
    this.productoservice.getComboPresentacion(idsucursal).subscribe( (resp: any) => {
        this.combopresentaciones = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error  en combo presentacion ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error  en combo presentacion ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }

  getProductosPresentacion() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    switch (this.idrol) {
      case 1:
        this.displayedColumns = ['tienda', 'sucursal','estadisponible', 'entregainmediata', 'solosucursal','formatradicional','formacorazon','formarectangular', 'presentacion', 'precio_normal', 'actions'];
        //this.displayedColumns = ['tienda', 'sucursal','estadisponible', 'entregainmediata', 'solosucursal', 'presentacion', 'precio_normal', 'actions'];
        break;
      case 2:
        this.displayedColumns = ['sucursal','estadisponible', 'entregainmediata', 'solosucursal','formatradicional','formacorazon','formarectangular', 'presentacion', 'precio_normal', 'actions'];
        //this.displayedColumns = ['sucursal','estadisponible', 'entregainmediata', 'solosucursal', 'presentacion', 'precio_normal', 'actions'];
        break;
    
      default:
        this.displayedColumns = ['sucursal','estadisponible', 'entregainmediata', 'solosucursal','formatradicional','formacorazon','formarectangular', 'presentacion', 'precio_normal', 'actions'];
        //this.displayedColumns = ['sucursal','estadisponible', 'entregainmediata', 'solosucursal','presentacion', 'precio_normal', 'actions'];
        break;
    }

    

    this.productoservice.getProductosPresentacion(this.data.row.idproducto, Number(sessionStorage.getItem('ids')) == 6 ? 0 :  Number(sessionStorage.getItem('ids'))).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de presentaciones en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de presentaciones en el servidor'
            });
            break;

      }
    });
  }

  abcProductosPresentacion() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    this.presentacionmodel.operacion = this.presentacionmodel.idproductos_presentacion > 0 ? 2 : 1;
    this.presentacionmodel.idproducto = this.data.row.idproducto;
    this.presentacionmodel.idusuario = Number(sessionStorage.getItem('idu'));
    this.presentacionmodel.precio_promocion = '0';

    this.productoservice.abcProductosPresentacion(this.presentacionmodel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.presentacionmodel.operacion === 1) { // nuevo registro
          this.presentacionmodel.idproductos_presentacion = resp['idrespuesta'] !== -1 || resp['idrespuesta'] !== -2 ? resp['idrespuesta'] : 0 ;
        }
        if (resp) {
          if (resp['idrespuesta'] > 0) {
            Swal.fire({
              icon: 'success',
              text: resp['respuesta']
            });
            this.nuevo();
            this.getProductosPresentacion();
           } else {
            Swal.fire({
              icon: 'error',
              text: resp['respuesta']
            });
           }
        }
        }, (err) => {
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado de la presentación'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado de la presentación'
                });
                break;
          }
        });
  }

  actualizar(row){
     this.presentacionmodel = row; 
     this.presentacionmodel.idtienda = this.data.row.idtienda;
    if(this.combopresentaciones == undefined || this.combopresentaciones.length == 0){
      this.getComboPresentacion(this.presentacionmodel.idsucursal);
    }
    this.visualizar = 1;
  }

  nuevo(){
    this.presentacionmodel = new ProductosPresentacionModel ();
    this.presentacionmodel.idtienda = 0;
    this.presentacionmodel.idsucursal = 0;
    this.presentacionmodel.idpresentacion = 0;
    this.presentacionmodel.idcategoria = 0;
    this.presentacionmodel.precio_promocion = '0'; 
    this.visualizar = 1;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
