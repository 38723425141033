import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { ProductosCategoriaModel } from '../../models/producto/producto.model';
import { ProductoService } from '../../services/producto.service';
import { CombosService } from '../../services/combos.service';
import {  NgForm } from '@angular/forms';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';


@Component({
  selector: 'app-productoscategorias-dialog',
  templateUrl: './productoscategorias-dialog.component.html',
  styleUrls: ['./productoscategorias-dialog.component.css']
})
export class ProductoscategoriasDialogComponent implements OnInit {

  Forma: NgForm;
  combocategorias: any[];
  combopresentaciones: any[];
  categoriamodel: ProductosCategoriaModel;
  displayedColumns: string[] = [];
  dataSource: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public dialogRef: MatDialogRef<ProductoscategoriasDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private productoservice: ProductoService,
              private comboservice: CombosService,
              public paginatorInt: MatPaginatorIntl) { }

  ngOnInit(): void {
    this.categoriamodel = new ProductosCategoriaModel();
    this.categoriamodel.idcategoria = 0;
    this.getComboCategoria();
    this.getProductosCategoria();
  }

  getComboCategoria() {
    this.productoservice.getComboCategoria(this.data.row.idtienda).subscribe( (resp: any[]) => {
        this.combocategorias = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error  en combo categoría ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error  en combo categoría ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }

  getProductosCategoria() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumns = ['estadisponible', 'categoria', 'actions'];
    this.productoservice.getGridProductosCategoria(this.data.row.idproducto).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor o revise su conexión a internet.'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de categorias en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de categorias en el servidor'
            });
            break;
      }
    });
  }

  abcProductosCategoria() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    this.categoriamodel.operacion = this.categoriamodel.idproductos_categoria > 0 ? 2 : 1;
    this.categoriamodel.idproducto = this.data.row.idproducto;
    this.categoriamodel.idusuario = Number(sessionStorage.getItem('idu'));


    this.productoservice.abcProductosCategoria(this.categoriamodel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.categoriamodel.operacion === 1) { // nuevo registro
          this.categoriamodel.idproductos_categoria = resp['idrespuesta'] !== -1 || resp['idrespuesta'] !== -2 ? resp['idrespuesta'] : 0 ;
        }
        if (resp) {
          if (resp['idrespuesta'] > 0) {
            Swal.fire({
              icon: 'success',
              text: resp['respuesta']
            });
            this.nuevo();
            this.getProductosCategoria();
           } else {
            Swal.fire({
              icon: 'error',
              text: resp['respuesta']
            });
           }
        }
        }, (err) => {
         Swal.close();
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor o revise su conexión a internet.'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado de la categoria'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado de la categoria'
                });
                break;
          }
        });
  }

  actualizar(row){
    this.categoriamodel = row;
  }

  nuevo() {
    this.categoriamodel = new ProductosCategoriaModel();
    this.categoriamodel.idcategoria = 0;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}
