import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ParamModel } from '../../models/home/home.param.model';
import { BannerModel } from '../../models/banner/banner.model';
import { ProductoService } from '../../services/producto.service';
import { BannerService } from '../../services/banner.service';
import Swal from 'sweetalert2';
import * as jQuery from 'jquery';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  parametros: ParamModel = new ParamModel();
  productosBanner: BannerModel = new BannerModel();
  dataBanner: any;
  dataCategorias: any;
  dataGrid: any;
  fecha: Date;
  anio: number;
  op: number;

  constructor(private router: Router,
              private productoService: ProductoService,
              private bannerService: BannerService) {
                this.parametros.idsucursal = 1;
  }

  chunks(array, size) {
    let results = [];
    results = [];
    while (array.length) {
      results.push(array.splice(0, size));
    }
    return results;
  }
  ngOnInit() {
    this.fecha = new Date();
    this.anio = this.fecha.getFullYear();
    this.getCargaBanner();
    this.getCargaProductosGrid();
    // this.getCategorias();
    // setTimeout(function(){ 
    //     this.getProductosCategoria(0);
    // }, 1000);
  }
   getCargaBanner() {
    this.bannerService.getBannerProducto(Number(this.parametros.idsucursal)).subscribe(resp => {
      // console.log(resp);
      this.dataBanner = resp;
    }, (err) => {
    });
  }

  // getCategorias() {
  //   this.productoService.getCategorias(Number(this.parametros.idsucursal)).subscribe(resp => {
  //     console.log(resp);
  //     this.dataCategorias = resp;
  //   }, (err) => {
  //   });
  // }

  // getProductosCategoria(index) {
  //   this.productoService.getProductosCategoria(Number(this.parametros.idsucursal),this.dataCategorias[index].idcategoria).subscribe(resp => {
  //     console.log(resp);
  //     this.dataGrid = resp;
  //   }, (err) => {
  //   });
  // }

  getCargaProductosGrid() {
    this.productoService.getProductoGrid(Number(this.parametros.idsucursal)).subscribe(resp => {
      // console.log(resp);
      this.dataGrid = resp;
    }, (err) => {
    });
  }

  rutasmodulos(component: string) {
    switch (component) {
      case 'catsucursal':
        this.acceso(1, 1, component);
        break;
      case 'catproducto':
        this.acceso(2, 3, component);
        break;
      case 'catbanner':
        this.acceso(2, 4, component);
        break;
      case 'catextras':
        this.acceso(3, 15, component);
        break;
      case 'pedidos':
        this.acceso(4, 28, component);
        break;
      default:
        Swal.fire({
          icon: 'warning',
          text: 'Módulo no desarrollado'
        });
        break;
    }
  }
  acceso(idmodulo: number, idsubmodulo: number, component: string) {
    // console.log(component);
    this.router.navigate( [component] );
    /* this.auth.acceso(Number(sessionStorage.getItem('id')), idmodulo, idsubmodulo).subscribe( (resp: any[]) => {
      if (resp) {
        if (resp[0].idrespuesta === 0) {
           Swal.fire({
            type: 'warning',
            text: 'No tiene acceso al módulo'
           });
        } else {
          this.router.navigate( [component] );
        }
      }
   }, (err) => {
    switch (err.status) {
     case 0:
         Swal.fire({
           type: 'warning',
           text: 'No hay conexión con el servidor'
         });
         break;
      case 409:
        Swal.fire({
         type: 'error',
         text: 'Error ' + err.status + ' de la atención de notificaciones en el servidor'
        });
        break;
       case 500:
           Swal.fire({
             type: 'error',
             text: 'Error ' + err.status + ' de la atención de notificaciones en el servidor'
           });
           break;
     }
   });
   } */
  }

}




