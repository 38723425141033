import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PedidoDetalleModel, PedidoModel } from '../../models/pedido/pedido.model';
import Swal from 'sweetalert2';
import { PedidoService } from '../../services/pedido.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-pedido-reportepasteles-dialog',
  templateUrl: './pedido-reportepasteles-dialog.component.html',
  styleUrls: ['./pedido-reportepasteles-dialog.component.css']
})

export class PedidoReportepastelesDialogComponent implements OnInit {
  displayedColumnstitulo: string[] = [];
  dataSourcetitulo: MatTableDataSource<any>;
  displayedColumnsdetalle: string[] = [];
  dataSourcedetalle: MatTableDataSource<any>;
  sucursal: string;
  reporte: any[];

  constructor(public dialogRef: MatDialogRef<PedidoReportepastelesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private servicepedido: PedidoService) { }

  ngOnInit(): void {
    this.getReportePasteles();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getReportePasteles() {
    this.displayedColumnstitulo = ['presentacion','totalpresent','detallereporte'];
    this.displayedColumnsdetalle = ['presentacion', 'cantidad', 'producto','pedido','importe','hora_entrega','molde'];
    this.servicepedido.getReportePasteles(Number(sessionStorage.getItem('ids')) == 0 ? this.data.idsucursal : Number(sessionStorage.getItem('ids')) , this.data.fecha_entrega).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSourcetitulo = new MatTableDataSource<any>(resp);          
          this.sucursal = resp[0].sucursal;
          this.reporte = resp;
    }, (err) => {
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el reporte de productos en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el reporte de productos en el servidor'
            });
            break;
      }
    });
  }

}
