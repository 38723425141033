import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { PedidoService } from '../../services/pedido.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-estatus-pedido-detalle',
  templateUrl: './estatus-pedido-detalle.component.html',
  styleUrls: ['./estatus-pedido-detalle.component.css']
})
export class EstatusPedidoDetalleComponent implements OnInit {
  cargoid: string;
  estatusCargo: string;
  estatusMensaje: string;
  estatusCargoSpanish: string;
  estatusMensajeSpanish: string;
  codigoerror: string;  
  constructor(private pedidoService: PedidoService,
             public dialogRef: MatDialogRef<EstatusPedidoDetalleComponent>,
             @Inject(MAT_DIALOG_DATA) public data: any,
            private router: Router) 
            { 
               this.cargoid =  this.data.row.idopenpay_cargo;
              // this.cargoid = this.router.parseUrl(this.router.url).queryParams['id'];
            }

  ngOnInit(): void {
    this.pedidocargorespuesta(this.cargoid);
  }

  pedidocargorespuesta(cargoid:string) {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Obteniendo a la informacion ...'
    });
    Swal.showLoading();
    
    this.pedidoService.getEstatusCargoPedidoNew(cargoid).subscribe( (resp: any) => {
      setTimeout(() => {
        Swal.close();
        console.log(resp);
         if(resp.status == 'completed'){ 
           this.estatusCargo = 'Completada';
           this.estatusMensaje = resp.errorMessage;
           this.codigoerror = resp.errorCode; 
           this.estatusMensajeSpanish = "El cargo fue realizado.";    
         } else {
          this.estatusCargo = resp.status;
          this.codigoerror = resp.errorCode.toString(); 
          switch (this.estatusCargo) {
            case "failed":
              this.estatusCargoSpanish = "Cargo Fallido"
              break;
            case "charge_pending":
              this.estatusCargoSpanish = "Cargo pendiente"
              break;
          
            default:
              this.estatusCargoSpanish = " ";
              break;
          };
          switch (this.codigoerror) {
              case "2004":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El número de tarjeta es inválido."
              break;
              case "2005":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Se requiere el código de seguridad CVV2."
              break;
              case "2006":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El código de seguridad de la tarjeta (CVV2) no fue proporcionado."
              break;
              case "2007":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El número de tarjeta es de prueba, solamente puede usarse en Sandbox."
              break;
              case "2008":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La tarjeta no es valida para pago con puntos."
              break;
              case "2009":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El código de seguridad de la tarjeta (CVV2) es inválido."
              break;
              case "2010":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Autenticación 3D Secure fallida."
              break;
              case "2011":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Tipo de tarjeta no soportada."
              break;
              case "3001":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La tarjeta fue declinada por el banco."
              break;
              case "3002":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La tarjeta ha expirado."
              break;
              case "3003":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La tarjeta no tiene fondos suficientes."
              break;
              case "3004":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La tarjeta ha sido identificada como una tarjeta robada."
              break;
              case "3005":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La tarjeta ha sido rechazada por el sistema antifraude. -- Rechazada por coincidir con registros en lista negra."
              break;
              case "3006":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La operación no esta permitida para este cliente o esta transacción."
              break;
              case "3009":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "La tarjeta fue reportada como perdida."
              break;
              case "3010":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El banco ha restringido la tarjeta."
              break;
              case "3011":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El banco ha solicitado que la tarjeta sea retenida. Contacte al banco."
              break;
              case "3012":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Se requiere solicitar al banco autorización para realizar este pago."
              break;
              case "3201":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Comercio no autorizado para procesar pago a meses sin intereses."
              break;
              case "3203":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Promoción no valida para este tipo de tarjetas."
              break;
              case "3204":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "El monto de la transacción es menor al mínimo permitido para la promoción."
              break;
              case "3205":
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = "Promoción no permitida."
              break;

            default:
                this.estatusMensaje = resp.errorMessage;
                this.estatusMensajeSpanish = " ";
              break;
          }
         };
      }, 2500);
        
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' en Openpay'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' inviable conectar con Openpay'
            });
            break;

      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
