import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ProductodetalleComponent } from './components/productodetalle/productodetalle.component';
import { PanelAdminComponent } from './components/panel-admin/panel-admin.component';
import { CategoriasComponent } from './components/categorias/categorias.component';
import { CategoriasDialogComponent } from './components/categorias-dialog/categorias-dialog.component';
import { ProductoComponent } from './components/producto/producto.component';
import { ProductoDialogComponent } from './components/producto-dialog/producto-dialog.component';
import { BannerComponent } from './components/banner/banner.component';
import { BannerDialogComponent } from './components/banner-dialog/banner-dialog.component';
import { LoginComponent } from './components/login/login.component';
import { ExtrasComponent } from './components/extras/extras.component';
import { ExtrasDialogComponent } from './components/extras-dialog/extras-dialog.component';
import { SucursalComponent } from './components/sucursal/sucursal.component';
import { SucursalDialogComponent } from './components/sucursal-dialog/sucursal-dialog.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { NavbarPanelComponent } from './components/navbar/navbarpanel.component';
import { PedidosComponent } from './components/pedidos/pedidos.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { UsuariosDialogComponent } from './components/usuarios-dialog/usuarios-dialog.component';
import { VentasComponent } from './components/ventas/ventas.component';
import { PresentacionesComponent } from './components/presentaciones/presentaciones.component';
import { EnviocostoComponent } from './components/enviocosto/enviocosto.component';
import { IngredientesComponent } from './components/ingredientes/ingredientes.component';


import { AuthGuard } from './guards/auth.guard';
import { TiendaComponent } from './components/tienda/tienda.component';
import { UsuarioSoliRecuperaContraComponent } from './components/usuario-soli-recupera-contra/usuario-soli-recupera-contra.component';
import { UsuariorecuperacontraComponent } from './components/usuariorecuperacontra/usuariorecuperacontra.component';
import { UsuarioconfirmacionComponent } from './components/usuarioconfirmacion/usuarioconfirmacion.component';
import { TerminosComponent } from './components/terminos/terminos.component';
import { LegalesComponent } from './components/legales/legales.component';
import { TerminoscondicionesComponent } from './components/terminoscondiciones/terminoscondiciones.component';
import { AvisoprivacidadComponent } from './components/avisoprivacidad/avisoprivacidad.component';
import { TerminosdeliveryComponent } from './components/terminosdelivery/terminosdelivery.component';
import { AvisodeliveryComponent } from './components/avisodelivery/avisodelivery.component';
import { PagoexitosoComponent } from './components/pagoexitoso/pagoexitoso.component';
import { PagorespuestaComponent } from './components/pagorespuesta/pagorespuesta.component';
import { PagorechazadoComponent } from './components/pagorechazado/pagorechazado.component';
import { DianolaboralComponent } from './components/dianolaboral/dianolaboral.component';
import { EntregaDomicilioComponent } from './components/entrega-domicilio/entrega-domicilio.component';

const APP_ROUTES: Routes = [
  // {path: 'login', component: LoginComponent}, <-- esta linea es para entrar por home
  {path: '', component: LoginComponent}, // esta linea es para entrar por login
  {path: 'usuariosolicitudcambiopass', component: UsuarioSoliRecuperaContraComponent}, // solicitar cambio password via email
  {path: 'usuariorecuperacion/:userId', component: UsuariorecuperacontraComponent}, // cambiar el password
  {path: 'usuarioconfirmacion/:userId', component: UsuarioconfirmacionComponent},
  {path: 'terminosycondiciones', component: TerminosComponent},
  {path: 'avisosprivacidad', component: LegalesComponent},
  {path: 'terminoscondiciones', component: TerminoscondicionesComponent},
  {path: 'avisoprivacidad', component: AvisoprivacidadComponent},
  {path: 'terminosdelivery', component: TerminosdeliveryComponent},
  {path: 'avisodelivery', component: AvisodeliveryComponent},
  {path: 'pagorespuesta', component: PagorespuestaComponent},
  {path: 'pagoexitoso', component: PagoexitosoComponent},
  {path: 'pagorechazado/:cargoid', component: PagorechazadoComponent},

  // {
  //   path: '',
  //   component: NavbarComponent,
  //   children: [
  //     {path: '', component: HomeComponent},
  //     {path: 'home', component: HomeComponent},
  //     {path: 'productodetalle', component: ProductodetalleComponent }
  //   ]
  // },
  {
    path: 'panel',
    component: NavbarPanelComponent , canActivate: [ AuthGuard ],
    children: [
      {path: 'catbanner', component: BannerComponent, canActivate: [ AuthGuard ]},
      {path: 'paneladmin', component: PanelAdminComponent, canActivate: [ AuthGuard ]},
      {path: 'catproducto', component: ProductoComponent, canActivate: [ AuthGuard ]},
      {path: 'catextras', component: ExtrasComponent, canActivate: [ AuthGuard ]},
      {path: 'catsucursal', component: SucursalComponent, canActivate: [ AuthGuard ]},
      {path: 'pedidos', component: PedidosComponent, canActivate: [ AuthGuard ]},
      {path: 'catusuario', component: UsuariosComponent, canActivate: [ AuthGuard ]},
      {path: 'cattienda', component: TiendaComponent, canActivate: [ AuthGuard ]},
      {path: 'catcategoria', component: CategoriasComponent, canActivate: [ AuthGuard ]},
      {path: 'ventas', component: VentasComponent, canActivate: [ AuthGuard]},
      {path: 'catpresentacion', component: PresentacionesComponent, canActivate: [ AuthGuard]},
      {path: 'envios', component: EnviocostoComponent, canActivate: [ AuthGuard]},
      {path: 'catingrediente', component: IngredientesComponent, canActivate: [AuthGuard]},
      {path: 'dianolaboral', component: DianolaboralComponent, canActivate: [AuthGuard]},
      {path: 'entregadom', component: EntregaDomicilioComponent, canActivate: [AuthGuard]}
      
    ]
  },

  
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: '**', pathMatch: 'full', redirectTo: 'login' }
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);
