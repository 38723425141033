import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { AppSettings } from '../models/appsettings';
import { SucursalModel, ParamSucursalModel, FechasEntregaDomDesactivadosModel, LimiteProdCarritoDomModel, LimiteProdPedidoModel  } from '../models/sucursal/sucursal.model';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class SucursalService {

  private header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  .append('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  private headerQP = new HttpHeaders().set('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  constructor(private http: HttpClient) { }

  getQueryParam(query: string) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.get(url, { headers: this.headerQP });
  }

  getFormParam(query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.header });
  }

  getSucursalFavorita(data: SucursalModel) {
    return this.getQueryParam('sucursal/sucursalfavorita?idsucursal=' + data.idsucursal);
  }

  getSucursalCatalogoGrid(data: ParamSucursalModel) {
    return this.getQueryParam('sucursal/leersucursalespanel?idsucursal=' + data.idsucursal + '&idrol=' + data.idrol);
  }

  abcSucursal(data: SucursalModel) {
    const params = new HttpParams({
      fromObject: {
        idsucursal: String(data.idsucursal === undefined ? 0 : data.idsucursal),
        idtienda: String( data.idtienda === undefined ? 0 : data.idtienda),
        sucursal: data.sucursal === undefined ? '' : data.sucursal,
        abreviatura: data.abreviatura === undefined ? '' : data.abreviatura,
        latitud: String( data.latitud === undefined ? '' : data.latitud),
        longitud: String( data.longitud === undefined ? '' : data.longitud),
        radio_repartidor: data.radio_repartidor === undefined ? '' : data.radio_repartidor,
        entrada: data.entrada === undefined ? '' : data.entrada,
        salida: data.salida === undefined ? '' : data.salida,
        telefono: data.telefono === undefined ? '' : data.telefono,
        direccion: data.direccion === undefined ? '' : data.direccion,
        idusuario: String( data.idusuario === undefined ? 0 : data.idusuario),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar, 3 dar de baja 4 reactivar)
       }
    });
    return this.getFormParam('sucursal/abcsucursalpanel', params);
  }

  validaHorario( entradaName: string, salidaName: string ) {

    return ( formGroup: FormGroup ) => {
      const entradaControl = formGroup.controls[entradaName];
      const salidaControl = formGroup.controls[salidaName];

      const strentrada = String(entradaControl.value);
      const strsalida = String(salidaControl.value);

      const entrada = new Date();
      entrada.setHours( Number(strentrada.split(':')[0]), Number(strentrada.split(':')[1]), Number(strentrada.split(':')[2]) );
      const salida = new Date();
      salida.setHours( Number(strsalida.split(':')[0]), Number(strsalida.split(':')[1]), Number(strsalida.split(':')[2]) );
      // salidaControl.value.split(':')[0], salidaControl.value.split(':')[1], salidaControl.value.split(':')[2]
      if (entrada < salida) {
        salidaControl.setErrors(null);
      } else {
        salidaControl.setErrors( { SalidaNoEsMayor : true } );
      }

    };
  }

  getFechaEntregaDomDesactivarGrid(idsucursal: number) {
    return this.getQueryParam('sucursal/leerfechaentregadomdesactivar?idsucursal=' + idsucursal);
  }

  abcfechaentregadomdesactivar(data: FechasEntregaDomDesactivadosModel) {
    const params = new HttpParams({
      fromObject: {
        id: String(data.id === undefined ? 0 : data.id),
        idsucursal: String(data.idsucursal === undefined ? 0 : data.idsucursal),
        fecha_entrega_dom: data.fecha_entrega_dom === undefined ? '' : data.fecha_entrega_dom,
        mensaje: data.mensaje === undefined ? '' : data.mensaje,
        usuario_creada: String( data.usuario_creada === undefined ? 0 : data.usuario_creada),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar, 3 desactivar 4 reactivar)
       }
    });
    return this.getFormParam('sucursal/abcfechaentregadomdesactivar', params);
  }

  getLimiteProdCarritoDomGrid(idsucursal: number) {
    return this.getQueryParam('sucursal/leerlimiteprodcarritodom?idsucursal=' + idsucursal);
  }

  abcLimiteProdCarritoDom(data: LimiteProdCarritoDomModel) {
    const params = new HttpParams({
      fromObject: {
        id: String(data.id === undefined ? 0 : data.id),
        idsucursal: String(data.idsucursal === undefined ? 0 : data.idsucursal),
        cantidad: String(data.cantidad === undefined ? 0 : data.cantidad),
        usuario_creada: String( data.usuario_creada === undefined ? 0 : data.usuario_creada),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar, 3 activar, 4 desactivar )
       }
    });
    return this.getFormParam('sucursal/abclimiteprodcarritodom', params);
  }

  getLimiteProdPedidoGrid(idsucursal: number) {
    return this.getQueryParam('sucursal/leerlimiteprodpedido?idsucursal=' + idsucursal);
  }

  abcLimiteProdPedido(data: LimiteProdPedidoModel) {
    const params = new HttpParams({
      fromObject: {
        id: String(data.id === undefined ? 0 : data.id),
        idsucursal: String(data.idsucursal === undefined ? 0 : data.idsucursal),
        tipoentrega: String(data.tipoentrega === undefined ? 0 : data.tipoentrega),
        maxproductos: String(data.maxproductos === undefined ? 0 : data.maxproductos),
        maxpedidos: String(data.maxpedidos === undefined ? 0 : data.maxpedidos),
        usuario_creada: String( data.usuario_creada === undefined ? 0 : data.usuario_creada),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar, 3 activar, 4 desactivar )
       }
    });
    return this.getFormParam('sucursal/abclimiteprodpedido', params);
  }


}
