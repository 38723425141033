import { Injectable } from '@angular/core';
import { AppSettings } from '../models/appsettings';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CombosService {

  private header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  .append('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  private headerQP = new HttpHeaders().set('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  constructor(private http: HttpClient) { }

  getQueryParam(query: string) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.get(url, { headers: this.headerQP });
  }

  getFormParam(query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.header });
  }

  getComboSucursal(idtienda: number) {
    return this.getQueryParam('combos/sucursales?idtienda=' + idtienda);
  }

  getComboRol(idrol: number) {
    return this.getQueryParam('combos/roles?idrol=' + idrol);
  }

  getComboPanelSucursalFavorita(idsucursal: number, idrol: number) {
    return this.getQueryParam('sucursal/leersucursalespanel?idsucursal=' + idsucursal + '&idrol=' + idrol);
  }

  // combos para panel

  getComboPanelSucursal(idsucursal: number, idrol: number) {
    return this.getQueryParam('sucursal/leersucursalespanel?idsucursal=' + idsucursal + '&idrol=' + idrol);
  }

  getComboTiendas() {
    return this.getQueryParam('combos/tiendas');
  }

  getComboPanes() {
    return this.getQueryParam('combos/panes');
  }

  getComboRellenos() {
    return this.getQueryParam('combos/rellenos');
  }

  getComboCoberturas() {
    return this.getQueryParam('combos/coberturas' );
  }

  getComboPanesSucursal(idsucursal: number) {
    return this.getQueryParam('combos/panessucursales?idsucursal=' + idsucursal);
  }

  getComboRellenosSucursal(idsucursal: number) {
    return this.getQueryParam('combos/rellenossucursales?idsucursal=' + idsucursal);
  }

  getComboCoberturasSucursal(idsucursal: number) {
    return this.getQueryParam('combos/coberturassucursales?idsucursal=' + idsucursal);
  }

  getComboPanelPresentaciones(idusuario: number, idrol: number) {
    return this.getQueryParam('presentaciones/leerpresentacionespanel?idusuario=' + idusuario + '&idrol=' + idrol);
  }

  getComboPresentaciones(idsucursal: number) {
    return this.getQueryParam('combos/presentaciones?idsucursal=' + idsucursal);
  }

  getComboObjetoImpuesto() {
    return this.getQueryParam('combos/objetoimpuesto');
  }


}
