import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSettings } from '../models/appsettings';
import { PanCatalogoModel, PanSucursalCatalogoModel, PanPresentacionCatalogoModel, RellenoCatalogoModel, RellenoSucursalModel, CoberturaCatalogoModel, CoberturaSucursalModel } from '../models/ingrediente/ingrediente.model';

@Injectable({
  providedIn: 'root'
})
export class IngredienteService {

  private header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  .append('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  private headerJSON = new HttpHeaders().set('Content-Type', 'application/json');

  private headerQP = new HttpHeaders().set('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  constructor(private http: HttpClient) { }

  getQueryParam( query: string) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.get(url, { headers: this.headerQP });
  }

  getFormParam( query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.header });
  }

  getFormParamJSON(query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.headerJSON });
  }

  // panes

  getGridCatPanes() {
    return this.getQueryParam('productos/getcatpanespanel');
  }

  abcCatPanes(data: PanCatalogoModel) {
    const params = new HttpParams({
      fromObject: {
        idpan: String(data.idpan === undefined ? 0 : data.idpan), // ( valor 0 si operación = 1 )
        pan: data.pan === undefined ? '' : data.pan,
        idusuario: String(data.idusuario === undefined ? 0 : data.idusuario),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar)
      }
    });
    return this.getFormParam('productos/abccatpanespanel', params);
  }

  getGridCatPanesSucursal(idsucursal: number) {
    return this.getQueryParam('productos/getcatpanessucursalespanel?idsucursal=' + idsucursal);
  }

  abcCatPanesSucursal(data: PanSucursalCatalogoModel) {
    const params = new HttpParams({
      fromObject: {
        idpanes_sucursales: String(data.idpanes_sucursales === undefined ? 0 : data.idpanes_sucursales), // ( valor 0 si operación = 1 )
        idpan: String(data.idpan === undefined ? 0 : data.idpan),
        idsucursal: String(data.idsucursal === undefined ? 0 : data.idsucursal),
        estaactivo: String(data.estaactivo === undefined ? 0 : data.estaactivo),
        idusuario: String(data.idusuario === undefined ? 0 : data.idusuario),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar)
      }
    });
    return this.getFormParam('productos/abccatpanessucursalespanel', params);
  }

  getGridCatPanesPresentacion(idpan: number, idsucursal: number) {
    return this.getQueryParam('productos/getcatpanespresentacionpanel?idpan=' + idpan + '&idsucursal=' + idsucursal);
  }

  abcCatPanesPresentacion(data: PanPresentacionCatalogoModel) {
    const params = new HttpParams({
      fromObject: {
        idpanespresentacion: String(data.idpanespresentacion === undefined ? 0 : data.idpanespresentacion), // ( valor 0 si operación = 1 )
        idpan: String(data.idpan === undefined ? 0 : data.idpan),
        idpresentacion: String(data.idpresentacion === undefined ? 0 : data.idpresentacion),
        url_img_panpresentacion: data.url_img_panpresentacion === undefined ? '' : data.url_img_panpresentacion, // (url de la imagen)
        imagenbase64: data.imagenbase64 === undefined ? '' : data.imagenbase64, // (imagen en base 64, solo si hay cambios)
        estaactivo: String(data.estaactivo === undefined ? 0 : data.estaactivo),
        idusuario: String(data.idusuario === undefined ? 0 : data.idusuario),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar)
      }
    });
    return this.getFormParam('productos/abccatpanespresentacionpanel', params);
  }

  // rellenos

  getGridCatRellenos() {
    return this.getQueryParam('productos/getcatrellenospanel');
  }

  abcCatRellenos(data: RellenoCatalogoModel) {
    const params = new HttpParams({
      fromObject: {
        idrelleno: String(data.idrelleno === undefined ? 0 : data.idrelleno), // ( valor 0 si operación = 1 )
        relleno: data.relleno === undefined ? '' : data.relleno,
        imagenb64: data.imagenb64 === undefined ? '' : data.imagenb64, // (imagen en base 64, solo si hay cambios)
        url_img_relleno: data.url_img_relleno === undefined ? '' : data.url_img_relleno, // (url de la imagen)
        idusuario: String(data.idusuario === undefined ? 0 : data.idusuario),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar)
      }
    });
    return this.getFormParam('productos/abccatrellenospanel', params);
  }

  getGridCatRellenosSucursal(idsucursal: number) {
    return this.getQueryParam('productos/getcatrellenossucursalespanel?idsucursal=' + idsucursal);
  }

  abcCatRellenosSucursal(data: RellenoSucursalModel) {
    const params = new HttpParams({
      fromObject: {
        idrellenos_sucursales: String(data.idrellenos_sucursales === undefined ? 0 : data.idrellenos_sucursales), // ( valor 0 si operación = 1 )
        idrelleno: String(data.idrelleno === undefined ? 0 : data.idrelleno),
        idsucursal: String(data.idsucursal === undefined ? 0 : data.idsucursal),
        estaactivo: String(data.estaactivo === undefined ? 0 : data.estaactivo),
        idusuario: String(data.idusuario === undefined ? 0 : data.idusuario),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar)
      }
    });
    return this.getFormParam('productos/abccatrellenosucursalespanel', params);
  }


  // coberturas

  getGridCatCoberturas() {
    return this.getQueryParam('productos/getcatcoberturapanel');
  }

  abcCatCoberturas(data: CoberturaCatalogoModel) {
    const params = new HttpParams({
      fromObject: {
        idcobertura: String(data.idcobertura === undefined ? 0 : data.idcobertura), // ( valor 0 si operación = 1 )
        cobertura: data.cobertura === undefined ? '' : data.cobertura,
        imagenb64: data.imagenb64 === undefined ? '' : data.imagenb64, // (imagen en base 64, solo si hay cambios)
        url_img_cobertura: data.url_img_cobertura === undefined ? '' : data.url_img_cobertura, // (url de la imagen)
        idusuario: String(data.idusuario === undefined ? 0 : data.idusuario),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar)
      }
    });
    return this.getFormParam('productos/abccatcoberturapanel', params);
  }

  getGridCatCubiertasSucursal(idsucursal: number) {
    return this.getQueryParam('productos/getcatcoberturasucursalespanel?idsucursal=' + idsucursal);
  }

  abcCatCubiertasSucursal(data: CoberturaSucursalModel) {
    const params = new HttpParams({
      fromObject: {
        idcobertura_sucursales: String(data.idcobertura_sucursales === undefined ? 0 : data.idcobertura_sucursales), // ( valor 0 si operación = 1 )
        idcobertura: String(data.idcobertura === undefined ? 0 : data.idcobertura),
        idsucursal: String(data.idsucursal === undefined ? 0 : data.idsucursal),
        estaactivo: String(data.estaactivo === undefined ? 0 : data.estaactivo),
        idusuario: String(data.idusuario === undefined ? 0 : data.idusuario),
        operacion: String( data.operacion === undefined ? 0 : data.operacion) //  ( 1 insertar, 2 Actualizar)
      }
    });
    return this.getFormParam('productos/abccatcoberturasucursalespanel', params);
  }


}
