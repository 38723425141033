import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { AdminUsuariosModel, UsuarioLoginModel } from './../models/adminusuarios/adminusuarios.model';
import { BannerModel } from './../models/banner/banner.model';
import { AppSettings } from '../models/appsettings';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  private header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  .append('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  private headerQP = new HttpHeaders().set('Authorization', 'Basic ' + AppSettings.API_CREDENCIALES);

  constructor(private http: HttpClient) { }

  getQueryParam( query: string) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.get(url, { headers: this.headerQP });
  }

  getFormParam( query: string, params: HttpParams) {
    const url = AppSettings.API_ENDPOINT + `${ query }`;
    return this.http.post(url, params, { headers: this.header });
  }

  abcBannerProducto(data: BannerModel) {
    const params = new HttpParams({
      fromObject: {
        idbanner_sucursal: String(data.idbanner_sucursal === undefined ? 0 : data.idbanner_sucursal),
        idsucursal: String( data.idsucursal === undefined ? 0 : data.idsucursal),
        url_imagen: data.url_imagen === undefined ? '' : data.url_imagen,
        imagenbase64: data.imagenbase64 === undefined ? '' : data.imagenbase64,
        estaactivo: String( data.estaactivo === undefined ? 0 : data.estaactivo),
        operacion: String( data.operacion === undefined ? 0 : data.operacion), //  ( 1 insertar, 2 Actualizar, 3 dar de baja)
        sucursal: data.sucursal === undefined ? '' : data.sucursal
      }
    });
    return this.getFormParam('productos/agregarbanner', params);
  }

  getBannerProducto(idsucursal: number) {
    return this.getQueryParam('productos/leerbannerspanel?idsucursal=' + idsucursal);
  }

}
