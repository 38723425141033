import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';


import { ExtraService } from '../../services/extra.service';
import { CombosService } from '../../services/combos.service';
import { ExtrasModel } from '../../models/extra/extras.model';


@Component({
  selector: 'app-extras-dialog',
  templateUrl: './extras-dialog.component.html',
  styleUrls: ['./extras-dialog.component.css']
})
export class ExtrasDialogComponent implements OnInit {

  extrasmodel: ExtrasModel;

  formulario: FormGroup;
  submitted = false;

  combosucursales: any[];
  idsucursal: number;
  idrol: number;


  constructor(public dialogRef: MatDialogRef<ExtrasDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private extraservice: ExtraService,
              private combosservicio: CombosService,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      idsucursal: [''],
      extra: ['', Validators.required],
      precio: ['', [Validators.required, Validators.min(0)]],
      requiereDist: ['']
    }, {
    });

    this.extrasmodel = new ExtrasModel();

    this.extrasmodel.idextra = 0;
    this.idrol = Number(sessionStorage.getItem('idr'));
    this.idsucursal = Number(sessionStorage.getItem('ids'));
    if (this.idrol === 1 || this.idrol === 2) {
      this.getComboSucursal();
    }


    if (this.data.row.idextra > 0) {
      this.extrasmodel = this.data.row;
    }

    if (this.extrasmodel.idextra === 0) {
     // this.extrasmodel.idsucursal = 0;
      this.extrasmodel.precio = 0;
    }
    // this.extrasmodel.idsucursal = Number(sessionStorage.getItem('ids'));
    this.extrasmodel.idusuario = Number(sessionStorage.getItem('idu'));
    if (this.extrasmodel.requiereDist) {
        this.extrasmodel.requiereDist = true;
    } else {
        this.extrasmodel.requiereDist = false;
    }


    // console.log(this.extrasmodel);

  }

  // variable para acceder a los campos del formulario
  get f() { return this.formulario.controls; }

  idsucursalNoValido() {
    return this.formulario.get('idsucursal').invalid;
  }

  extraNovalido() {
    return this.formulario.get('extra').invalid;
  }

  precioNovalido() {
    if (this.formulario.get('precio').value >= 0) {
      return false;
    } else {
      return true;
    }
  //  return this.formulario.get('precio').invalid;
  }

  requiereDistNoValido() {
    return this.formulario.get('requiereDist').invalid;
  }

  abcCatExtras() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    this.extrasmodel.operacion = this.extrasmodel.idextra > 0 ? 2 : 1;
    if (this.idrol > 2) {
      this.extrasmodel.idsucursal = this.idsucursal;
    }
    // console.log(this.extrasmodel);
    this.extraservice.abcCatExtras(this.extrasmodel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.extrasmodel.operacion === 1) {
          this.extrasmodel.idextra = resp['idrespuesta'] !== -1 || resp['idrespuesta'] !== -2 ? resp['idrespuesta'] : 0 ;
          this.extrasmodel.operacion = this.extrasmodel.operacion === 1 ? 2 : 2;
        }
        if (resp) {
          if (resp['idrespuesta'] > 0) {
            Swal.fire({
              icon: 'success',
              text: resp['respuesta']
            });
           } else {
            Swal.fire({
              icon: 'error',
              text: resp['respuesta']
            });
           }
        }
        }, (err) => {
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado del catálogo de extras'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado del catálogo de extras'
                });
                break;
          }
        });
  }

  getComboSucursal() {
    this.combosservicio.getComboPanelSucursal(this.idsucursal, this.idrol).subscribe( (resp: any[]) => {
        this.combosucursales = resp;
        console.log(this.combosucursales);
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {

    this.submitted = true;

    // se detiene si el formulario es invalido
    if (this.formulario.invalid) {
        return;
    }
    this.abcCatExtras();

    // valores visibles del formmulario
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
    // valores del modelo que se pasa al abc
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.extrasmodel, null, 4));

  }

}
