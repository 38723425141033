export class FacturacionModel {
    iddatosfacturacion: number;
    idusocfdi: string;
    id: number;
    esinvitado: boolean;
    nombre: string;
    rfc: string;
    email: string;
    direccion: string;
    operacion: number;
    domiciliofiscalreceptor: string;
    codigoregimenfiscalreceptor: string;
    descripcioncodigofiscal: string;
}