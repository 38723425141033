import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { ProductosSucursalesModel } from './../../models/producto/producto.model';
import { ProductoService } from '../../services/producto.service';
import { CombosService } from '../../services/combos.service';
import {  NgForm } from '@angular/forms';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';

@Component({
  selector: 'app-productossucursal-dialog',
  templateUrl: './productossucursal-dialog.component.html',
  styleUrls: ['./productossucursal-dialog.component.css']
})
export class ProductossucursalDialogComponent implements OnInit {
  Forma: NgForm;
  combotiendas: any[];
  combosucursales: any[];
  sucursalmodel: ProductosSucursalesModel;
  displayedColumns: string[] = [];
  dataSource: any;
  tienda: boolean;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(public dialogRef: MatDialogRef<ProductossucursalDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private productoservice: ProductoService,
              private comboservice: CombosService,
              public paginatorInt: MatPaginatorIntl) { }

  ngOnInit(): void {
    this.tienda = Number(sessionStorage.getItem('idr')) === 1 ? true : false;
    this.sucursalmodel = new ProductosSucursalesModel();
    this.sucursalmodel.idtienda = 0;
    this.sucursalmodel.idsucursal = 0;
    this.sucursalmodel.idproducto = this.data.row.idproducto;
    this.sucursalmodel.idusuario = Number(sessionStorage.getItem('idu'));
    this.getComboTiendas();
    this.getComboSucursal(this.data.row.idtienda);
    this.getProductosSucursal();
  }

  getComboTiendas() {
    this.comboservice.getComboTiendas().subscribe( (resp: any[]) => {
        this.combotiendas = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en combo tiendas ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en combo tiendas ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }

  getComboSucursal(idtienda) {
    this.comboservice.getComboSucursal(idtienda).subscribe( (resp: any[]) => {
        this.combosucursales = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }

  abcProductosSucursales() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando registro ...'
    });
    Swal.showLoading();
    this.sucursalmodel.operacion = this.sucursalmodel.idproductos_sucursales > 0 ? 2 : 1;
  // this.sucursalmodel.idsucursal = Number();


    this.productoservice.abcProductosSucursales(this.sucursalmodel).subscribe( (resp: any[]) => {
        Swal.close();
        if (this.sucursalmodel.operacion === 1) { // nuevo registro
          this.sucursalmodel.idproductos_sucursales = resp['idrespuesta'] !== -1 || resp['idrespuesta'] !== -2 ? resp['idrespuesta'] : 0 ;
        }
        if (resp) {
          if (resp) {
            Swal.fire({
              icon: 'success',
              text: resp['respuesta']
            });
           } else {
            Swal.fire({
              icon: 'error',
              text: resp['respuesta']
            });
           }
           this.nuevo();
          this.getProductosSucursal();
        }
        }, (err) => {
         switch (err.status) {
          case 0:
              Swal.fire({
                icon: 'warning',
                text: 'No hay conexión con el servidor'
              });
              break;
           case 409:
             Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' en el guardado de la sucursal'
             });
             break;
            case 500:
                Swal.fire({
                  icon: 'error',
                  text: 'Error ' + err.status + ' en el guardado de la sucursal'
                });
                break;
          }
        });
  }

  getProductosSucursal() {
    // Swal.fire({
    //   allowOutsideClick: false,
    //   icon: 'info',
    //   text: 'Cargando registros ...'
    // });
    // Swal.showLoading();

    this.displayedColumns = ['sucursal', 'estaactivo', 'actions'];

    this.productoservice.getProductosSucursal(this.data.row.idtienda, this.data.row.idproducto).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.dataSource.paginator = this.paginator;
    }, (err) => {
      // Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de presentaciones en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de presentaciones en el servidor'
            });
            break;

      }
    });
  }

  reloadgrid() {
    // Swal.fire({
    //   allowOutsideClick: false,
    //   icon: 'info',
    //   text: 'Cargando registros ...'
    // });
    // Swal.showLoading();
  //  this.activaGuardar = false;
    this.getProductosSucursal();
  //  this.activaGuardar = true;
    // Swal.close();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  actualizar(row) {
    this.sucursalmodel = row;
    this.sucursalmodel.idusuario = Number(sessionStorage.getItem('idu'));
  }

  nuevo() {
    this.sucursalmodel = new ProductosSucursalesModel();
    this.sucursalmodel.idtienda = 0;
    this.sucursalmodel.idsucursal = 0;
    this.sucursalmodel.idproducto = this.data.row.idproducto;
    this.sucursalmodel.idusuario = Number(sessionStorage.getItem('idu'));
  }

}
