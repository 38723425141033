import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog} from '@angular/material/dialog';
import { ResumenVentasReporteComponent } from "../resumen-ventas-reporte/resumen-ventas-reporte.component";
import { PedidoService } from '../../services/pedido.service';
import Swal from 'sweetalert2';
import { CombosService } from '../../services/combos.service';

@Component({
  selector: 'app-resumen-ventas',
  templateUrl: './resumen-ventas.component.html',
  styleUrls: ['./resumen-ventas.component.css']
})
export class ResumenVentasComponent implements OnInit {
   fechaIni: string;
   fechaFin: string;
   combosucursales: any[];
   idsucursal: number;
   idrol: number;

  constructor(private dialog: MatDialog,
    public dialogRef: MatDialogRef<ResumenVentasReporteComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any,
     private pedidoService: PedidoService,
     private comboservice: CombosService) { }

  ngOnInit(): void {
    this.getFechaActual();   
   // document.getElementById("idsucursal").innerHTML = "0"; 
      this.idrol = Number(sessionStorage.getItem('idr'));
     // if(Number(sessionStorage.getItem('ids')) == 0 || Number(sessionStorage.getItem('ids')) == 6){
        this.getComboSucursal(1);
     // }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getComboSucursal(idtienda: number) {
    this.comboservice.getComboSucursal(idtienda == 0 ? 1 : idtienda).subscribe( (resp: any[]) => {
        this.combosucursales = resp;
    }, (err) => {
     switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error en sucursal ' + err.status + ' en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error en sucursal ' + err.status + ' en el servidor'
            });
            break;
      }
    });
  }

  getFechaActual(){  
      
    this.pedidoService.getFechaActual().subscribe( (resp: any[]) => {
      this.fechaIni = resp[0].fecha_actual;
      this.fechaFin = resp[0].fecha_actual;
    }, (err) => {
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para la fecha actual en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para la fecha actual en el servidor'
            });
            break;
    
      }
    });
  }

  openDialogReportePasteles(): void {
    const dialogRef = this.dialog.open( ResumenVentasReporteComponent , {
      data: {titulo: 'Resumen de ventas',
      fecha_inicial: this.fechaIni,
      fecha_final: this.fechaFin,
      idsucursal: this.idsucursal
    },
    disableClose: true,
    height: String(screen.height * 0.90),
    width: String(screen.width * 0.90),
    });
    dialogRef.afterClosed().subscribe(result => {
      //this.getGridPedidos();
    });
  
  }

}
