export class EnvioCostoModel {
    idrol: number;
    idsucursal: number;
    idcosto_envio: number;
    idtienda: number;
    tienda: string;
    rangoinicial: string;
    rangofinal: string;
    costo: string;
    estaactivo: boolean;
    idusuario: number;
    operacion: number;
}
