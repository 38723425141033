import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatTableDataSource } from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import Swal from 'sweetalert2';


import { ProductoModel, ProductoParamModel } from './../../models/producto/producto.model';

import { ProductoService } from '../../services/producto.service';
import { PedidoService } from '../../services/pedido.service';
import { PedidoDetalleModel, PedidoEscanerCodigoModel } from '../../models/pedido/pedido.model';

@Component({
  selector: 'app-pedido-escanear-codigo-detalle',
  templateUrl: './pedido-escanear-codigo-detalle.component.html',
  styleUrls: ['./pedido-escanear-codigo-detalle.component.css']
})
export class PedidoEscanearCodigoDetalleComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PedidoEscanearCodigoDetalleComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private productoservice: ProductoService,
              private pedidoservice: PedidoService,
              public paginatorInt: MatPaginatorIntl) {

                interface ExtrasItem {
                  idcarrito_extras: number;
                  extra: string;
                  distribucion: string;
                  cantidad: string;
                  precio: string;
                }

                interface Producto {
                  idproducto: number;
                  producto: string;
                  url_imagen: string;
                  cantidad: string;
                  extras: Array<ExtrasItem>;
                }

                const ELEMENT_DATA = new Array<Producto>();

}
  paramproductolmodel: ProductoParamModel;
  producto: ProductoModel;
  pedidodetalle: PedidoDetalleModel;
  pedidoescanercodigomodel: PedidoEscanerCodigoModel;

  displayedColumns: string[] = [];

  arreglo: any[] = [];

  datasource: any;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;

  ngOnInit(): void {

    this.pedidodetalle = new PedidoDetalleModel();
    this.paramproductolmodel = new ProductoParamModel();
    this.pedidoescanercodigomodel = new PedidoEscanerCodigoModel();

    this.pedidoescanercodigomodel = this.data.row;
    console.log(this.pedidoescanercodigomodel);
    this.getGridProductos();

    this.paginatorInt.firstPageLabel = 'Primera página';
    this.paginatorInt.lastPageLabel = 'Última página';
    this.paginatorInt.itemsPerPageLabel = 'Registro por página';
    this.paginatorInt.nextPageLabel     = 'Siguiente página';
    this.paginatorInt.previousPageLabel = 'Anterior página';
  }

  getGridProductos() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumns = ['idproducto', 'url_imagen_miniatura', 'nombre', 'presentacion', 'cantidad', 'precio_normal', 'precio_promocion', 'extras'];
    this.pedidoservice.getEscanearCodigoPedido(this.data.row).subscribe( (resp: any[]) => {
          Swal.close();
          this.dataSource = new MatTableDataSource<any>(resp);
          this.arreglo = Array.from(resp);
          // this.dataSource = this.arreglo;
          this.dataSource.paginator = this.paginator;
          console.log(resp);
    }, (err) => {
      Swal.close();
      switch (err.status) {
      case 0:
          Swal.fire({
            icon: 'warning',
            text: 'No hay conexión con el servidor'
          });
          break;
       case 409:
         Swal.fire({
          icon: 'error',
          text: 'Error ' + err.status + ' para el grid de productos en el servidor'
         });
         break;
        case 500:
            Swal.fire({
              icon: 'error',
              text: 'Error ' + err.status + ' para el grid de productos en el servidor'
            });
            break;

      }
    });
  }

  accesoDetalle(row) {
    this.getGridProductos();
  }

applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

onNoClick(): void {
    this.dialogRef.close();
  }

}
