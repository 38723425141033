import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import * as jsPDF from 'jspdf';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pedidos-imprimir-dialog',
  templateUrl: './pedidos-imprimir-dialog.component.html',
  styleUrls: ['./pedidos-imprimir-dialog.component.css']
})
export class PedidosImprimirDialogComponent implements OnInit {

  imagenPerfilvacia: string;

  displayedColumns: string[] = [];
  datasource: any;
  dataSource: MatTableDataSource<any>;

  @ViewChild('tabla') tabla: ElementRef;
  @ViewChild('htmlData') htmlData: ElementRef;


  constructor(public dialogRef: MatDialogRef<PedidosImprimirDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any
              ) { }

  ngOnInit(): void {
  //  console.log(this.data);
  //  this.dataSource =  this.data;
  this.imagenPerfilvacia = './assets/iconouser/pastel1.jpg';
  this.getGridPedidos();
  }

  getGridPedidos() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando registros ...'
    });
    Swal.showLoading();
    this.displayedColumns = ['estatus_pedido', 'no_orden', 'subtotal', 'descuento', 'iva', 'total', 'nombre_repartidor', 'fecha_entrega', 'tipo_entrega', 'nombre_recibe', 'envio'];
    this.dataSource = new MatTableDataSource<any>(this.data.row);
  //  console.log(this.data.row);
    Swal.close();

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  imprimir(cmpName) {
     const printContents = document.getElementById(cmpName).innerHTML;
     const originalContents = document.body.innerHTML;

     document.body.innerHTML = printContents;

     window.print();

     document.body.innerHTML = originalContents;
  }

  public openPDF(): void {
    const DATA = this.htmlData.nativeElement;
    const doc = new jsPDF('landscape', 'pt', 'letter', true);
    doc.fromHTML(DATA.innerHTML, 15, 15);
    doc.output('dataurlnewwindow');
  }


  public downloadPDF(): void {
    const DATA = this.tabla.nativeElement;
    const doc = new jsPDF('p', 'pt', 'a4');

    const handleElement = {
      '#editor'(element, renderer) {
        return true;
      }
    };
    doc.fromHTML(DATA.innerHTML, 15, 15, {
      width: 200,
      elementHandlers: handleElement
    });

    doc.save('pedidos.pdf');
  }

  getColorEstatusRow(estatus: number) {
    let colorletra: string;
    switch (estatus) {
      case 2: {
        colorletra = '#d01d17';
        break;
      }
      case 3: {
        colorletra = '#8c1819';
        break;
      }
      case 4: {
        colorletra = '#391410';
        break;
      }

    }

    return colorletra;
  }

}
